import React from 'react';
import { Helmet } from 'react-helmet';

const ForceConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Force Converter | Case Modify</title>
      <meta name="description" content="Force Unit Conversion - converts dyne, gram-force, newton, pond..." />
      <meta name="Keywords" content="converts dyne, gram-force, newton, pond" />

      <meta name="apple-mobile-web-app-title" content="Force Converter" />
      <meta name="application-name" content="Force Converter" />
      <meta name="msapplication-tooltip" content="Force Converter" />

      <link rel="canonical" href="https://casemodify.com/force-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/force-converter/" />
      <meta property="og:title" content="Force Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Force Unit Conversion - converts dyne, gram-force, newton, pond..." />
    </Helmet>
  );
};

export default ForceConverterMetaTags;
