import React from 'react';
import { Helmet } from 'react-helmet';

const VolumeConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Volume Converter | Case Modify</title>
      <meta name="description" content="Volume Unit Conversion - converts liter, cubic meter, gallon, quart, pint, bushel, barrel etc" />
      <meta name="Keywords" content="converts liter, cubic meter, gallon, quart, pint, bushel, barrel" />

      <meta name="apple-mobile-web-app-title" content="Volume Converter" />
      <meta name="application-name" content="Volume Converter" />
      <meta name="msapplication-tooltip" content="Volume Converter" />

      <link rel="canonical" href="https://casemodify.com/volume-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/volume-converter/" />
      <meta property="og:title" content="Volume Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Volume Unit Conversion - converts liter, cubic meter, gallon, quart, pint, bushel, barrel etc" />
    </Helmet>
  );
};

export default VolumeConverterMetaTags;
