import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './EnergyConverter.style';
import EnergyConverterMetaTags from '../../../components/MetaTag/EnergyConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const EnergyConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'Btu (th)': { toJoules: value => value * 1055.06, fromJoules: value => value / 1055.06 },
    'Btu (mean)': { toJoules: value => value * 1055.87, fromJoules: value => value / 1055.87 },
    'calories (IT)': { toJoules: value => value * 4.184, fromJoules: value => value / 4.184 },
    'calories (th)': { toJoules: value => value * 4.184, fromJoules: value => value / 4.184 },
    'calories (mean)': { toJoules: value => value * 4.19002, fromJoules: value => value / 4.19002 },
    'calories (15C)': { toJoules: value => value * 4.1858, fromJoules: value => value / 4.1858 },
    'calories (20C)': { toJoules: value => value * 4.1819, fromJoules: value => value / 4.1819 },
    'calories (food)': { toJoules: value => value * 4184, fromJoules: value => value / 4184 },
    'centigrade heat units': { toJoules: value => value * 1899.1, fromJoules: value => value / 1899.1 },
    'electron volts [eV]': { toJoules: value => value * 1.60218e-19, fromJoules: value => value / 1.60218e-19 },
    'ergs': { toJoules: value => value * 1e-7, fromJoules: value => value / 1e-7 },
    'foot-pound force [ft lbf]': { toJoules: value => value * 1.35582, fromJoules: value => value / 1.35582 },
    'foot poundals': { toJoules: value => value * 0.0421401, fromJoules: value => value / 0.0421401 },
    'gigajoules [GJ]': { toJoules: value => value * 1e9, fromJoules: value => value / 1e9 },
    'horsepower hours': { toJoules: value => value * 2.68452e6, fromJoules: value => value / 2.68452e6 },
    'inch-pound force [in lbf]': { toJoules: value => value * 0.112984, fromJoules: value => value / 0.112984 },
    'joules [J]': { toJoules: value => value, fromJoules: value => value },
    'kilocalories (IT)': { toJoules: value => value * 4184, fromJoules: value => value / 4184 },
    'kilocalories (th)': { toJoules: value => value * 4184, fromJoules: value => value / 4184 },
    'kilogram-force meters': { toJoules: value => value * 9.80665, fromJoules: value => value / 9.80665 },
    'kilojoules [kJ]': { toJoules: value => value * 1000, fromJoules: value => value / 1000 },
    'kilowatt hours [kWh]': { toJoules: value => value * 3.6e6, fromJoules: value => value / 3.6e6 },
    'megajoules [MJ]': { toJoules: value => value * 1e6, fromJoules: value => value / 1e6 },
    'newton meters [Nm]': { toJoules: value => value, fromJoules: value => value },
    'therms': { toJoules: value => value * 1.05506e8, fromJoules: value => value / 1.05506e8 },
    'watt seconds [Ws]': { toJoules: value => value, fromJoules: value => value },
    'watt hours [Wh]': { toJoules: value => value * 3600, fromJoules: value => value / 3600 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertEnergy(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertEnergy(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertEnergy(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const convertEnergy = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const joulesValue = units[fromUnit].toJoules(value);
    return units[toUnit].fromJoules(joulesValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'EnergyConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <EnergyConverterMetaTags />
      <div className='title'>
        <h1>Energy Converter</h1>
        <p>
          <label htmlFor='content'>
            Energy Unit Conversion - converts calories, Btu, gigajoules, joules, therms ...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default EnergyConverter;
