import React from 'react';
import { Anchor } from './Media.style';

const FacebookShare = () => {
  const currentUrl = window.location.href;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
  return (
    <Anchor href={facebookShareUrl} className="btn" target="_blank" rel="noopener noreferrer">
      Share on  <img className='social-logo' src="/icons/facebook.png" alt='facebook'/>
    </Anchor>
  );
};

export default FacebookShare;
