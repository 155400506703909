import React, { useState } from 'react';
import TextAnalyzer from '../../components/TextAnalyzer/TextAnalyzer';
import Description from '../../components/Description/Description';
import { downloadText, copyToClipboard } from '../../common/utils'
import { Container, CoffeeCup } from './Home.style';
import FacebookShare from '../../components/Media/FacebookShare';
import TwitterShare from '../../components/Media/TwitterShare';
import PayPalDonate from '../../components/Media/PaypalDonate';
import HomeMetaTags from '../../components/MetaTag/HomeMetaTags'

const Home = () => {

  const [inputText, setInputText] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const convertToSentenceCase = () => {
    setInputText(inputText.toLowerCase());
    setInputText(inputText.charAt(0).toUpperCase() + inputText.slice(1));
  };

  const convertToLowercase = () => {
    setInputText(inputText.toLowerCase());
  };

  const convertToUppercase = () => {
    setInputText(inputText.toUpperCase());
  };

  const convertToCapitalizedCase = () => {
    setInputText(inputText.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase()));
  };

  const convertToAlternatingCase = () => {
    setInputText(
      inputText
        .split('')
        .map((char, index) =>
          index % 2 === 0 ? char.toLowerCase() : char.toUpperCase()
        )
        .join('')
    );
  };

  const convertToTitleCase = () => {
    setInputText(
      inputText.replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      })
    );
  };

  const convertToInverseCase = () => {
    setInputText(
      inputText
        .split('')
        .map((char) =>
          char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
        )
        .join('')
    );
  };

  const handleDownload = () => {
    downloadText(inputText, 'casemodify.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(inputText);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleClear = () => {
    setInputText('');
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  return (
    <>
    <Container>
      <HomeMetaTags />
      <div className='title'>
        <h1>Left the caps lock on by mistake and typed something, but don't feel like starting over and retyping everything?</h1>
        <p><label>Enter your text and select the desired case for conversion.</label></p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="row">
        <textarea className="textarea" placeholder="Type or paste your content here..." autoFocus={true} value={inputText} onChange={handleTextChange}></textarea>
      </div>
      <div className="actions">
        <button className="btn" onClick={convertToSentenceCase}>
          Sentence case
        </button>
        <button className="btn" onClick={convertToLowercase}>
          Lower case
        </button>
        <button className="btn" onClick={convertToUppercase}>
          UPPER CASE
        </button>
        <button className="btn" onClick={convertToCapitalizedCase}>
          Capitalized Case
        </button>
        <button className="btn" onClick={convertToAlternatingCase}>
          aLtErNaTiNg cAsE
        </button>
        <button className="btn" onClick={convertToTitleCase}>
          Title Case
        </button>
        <button className="btn" onClick={convertToInverseCase}>
          InVeRsE CaSe
        </button>
        <button className="btn" onClick={handleDownload}>
          Download Text
        </button>
        <button className="btn" onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </button>
        <button className="btn" onClick={handleClear}>
          Clear
        </button>
        <a
          href="https://www.ko-fi.com/casemodify"
          className="btn-coffee"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
          Buy me a Coffee
        </a>
      </div>
      <div className="row-counts">
        <TextAnalyzer text={inputText} />
      </div>
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
      </Container>
      
      <Description />
    </>
  )
};

export default Home;