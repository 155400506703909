// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASsSAJY-d5xXd2d8yOZNUSR5LGL0CcvtU",
  authDomain: "case-modify.firebaseapp.com",
  projectId: "case-modify",
  storageBucket: "case-modify.appspot.com",
  messagingSenderId: "212593631180",
  appId: "1:212593631180:web:83b14a35b5eb8667468769",
  measurementId: "G-7ETGBN5C2B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);