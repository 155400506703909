import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './CursedTextTool.style';
import { convertToFontText } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import CursedTextMetaTags from '../../../components/MetaTag/CursedTextMetaTags';
import {
  cursedTextFont1, cursedTextFont2, cursedTextFont3, cursedTextFont4,
  cursedTextFont5, cursedTextFont6, cursedTextFont7, cursedTextFont8,
  cursedTextFont9, cursedTextFont10, cursedTextFont11, cursedTextFont12,
  cursedTextFont13 } from '../../../common/constants';

const CursedTextTool = () => {
  
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText1, setTransformedText1] = useState('');
  const [transformedText2, setTransformedText2] = useState('');
  const [transformedText3, setTransformedText3] = useState('');
  const [transformedText4, setTransformedText4] = useState('');
  const [transformedText5, setTransformedText5] = useState('');
  const [transformedText6, setTransformedText6] = useState('');
  const [transformedText7, setTransformedText7] = useState('');
  const [transformedText8, setTransformedText8] = useState('');
  const [transformedText9, setTransformedText9] = useState('');
  const [transformedText10, setTransformedText10] = useState('');
  const [transformedText11, setTransformedText11] = useState('');
  const [transformedText12, setTransformedText12] = useState('');
  const [transformedText13, setTransformedText13] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    setTransformedText1(convertToFontText(inputText, cursedTextFont1));
    setTransformedText2(convertToFontText(inputText, cursedTextFont2));
    setTransformedText3(convertToFontText(inputText, cursedTextFont3));
    setTransformedText4(convertToFontText(inputText, cursedTextFont4));
    setTransformedText5(convertToFontText(inputText, cursedTextFont5));
    setTransformedText6(convertToFontText(inputText, cursedTextFont6));
    setTransformedText7(convertToFontText(inputText, cursedTextFont7));
    setTransformedText8(convertToFontText(inputText, cursedTextFont8));
    setTransformedText9(convertToFontText(inputText, cursedTextFont9));
    setTransformedText10(convertToFontText(inputText, cursedTextFont10));
    setTransformedText11(convertToFontText(inputText, cursedTextFont11));
    setTransformedText12(convertToFontText(inputText, cursedTextFont12));
    setTransformedText13(convertToFontText(inputText, cursedTextFont13));
  };

  const handleDownload = () => {
    downloadText((combineDownloadableText()), 'FacebookText.txt');
  };

  const handleCopyTextToClipboard = (text) => {
    text && copyToClipboard(text);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(combineDownloadableText());

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const combineDownloadableText = () => {
    return transformedText1
      + "\n"
      + transformedText2
      + "\n"
      + transformedText3
      + "\n"
      + transformedText4
      + "\n"
      + transformedText5
      + "\n"
      + transformedText6
      + "\n"
      + transformedText7
      + "\n"
      + transformedText8
      + "\n"
      + transformedText9
      + "\n"
      + transformedText10
      + "\n"
      + transformedText11
      + "\n"
      + transformedText12
      + "\n"
      + transformedText13;
  }

  return (
    <Container>
      <CursedTextMetaTags />

      <div className="title">
        <h1>Cursed Text Tool</h1>
        <p>
          <label>  
            Explore our enchanted text generator to craft uniquely slanted text that complements your profile and content! If you crave dynamic and engaging posts, our tool is tailored just for you. Elevate your content with our cursed text generator, infusing a dash of thrill into your posts and ensuring they captivate attention amidst the crowd. Embrace the enchantment – give it a whirl!
          </label>
        </p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
            <p>
                {transformedText1} {' '}
                {transformedText1.length > 0 && (
                  <img
                    className='copy'
                    src={'/icons/copy.svg'}
                    alt="Copy to Clipboard"
                    onClick={() => handleCopyTextToClipboard(transformedText1)}
                  />
                )}
              </p>
              <p>
                {transformedText2} {' '}
                {transformedText2.length > 0 && (
                  <img
                    className='copy'
                    src={'/icons/copy.svg'}
                    alt="Copy to Clipboard"
                    onClick={() => handleCopyTextToClipboard(transformedText2)}
                  />
                )}
              </p>
              <p>
                {transformedText3} {' '}
                { transformedText3.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText3)}/>}
              </p>
              <p>
                {transformedText4} {' '}
                { transformedText4.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText4)}/>}
              </p>
              <p>
                {transformedText5} {' '}
                { transformedText5.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText5)}/>}
              </p>
              <p>
                {transformedText6} {' '}
                { transformedText6.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText6)}/>}
              </p>
              <p>
                {transformedText7} {' '}
                { transformedText7.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText7)}/>}
              </p>
              <p>
                {transformedText8} {' '}
                { transformedText8.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText8)}/>}
              </p>
              <p>
                {transformedText9} {' '}
                { transformedText9.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText9)}/>}
              </p>
              <p>
                {transformedText10} {' '}
                { transformedText10.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText10)}/>}
              </p>
              <p>
                {transformedText11} {' '}
                { transformedText11.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText11)}/>}
              </p>
              <p>
                {transformedText12} {' '}
                { transformedText12.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText12)}/>}
              </p>
              <p>
                {transformedText13} {' '}
                { transformedText13.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText13)}/>}
              </p>
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default CursedTextTool;
