import React from 'react';
import { Helmet } from 'react-helmet';

const MirrorTextMetaTags = () => {
  return (
    <Helmet>
      <title>Mirror Text Generator | Text Mirror Converter | Convert Case | Case Modify</title>
      <meta name="description" content="Looking for a Mirror Text Generator Online for Quick and Free Use? Check Out the Mirror Text Converter at Modify Case." />

      <meta name="apple-mobile-web-app-title" content="Mirror Text Generator" />
      <meta name="application-name" content="Mirror Text Generator" />
      <meta name="msapplication-tooltip" content="Mirror Text Generator" />

      <link rel="canonical" href="https://casemodify.com/mirror-text-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/mirror-text-generator/" />
      <meta property="og:title" content="Mirror Text Generator - Mirror Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking for a Mirror Text Generator Online for Quick and Free Use? Check Out the Mirror Text Converter at Modify Case." />
    </Helmet>
  );
};

export default MirrorTextMetaTags;
