import React from 'react';
import { Anchor } from './Media.style';

const TwitterShare = () => {
  const currentUrl = window.location.href;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
  return (
    <Anchor href={twitterShareUrl} className="btn" target="_blank" rel="noopener noreferrer">
      <img className='social-logo' src="/icons/twitter.png" alt='twitter' />
    </Anchor>
  );
};

export default TwitterShare;
