import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './AreaConverter.style';
import AreaConverterMetaTags from '../../../components/MetaTag/AreaConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const AreaConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    acre: { toSquareMeter: value => value * 4046.86, fromSquareMeter: value => value / 4046.86 },
    'are (a)': { toSquareMeter: value => value * 100, fromSquareMeter: value => value / 100 },
    'barn (b)': { toSquareMeter: value => value * 1e-28, fromSquareMeter: value => value / 1e-28 },
    'hectare (ha)': { toSquareMeter: value => value * 10000, fromSquareMeter: value => value / 10000 },
    homestead: { toSquareMeter: value => value * 647497.027, fromSquareMeter: value => value / 647497.027 },
    rood: { toSquareMeter: value => value * 1011.7141056, fromSquareMeter: value => value / 1011.7141056 },
    'square centimeter (cm^2)': { toSquareMeter: value => value * 0.0001, fromSquareMeter: value => value / 0.0001 },
    'square foot (ft^2)': { toSquareMeter: value => value * 0.092903, fromSquareMeter: value => value / 0.092903 },
    'square inch (in^2)': { toSquareMeter: value => value * 0.00064516, fromSquareMeter: value => value / 0.00064516 },
    'square kilometer (km^2)': { toSquareMeter: value => value * 1e6, fromSquareMeter: value => value / 1e6 },
    'square meter (m^2)': { toSquareMeter: value => value, fromSquareMeter: value => value },
    'square mile': { toSquareMeter: value => value * 2589988.11034, fromSquareMeter: value => value / 2589988.11034 },
    'square millimeter (mm^2)': { toSquareMeter: value => value * 1e-6, fromSquareMeter: value => value / 1e-6 },
    'square rod': { toSquareMeter: value => value * 25.2928526, fromSquareMeter: value => value / 25.2928526 },
    'square yard (yd^2)': { toSquareMeter: value => value * 0.836127, fromSquareMeter: value => value / 0.836127 },
    'township (twp)': { toSquareMeter: value => value * 93239571.972, fromSquareMeter: value => value / 93239571.972 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertArea(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertArea(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertArea(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const convertArea = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const squareMeterValue = units[fromUnit].toSquareMeter(value);
    return units[toUnit].fromSquareMeter(squareMeterValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'AreaConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <AreaConverterMetaTags />
      <div className='title'>
        <h1>Area Converter</h1>
        <p>
          <label htmlFor='content'>
            Area Unit Conversion - converts square meter, square foot, square mile, acre, hectare etc
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default AreaConverter;
