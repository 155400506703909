
export const cursedTextFont1 = {
  a: "𝔞",
  b: "𝔟",
  c: "𝔠",
  d: "𝔡",
  e: "𝔢",
  f: "𝔣",
  g: "𝔤",
  h: "𝔥",
  i: "𝔦",
  j: "𝔧",
  k: "𝔨",
  l: "𝔩",
  m: "𝔪",
  n: "𝔫",
  o: "𝔬",
  p: "𝔭",
  q: "𝔮",
  r: "𝔯",
  s: "𝔰",
  t: "𝔱",
  u: "𝔲",
  v: "𝔳",
  w: "𝔴",
  x: "𝔵",
  y: "𝔶",
  z: "𝔷",
  A: "𝔄",
  B: "𝔅",
  C: "ℭ",
  D: "𝔇",
  E: "𝔈",
  F: "𝔉",
  G: "𝔊",
  H: "ℌ",
  I: "ℑ",
  J: "𝔍",
  K: "𝔎",
  L: "𝔏",
  M: "𝔐",
  N: "𝔑",
  O: "𝔒",
  P: "𝔓",
  Q: "𝔔",
  R: "ℜ",
  S: "𝔖",
  T: "𝔗",
  U: "𝔘",
  V: "𝔙",
  W: "𝔚",
  X: "𝔛",
  Y: "𝔜",
  Z: "ℨ"
}
export const cursedTextFont2 = {
  a: "𝖆",
  b: "𝖇",
  c: "𝖈",
  d: "𝖉",
  e: "𝖊",
  f: "𝖋",
  g: "𝖌",
  h: "𝖍",
  i: "𝖎",
  j: "𝖏",
  k: "𝖐",
  l: "𝖑",
  m: "𝖒",
  n: "𝖓",
  o: "𝖔",
  p: "𝖕",
  q: "𝖖",
  r: "𝖗",
  s: "𝖘",
  t: "𝖙",
  u: "𝖚",
  v: "𝖛",
  w: "𝖜",
  x: "𝖝",
  y: "𝖞",
  z: "𝖟",
  A: "𝕬",
  B: "𝕭",
  C: "𝕮",
  D: "𝕯",
  E: "𝕰",
  F: "𝕱",
  G: "𝕲",
  H: "𝕳",
  I: "𝕴",
  J: "𝕵",
  K: "𝕶",
  L: "𝕷",
  M: "𝕸",
  N: "𝕹",
  O: "𝕺",
  P: "𝕻",
  Q: "𝕼",
  R: "𝕽",
  S: "𝕾",
  T: "𝕿",
  U: "𝖀",
  V: "𝖁",
  W: "𝖂",
  X: "𝖃",
  Y: "𝖄",
  Z: "𝖅",
  1: "❗",
  2: "𝟐",
  3: "𝟑",
  4: "𝟒",
  5: "𝟓",
  6: "𝟔",
  7: "𝟕",
  8: "𝟖",
  9: "𝟗",
  0: "𝟎",
  "?": "❓"
}
export const cursedTextFont3 = {
  a: "🅰",
  b: "🅱",
  c: "🅲",
  d: "🅳",
  e: "🅴",
  f: "🅵",
  g: "🅶",
  h: "🅷",
  i: "🅸",
  j: "🅹",
  k: "🅺",
  l: "🅻",
  m: "🅼",
  n: "🅽",
  o: "🅾",
  p: "🅿",
  q: "🆀",
  r: "🆁",
  s: "🆂",
  t: "🆃",
  u: "🆄",
  v: "🆅",
  w: "🆆",
  x: "🆇",
  y: "🆈",
  z: "🆉",
  A: "🅰",
  B: "🅱",
  C: "🅲",
  D: "🅳",
  E: "🅴",
  F: "🅵",
  G: "🅶",
  H: "🅷",
  I: "🅸",
  J: "🅹",
  K: "🅺",
  L: "🅻",
  M: "🅼",
  N: "🅽",
  O: "🅾",
  P: "🅿",
  Q: "🆀",
  R: "🆁",
  S: "🆂",
  T: "🆃",
  U: "🆄",
  V: "🆅",
  W: "🆆",
  X: "🆇",
  Y: "🆈",
  Z: "🆉"
}
export const cursedTextFont4 = {
  a: "丹",
  b: "日",
  c: "亡",
  d: "句",
  e: "ヨ",
  f: "乍",
  g: "呂",
  h: "廾",
  i: "工",
  j: "勹",
  k: "片",
  l: "し",
  m: "冊",
  n: "几",
  o: "回",
  p: "尸",
  q: "甲",
  r: "尺",
  s: "己",
  t: "卞",
  u: "凵",
  v: "レ",
  w: "山",
  x: "メ",
  y: "と",
  z: "乙",
  1: "１",
  2: "２",
  3: "３",
  4: "４",
  5: "５",
  6: "６",
  7: "７",
  8: "８",
  9: "９",
  0: "０"
}
export const cursedTextFont5 = {
  a: "ค",
  b: "๒",
  c: "ς",
  d: "๔",
  e: "є",
  f: "Ŧ",
  g: "ﻮ",
  h: "ђ",
  i: "เ",
  j: "ן",
  k: "к",
  l: "ɭ",
  m: "๓",
  n: "ภ",
  o: "๏",
  p: "ק",
  q: "ợ",
  r: "г",
  s: "ร",
  t: "Շ",
  u: "ย",
  v: "ש",
  w: "ฬ",
  x: "א",
  y: "ץ",
  z: "չ "
}
export const cursedTextFont6 = {
  a: "α",
  b: "Ⴆ",
  c: "ƈ",
  d: "ԃ",
  e: "ҽ",
  f: "ϝ",
  g: "ɠ",
  h: "ԋ",
  i: "ι",
  j: "ʝ",
  k: "ƙ",
  l: "ʅ",
  m: "ɱ",
  n: "ɳ",
  o: "σ",
  p: "ρ",
  q: "ϙ",
  r: "ɾ",
  s: "ʂ",
  t: "ƚ",
  u: "υ",
  v: "ʋ",
  w: "ɯ",
  x: "x",
  y: "ყ",
  z: "ȥ"
}
export const cursedTextFont7 = {
  a: "ǟ",
  b: "ɮ",
  c: "ƈ",
  d: "ɖ",
  e: "ɛ",
  f: "ʄ",
  g: "ɢ",
  h: "ɦ",
  i: "ɨ",
  j: "ʝ",
  k: "ӄ",
  l: "ʟ",
  m: "ʍ",
  n: "ռ",
  o: "օ",
  p: "ք",
  q: "զ",
  r: "ʀ",
  s: "ֆ",
  t: "ȶ",
  u: "ʊ",
  v: "ʋ",
  w: "ա",
  x: "Ӽ",
  y: "ʏ",
  z: "ʐ"
}
export const cursedTextFont8 = {
  a: "₳",
  b: "฿",
  c: "₵",
  d: "Đ",
  e: "Ɇ",
  f: "₣",
  g: "₲",
  h: "Ⱨ",
  i: "ł",
  j: "J",
  k: "₭",
  l: "Ⱡ",
  m: "₥",
  n: "₦",
  o: "Ø",
  p: "₱",
  q: "Q",
  r: "Ɽ",
  s: "₴",
  t: "₮",
  u: "Ʉ",
  v: "V",
  w: "₩",
  x: "Ӿ",
  y: "Ɏ",
  z: "Ⱬ "
}
export const cursedTextFont9 = {
  a: "Ａ",
  b: "ᵇ",
  c: "𝓬",
  d: "𝓭",
  e: "Ｅ",
  f: "ⓕ",
  g: "Ｇ",
  h: "𝓱",
  i: "𝐢",
  j: "נ",
  k: "𝕂",
  l: "𝔩",
  m: "爪",
  n: "Ň",
  o: "σ",
  p: "ｐ",
  q: "ⓠ",
  r: "я",
  s: "ş",
  t: "t",
  u: "𝕌",
  v: "Ѷ",
  w: "Ⓦ",
  x: "𝐗",
  y: "𝕪",
  z: "𝓏",
  1: "１",
  2: "➁",
  3: "３",
  4: "➃",
  5: "❺",
  6: "６",
  7: "７",
  8: "❽",
  9: "❾",
  0: "Ѳ"
}
export const cursedTextFont10 = {
  a: "𝒶",
  b: "𝓫",
  c: "𝓬",
  d: "𝓭",
  e: "𝐄",
  f: "𝔽",
  g: "Ğ",
  h: "𝓗",
  i: "เ",
  j: "ڶ",
  k: "Ҝ",
  l: "ᒪ",
  m: "𝐦",
  n: "𝓝",
  o: "ㄖ",
  p: "Ⓟ",
  q: "ợ",
  r: "ℝ",
  s: "𝕤",
  t: "𝓉",
  u: "𝓤",
  v: "ᵛ",
  w: "Ŵ",
  x: "ⓧ",
  y: "ʸ",
  z: "ℤ",
  1: "➀",
  2: "２",
  3: "➂",
  4: "４",
  5: "５",
  6: "❻",
  7: "❼",
  8: "➇",
  9: "❾",
  0: "ʘ"
}
export const cursedTextFont11 = {
  a: "ꋬ",
  b: "ꃳ",
  c: "ꉔ",
  d: "꒯",
  e: "ꏂ",
  f: "ꊰ",
  g: "ꍌ",
  h: "ꁝ",
  i: "꒐",
  j: "꒻",
  k: "ꀘ",
  l: "꒒",
  m: "ꂵ",
  n: "ꋊ",
  o: "ꄲ",
  p: "ꉣ",
  q: "ꆰ",
  r: "ꋪ",
  s: "ꇙ",
  t: "꓄",
  u: "꒤",
  v: "꒦",
  w: "ꅐ",
  x: "ꉧ",
  y: "ꌦ",
  z: "ꁴ"
}
export const cursedTextFont12 = {
  a: "ꁲ",
  b: "ꋰ",
  c: "ꀯ",
  d: "ꂠ",
  e: "ꈼ",
  f: "ꄞ",
  g: "ꁅ",
  h: "ꍩ",
  i: "ꂑ",
  j: "꒻",
  k: "ꀗ",
  l: "꒒",
  m: "ꂵ",
  n: "ꋊ",
  o: "ꂦ",
  p: "ꉣ",
  q: "ꁷ",
  r: "ꌅ",
  s: "ꌚ",
  t: "ꋖ",
  u: "ꐇ",
  v: "ꀰ",
  w: "ꅏ",
  x: "ꇒ",
  y: "ꐞ",
  z: "ꁴ"
}
export const cursedTextFont13 = {
  a: "ᗩ",
  b: "ᗷ",
  c: "ᑕ",
  d: "ᗪ",
  e: "E",
  f: "ᖴ",
  g: "G",
  h: "ᕼ",
  i: "I",
  j: "ᒍ",
  k: "K",
  l: "ᒪ",
  m: "ᗰ",
  n: "ᑎ",
  o: "O",
  p: "ᑭ",
  q: "ᑫ",
  r: "ᖇ",
  s: "ᔕ",
  t: "T",
  u: "ᑌ",
  v: "ᐯ",
  w: "ᗯ",
  x: "᙭",
  y: "Y",
  z: "ᘔ"
};

export const facebookFont1 = {
  a: "𝔞",
  b: "𝔟",
  c: "𝔠",
  d: "𝔡",
  e: "𝔢",
  f: "𝔣",
  g: "𝔤",
  h: "𝔥",
  i: "𝔦",
  j: "𝔧",
  k: "𝔨",
  l: "𝔩",
  m: "𝔪",
  n: "𝔫",
  o: "𝔬",
  p: "𝔭",
  q: "𝔮",
  r: "𝔯",
  s: "𝔰",
  t: "𝔱",
  u: "𝔲",
  v: "𝔳",
  w: "𝔴",
  x: "𝔵",
  y: "𝔶",
  z: "𝔷",
  A: "𝔄",
  B: "𝔅",
  C: "ℭ",
  D: "𝔇",
  E: "𝔈",
  F: "𝔉",
  G: "𝔊",
  H: "ℌ",
  I: "ℑ",
  J: "𝔍",
  K: "𝔎",
  L: "𝔏",
  M: "𝔐",
  N: "𝔑",
  O: "𝔒",
  P: "𝔓",
  Q: "𝔔",
  R: "ℜ",
  S: "𝔖",
  T: "𝔗",
  U: "𝔘",
  V: "𝔙",
  W: "𝔚",
  X: "𝔛",
  Y: "𝔜",
  Z: "ℨ"
}
  , facebookFont2 = {
    a: "𝖆",
    b: "𝖇",
    c: "𝖈",
    d: "𝖉",
    e: "𝖊",
    f: "𝖋",
    g: "𝖌",
    h: "𝖍",
    i: "𝖎",
    j: "𝖏",
    k: "𝖐",
    l: "𝖑",
    m: "𝖒",
    n: "𝖓",
    o: "𝖔",
    p: "𝖕",
    q: "𝖖",
    r: "𝖗",
    s: "𝖘",
    t: "𝖙",
    u: "𝖚",
    v: "𝖛",
    w: "𝖜",
    x: "𝖝",
    y: "𝖞",
    z: "𝖟",
    A: "𝕬",
    B: "𝕭",
    C: "𝕮",
    D: "𝕯",
    E: "𝕰",
    F: "𝕱",
    G: "𝕲",
    H: "𝕳",
    I: "𝕴",
    J: "𝕵",
    K: "𝕶",
    L: "𝕷",
    M: "𝕸",
    N: "𝕹",
    O: "𝕺",
    P: "𝕻",
    Q: "𝕼",
    R: "𝕽",
    S: "𝕾",
    T: "𝕿",
    U: "𝖀",
    V: "𝖁",
    W: "𝖂",
    X: "𝖃",
    Y: "𝖄",
    Z: "𝖅",
    1: "❗",
    2: "𝟐",
    3: "𝟑",
    4: "𝟒",
    5: "𝟓",
    6: "𝟔",
    7: "𝟕",
    8: "𝟖",
    9: "𝟗",
    0: "𝟎",
    "?": "❓"
  }
  , facebookFont3 = {
    a: "ⓐ",
    b: "ⓑ",
    c: "ⓒ",
    d: "ⓓ",
    e: "ⓔ",
    f: "ⓕ",
    g: "ⓖ",
    h: "ⓗ",
    i: "ⓘ",
    j: "ⓙ",
    k: "ⓚ",
    l: "ⓛ",
    m: "ⓜ",
    n: "ⓝ",
    o: "ⓞ",
    p: "ⓟ",
    q: "ⓠ",
    r: "ⓡ",
    s: "ⓢ",
    t: "ⓣ",
    u: "ⓤ",
    v: "ⓥ",
    w: "ⓦ",
    x: "ⓧ",
    y: "ⓨ",
    z: "ⓩ",
    A: "Ⓐ",
    B: "Ⓑ",
    C: "Ⓒ",
    D: "Ⓓ",
    E: "Ⓔ",
    F: "Ⓕ",
    G: "Ⓖ",
    H: "Ⓗ",
    I: "Ⓘ",
    J: "Ⓙ",
    K: "Ⓚ",
    L: "Ⓛ",
    M: "Ⓜ",
    N: "Ⓝ",
    O: "Ⓞ",
    P: "Ⓟ",
    Q: "Ⓠ",
    R: "Ⓡ",
    S: "Ⓢ",
    T: "Ⓣ",
    U: "Ⓤ",
    V: "Ⓥ",
    W: "Ⓦ",
    X: "Ⓧ",
    Y: "Ⓨ",
    Z: "Ⓩ",
    1: "①",
    2: "②",
    3: "③",
    4: "④",
    5: "⑤",
    6: "⑥",
    7: "⑦",
    8: "⑧",
    9: "⑨",
    0: "⓪"
  }
  , facebookFont4 = {
    a: "🅐",
    b: "🅑",
    c: "🅒",
    d: "🅓",
    e: "🅔",
    f: "🅕",
    g: "🅖",
    h: "🅗",
    i: "🅘",
    j: "🅙",
    k: "🅚",
    l: "🅛",
    m: "🅜",
    n: "🅝",
    o: "🅞",
    p: "🅟",
    q: "🅠",
    r: "🅡",
    s: "🅢",
    t: "🅣",
    u: "🅤",
    v: "🅥",
    w: "🅦",
    x: "🅧",
    y: "🅨",
    z: "🅩",
    1: "➊",
    2: "➋",
    3: "➌",
    4: "➍",
    5: "➎",
    6: "➏",
    7: "➐",
    8: "➑",
    9: "➒",
    0: "⓿"
  }
  , facebookFont5 = {
    a: "🄰",
    b: "🄱",
    c: "🄲",
    d: "🄳",
    e: "🄴",
    f: "🄵",
    g: "🄶",
    h: "🄷",
    i: "🄸",
    j: "🄹",
    k: "🄺",
    l: "🄻",
    m: "🄼",
    n: "🄽",
    o: "🄾",
    p: "🄿",
    q: "🅀",
    r: "🅁",
    s: "🅂",
    t: "🅃",
    u: "🅄",
    v: "🅅",
    w: "🅆",
    x: "🅇",
    y: "🅈",
    z: "🅉",
    A: "🄰",
    B: "🄱",
    C: "🄲",
    D: "🄳",
    E: "🄴",
    F: "🄵",
    G: "🄶",
    H: "🄷",
    I: "🄸",
    J: "🄹",
    K: "🄺",
    L: "🄻",
    M: "🄼",
    N: "🄽",
    O: "🄾",
    P: "🄿",
    Q: "🅀",
    R: "🅁",
    S: "🅂",
    T: "🅃",
    U: "🅄",
    V: "🅅",
    W: "🅆",
    X: "🅇",
    Y: "🅈",
    Z: "🅉",
    ".": "⊡"
  }
  , facebookFont6 = {
    a: "🅰",
    b: "🅱",
    c: "🅲",
    d: "🅳",
    e: "🅴",
    f: "🅵",
    g: "🅶",
    h: "🅷",
    i: "🅸",
    j: "🅹",
    k: "🅺",
    l: "🅻",
    m: "🅼",
    n: "🅽",
    o: "🅾",
    p: "🅿",
    q: "🆀",
    r: "🆁",
    s: "🆂",
    t: "🆃",
    u: "🆄",
    v: "🆅",
    w: "🆆",
    x: "🆇",
    y: "🆈",
    z: "🆉",
    A: "🅰",
    B: "🅱",
    C: "🅲",
    D: "🅳",
    E: "🅴",
    F: "🅵",
    G: "🅶",
    H: "🅷",
    I: "🅸",
    J: "🅹",
    K: "🅺",
    L: "🅻",
    M: "🅼",
    N: "🅽",
    O: "🅾",
    P: "🅿",
    Q: "🆀",
    R: "🆁",
    S: "🆂",
    T: "🆃",
    U: "🆄",
    V: "🆅",
    W: "🆆",
    X: "🆇",
    Y: "🆈",
    Z: "🆉"
  }
  , facebookFont7 = {
    a: "丹",
    b: "日",
    c: "亡",
    d: "句",
    e: "ヨ",
    f: "乍",
    g: "呂",
    h: "廾",
    i: "工",
    j: "勹",
    k: "片",
    l: "し",
    m: "冊",
    n: "几",
    o: "回",
    p: "尸",
    q: "甲",
    r: "尺",
    s: "己",
    t: "卞",
    u: "凵",
    v: "レ",
    w: "山",
    x: "メ",
    y: "と",
    z: "乙",
    1: "１",
    2: "２",
    3: "３",
    4: "４",
    5: "５",
    6: "６",
    7: "７",
    8: "８",
    9: "９",
    0: "０"
  }
  , facebookFont8 = {
    a: "ค",
    b: "๒",
    c: "ς",
    d: "๔",
    e: "є",
    f: "Ŧ",
    g: "ﻮ",
    h: "ђ",
    i: "เ",
    j: "ן",
    k: "к",
    l: "ɭ",
    m: "๓",
    n: "ภ",
    o: "๏",
    p: "ק",
    q: "ợ",
    r: "г",
    s: "ร",
    t: "Շ",
    u: "ย",
    v: "ש",
    w: "ฬ",
    x: "א",
    y: "ץ",
    z: "չ "
  }
  , facebookFont9 = {
    a: "α",
    b: "Ⴆ",
    c: "ƈ",
    d: "ԃ",
    e: "ҽ",
    f: "ϝ",
    g: "ɠ",
    h: "ԋ",
    i: "ι",
    j: "ʝ",
    k: "ƙ",
    l: "ʅ",
    m: "ɱ",
    n: "ɳ",
    o: "σ",
    p: "ρ",
    q: "ϙ",
    r: "ɾ",
    s: "ʂ",
    t: "ƚ",
    u: "υ",
    v: "ʋ",
    w: "ɯ",
    x: "x",
    y: "ყ",
    z: "ȥ"
  }
  , facebookFont10 = {
    a: "ǟ",
    b: "ɮ",
    c: "ƈ",
    d: "ɖ",
    e: "ɛ",
    f: "ʄ",
    g: "ɢ",
    h: "ɦ",
    i: "ɨ",
    j: "ʝ",
    k: "ӄ",
    l: "ʟ",
    m: "ʍ",
    n: "ռ",
    o: "օ",
    p: "ք",
    q: "զ",
    r: "ʀ",
    s: "ֆ",
    t: "ȶ",
    u: "ʊ",
    v: "ʋ",
    w: "ա",
    x: "Ӽ",
    y: "ʏ",
    z: "ʐ"
  }
  , facebookFont11 = {
    a: "₳",
    b: "฿",
    c: "₵",
    d: "Đ",
    e: "Ɇ",
    f: "₣",
    g: "₲",
    h: "Ⱨ",
    i: "ł",
    j: "J",
    k: "₭",
    l: "Ⱡ",
    m: "₥",
    n: "₦",
    o: "Ø",
    p: "₱",
    q: "Q",
    r: "Ɽ",
    s: "₴",
    t: "₮",
    u: "Ʉ",
    v: "V",
    w: "₩",
    x: "Ӿ",
    y: "Ɏ",
    z: "Ⱬ "
  }
  , facebookFont12 = {
    a: "Ａ",
    b: "ᵇ",
    c: "𝓬",
    d: "𝓭",
    e: "Ｅ",
    f: "ⓕ",
    g: "Ｇ",
    h: "𝓱",
    i: "𝐢",
    j: "נ",
    k: "𝕂",
    l: "𝔩",
    m: "爪",
    n: "Ň",
    o: "σ",
    p: "ｐ",
    q: "ⓠ",
    r: "я",
    s: "ş",
    t: "t",
    u: "𝕌",
    v: "Ѷ",
    w: "Ⓦ",
    x: "𝐗",
    y: "𝕪",
    z: "𝓏",
    1: "１",
    2: "➁",
    3: "３",
    4: "➃",
    5: "❺",
    6: "６",
    7: "７",
    8: "❽",
    9: "❾",
    0: "Ѳ"
  }
  , facebookFont13 = {
    a: "𝒶",
    b: "𝓫",
    c: "𝓬",
    d: "𝓭",
    e: "𝐄",
    f: "𝔽",
    g: "Ğ",
    h: "𝓗",
    i: "เ",
    j: "ڶ",
    k: "Ҝ",
    l: "ᒪ",
    m: "𝐦",
    n: "𝓝",
    o: "ㄖ",
    p: "Ⓟ",
    q: "ợ",
    r: "ℝ",
    s: "𝕤",
    t: "𝓉",
    u: "𝓤",
    v: "ᵛ",
    w: "Ŵ",
    x: "ⓧ",
    y: "ʸ",
    z: "ℤ",
    1: "➀",
    2: "２",
    3: "➂",
    4: "４",
    5: "５",
    6: "❻",
    7: "❼",
    8: "➇",
    9: "❾",
    0: "ʘ"
  }
  , facebookFont14 = {
    a: "ꋬ",
    b: "ꃳ",
    c: "ꉔ",
    d: "꒯",
    e: "ꏂ",
    f: "ꊰ",
    g: "ꍌ",
    h: "ꁝ",
    i: "꒐",
    j: "꒻",
    k: "ꀘ",
    l: "꒒",
    m: "ꂵ",
    n: "ꋊ",
    o: "ꄲ",
    p: "ꉣ",
    q: "ꆰ",
    r: "ꋪ",
    s: "ꇙ",
    t: "꓄",
    u: "꒤",
    v: "꒦",
    w: "ꅐ",
    x: "ꉧ",
    y: "ꌦ",
    z: "ꁴ"
  }
  , facebookFont15 = {
    a: "ꁲ",
    b: "ꋰ",
    c: "ꀯ",
    d: "ꂠ",
    e: "ꈼ",
    f: "ꄞ",
    g: "ꁅ",
    h: "ꍩ",
    i: "ꂑ",
    j: "꒻",
    k: "ꀗ",
    l: "꒒",
    m: "ꂵ",
    n: "ꋊ",
    o: "ꂦ",
    p: "ꉣ",
    q: "ꁷ",
    r: "ꌅ",
    s: "ꌚ",
    t: "ꋖ",
    u: "ꐇ",
    v: "ꀰ",
    w: "ꅏ",
    x: "ꇒ",
    y: "ꐞ",
    z: "ꁴ"
  }
  , facebookFont16 = {
    a: "ᗩ",
    b: "ᗷ",
    c: "ᑕ",
    d: "ᗪ",
    e: "E",
    f: "ᖴ",
    g: "G",
    h: "ᕼ",
    i: "I",
    j: "ᒍ",
    k: "K",
    l: "ᒪ",
    m: "ᗰ",
    n: "ᑎ",
    o: "O",
    p: "ᑭ",
    q: "ᑫ",
    r: "ᖇ",
    s: "ᔕ",
    t: "T",
    u: "ᑌ",
    v: "ᐯ",
    w: "ᗯ",
    x: "᙭",
    y: "Y",
    z: "ᘔ"
  }
  , facebookFont17 = {
    a: "𝒶",
    b: "𝒷",
    c: "𝒸",
    d: "𝒹",
    e: "ℯ",
    f: "𝒻",
    g: "ℊ",
    h: "𝒽",
    i: "𝒾",
    j: "𝒿",
    k: "𝓀",
    l: "𝓁",
    m: "𝓂",
    n: "𝓃",
    o: "ℴ",
    p: "𝓅",
    q: "𝓆",
    r: "𝓇",
    s: "𝓈",
    t: "𝓉",
    u: "𝓊",
    v: "𝓋",
    w: "𝓌",
    x: "𝓍",
    y: "𝓎",
    z: "𝓏",
    A: "𝒜",
    B: "ℬ",
    C: "𝒞",
    D: "𝒟",
    E: "ℰ",
    F: "ℱ",
    G: "𝒢",
    H: "ℋ",
    I: "ℐ",
    J: "𝒥",
    K: "𝒦",
    L: "ℒ",
    M: "ℳ",
    N: "𝒩",
    O: "𝒪",
    P: "𝒫",
    Q: "𝒬",
    R: "ℛ",
    S: "𝒮",
    T: "𝒯",
    U: "𝒰",
    V: "𝒱",
    W: "𝒲",
    X: "𝒳",
    Y: "𝒴",
    Z: "𝒵"
  }
  , facebookFont18 = {
    a: "𝓪",
    b: "𝓫",
    c: "𝓬",
    d: "𝓭",
    e: "𝓮",
    f: "𝓯",
    g: "𝓰",
    h: "𝓱",
    i: "𝓲",
    j: "𝓳",
    k: "𝓴",
    l: "𝓵",
    m: "𝓶",
    n: "𝓷",
    o: "𝓸",
    p: "𝓹",
    q: "𝓺",
    r: "𝓻",
    s: "𝓼",
    t: "𝓽",
    u: "𝓾",
    v: "𝓿",
    w: "𝔀",
    x: "𝔁",
    y: "𝔂",
    z: "𝔃",
    A: "𝓐",
    B: "𝓑",
    C: "𝓒",
    D: "𝓓",
    E: "𝓔",
    F: "𝓕",
    G: "𝓖",
    H: "𝓗",
    I: "𝓘",
    J: "𝓙",
    K: "𝓚",
    L: "𝓛",
    M: "𝓜",
    N: "𝓝",
    O: "𝓞",
    P: "𝓟",
    Q: "𝓠",
    R: "𝓡",
    S: "𝓢",
    T: "𝓣",
    U: "𝓤",
    V: "𝓥",
    W: "𝓦",
    X: "𝓧",
    Y: "𝓨",
    Z: "𝓩",
    1: "𝟏",
    2: "𝟐",
    3: "𝟑",
    4: "𝟒",
    5: "𝟓",
    6: "𝟔",
    7: "𝟕",
    8: "𝟖",
    9: "𝟗",
    0: "𝟎",
    "!": "❗",
    "?": "❓"
  }
  , facebookFont19 = {
    " ": " ",
    0: "０",
    1: "１",
    2: "２",
    3: "３",
    4: "４",
    5: "５",
    6: "６",
    7: "７",
    8: "８",
    9: "９",
    a: "ａ",
    b: "ｂ",
    c: "ｃ",
    d: "ｄ",
    e: "ｅ",
    f: "ｆ",
    g: "ｇ",
    h: "ｈ",
    i: "ｉ",
    j: "ｊ",
    k: "ｋ",
    l: "ｌ",
    m: "ｍ",
    n: "ｎ",
    o: "ｏ",
    p: "ｐ",
    q: "ｑ",
    r: "ｒ",
    s: "ｓ",
    t: "ｔ",
    u: "ｕ",
    v: "ｖ",
    w: "ｗ",
    x: "ｘ",
    y: "ｙ",
    z: "ｚ",
    A: "Ａ",
    B: "Ｂ",
    C: "Ｃ",
    D: "Ｄ",
    E: "Ｅ",
    F: "Ｆ",
    G: "Ｇ",
    H: "Ｈ",
    I: "Ｉ",
    J: "Ｊ",
    K: "Ｋ",
    L: "Ｌ",
    M: "Ｍ",
    N: "Ｎ",
    O: "Ｏ",
    P: "Ｐ",
    Q: "Ｑ",
    R: "Ｒ",
    S: "Ｓ",
    T: "Ｔ",
    U: "Ｕ",
    V: "Ｖ",
    W: "Ｗ",
    X: "Ｘ",
    Y: "Ｙ",
    Z: "Ｚ",
    "!": "！",
    '"': "゛",
    "#": "＃",
    $: "＄",
    "%": "％",
    "&": "＆",
    "(": "（",
    ")": "）",
    "*": "＊",
    "+": "＋",
    ",": "、",
    "-": "ー",
    ".": "。",
    "/": "／",
    ":": "：",
    ";": "；",
    "<": "〈",
    "=": "＝",
    ">": "〉",
    "?": "？",
    "@": "＠",
    "[": "［",
    "'": "'",
    "]": "］",
    "^": "＾",
    _: "＿",
    "`": "‘",
    "{": "｛",
    "|": "｜",
    "}": "｝",
    "~": "～"
  };

export const unicodeFont1 = {
  a: "𝒶",
  b: "𝒷",
  c: "𝒸",
  d: "𝒹",
  e: "ℯ",
  f: "𝒻",
  g: "ℊ",
  h: "𝒽",
  i: "𝒾",
  j: "𝒿",
  k: "𝓀",
  l: "𝓁",
  m: "𝓂",
  n: "𝓃",
  o: "ℴ",
  p: "𝓅",
  q: "𝓆",
  r: "𝓇",
  s: "𝓈",
  t: "𝓉",
  u: "𝓊",
  v: "𝓋",
  w: "𝓌",
  x: "𝓍",
  y: "𝓎",
  z: "𝓏",
  A: "𝒜",
  B: "ℬ",
  C: "𝒞",
  D: "𝒟",
  E: "ℰ",
  F: "ℱ",
  G: "𝒢",
  H: "ℋ",
  I: "ℐ",
  J: "𝒥",
  K: "𝒦",
  L: "ℒ",
  M: "ℳ",
  N: "𝒩",
  O: "𝒪",
  P: "𝒫",
  Q: "𝒬",
  R: "ℛ",
  S: "𝒮",
  T: "𝒯",
  U: "𝒰",
  V: "𝒱",
  W: "𝒲",
  X: "𝒳",
  Y: "𝒴",
  Z: "𝒵"
}
  , unicodeFont2 = {
    a: "𝓪",
    b: "𝓫",
    c: "𝓬",
    d: "𝓭",
    e: "𝓮",
    f: "𝓯",
    g: "𝓰",
    h: "𝓱",
    i: "𝓲",
    j: "𝓳",
    k: "𝓴",
    l: "𝓵",
    m: "𝓶",
    n: "𝓷",
    o: "𝓸",
    p: "𝓹",
    q: "𝓺",
    r: "𝓻",
    s: "𝓼",
    t: "𝓽",
    u: "𝓾",
    v: "𝓿",
    w: "𝔀",
    x: "𝔁",
    y: "𝔂",
    z: "𝔃",
    A: "𝓐",
    B: "𝓑",
    C: "𝓒",
    D: "𝓓",
    E: "𝓔",
    F: "𝓕",
    G: "𝓖",
    H: "𝓗",
    I: "𝓘",
    J: "𝓙",
    K: "𝓚",
    L: "𝓛",
    M: "𝓜",
    N: "𝓝",
    O: "𝓞",
    P: "𝓟",
    Q: "𝓠",
    R: "𝓡",
    S: "𝓢",
    T: "𝓣",
    U: "𝓤",
    V: "𝓥",
    W: "𝓦",
    X: "𝓧",
    Y: "𝓨",
    Z: "𝓩",
    1: "𝟏",
    2: "𝟐",
    3: "𝟑",
    4: "𝟒",
    5: "𝟓",
    6: "𝟔",
    7: "𝟕",
    8: "𝟖",
    9: "𝟗",
    0: "𝟎",
    "!": "❗",
    "?": "❓"
  }
  , unicodeFont3 = {
    a: "𝔞",
    b: "𝔟",
    c: "𝔠",
    d: "𝔡",
    e: "𝔢",
    f: "𝔣",
    g: "𝔤",
    h: "𝔥",
    i: "𝔦",
    j: "𝔧",
    k: "𝔨",
    l: "𝔩",
    m: "𝔪",
    n: "𝔫",
    o: "𝔬",
    p: "𝔭",
    q: "𝔮",
    r: "𝔯",
    s: "𝔰",
    t: "𝔱",
    u: "𝔲",
    v: "𝔳",
    w: "𝔴",
    x: "𝔵",
    y: "𝔶",
    z: "𝔷",
    A: "𝔄",
    B: "𝔅",
    C: "ℭ",
    D: "𝔇",
    E: "𝔈",
    F: "𝔉",
    G: "𝔊",
    H: "ℌ",
    I: "ℑ",
    J: "𝔍",
    K: "𝔎",
    L: "𝔏",
    M: "𝔐",
    N: "𝔑",
    O: "𝔒",
    P: "𝔓",
    Q: "𝔔",
    R: "ℜ",
    S: "𝔖",
    T: "𝔗",
    U: "𝔘",
    V: "𝔙",
    W: "𝔚",
    X: "𝔛",
    Y: "𝔜",
    Z: "ℨ"
  }
  , unicodeFont4 = {
    a: "𝖆",
    b: "𝖇",
    c: "𝖈",
    d: "𝖉",
    e: "𝖊",
    f: "𝖋",
    g: "𝖌",
    h: "𝖍",
    i: "𝖎",
    j: "𝖏",
    k: "𝖐",
    l: "𝖑",
    m: "𝖒",
    n: "𝖓",
    o: "𝖔",
    p: "𝖕",
    q: "𝖖",
    r: "𝖗",
    s: "𝖘",
    t: "𝖙",
    u: "𝖚",
    v: "𝖛",
    w: "𝖜",
    x: "𝖝",
    y: "𝖞",
    z: "𝖟",
    A: "𝕬",
    B: "𝕭",
    C: "𝕮",
    D: "𝕯",
    E: "𝕰",
    F: "𝕱",
    G: "𝕲",
    H: "𝕳",
    I: "𝕴",
    J: "𝕵",
    K: "𝕶",
    L: "𝕷",
    M: "𝕸",
    N: "𝕹",
    O: "𝕺",
    P: "𝕻",
    Q: "𝕼",
    R: "𝕽",
    S: "𝕾",
    T: "𝕿",
    U: "𝖀",
    V: "𝖁",
    W: "𝖂",
    X: "𝖃",
    Y: "𝖄",
    Z: "𝖅",
    1: "❗",
    2: "𝟐",
    3: "𝟑",
    4: "𝟒",
    5: "𝟓",
    6: "𝟔",
    7: "𝟕",
    8: "𝟖",
    9: "𝟗",
    0: "𝟎",
    "?": "❓"
  }
  , unicodeFont5 = {
    a: "ⓐ",
    b: "ⓑ",
    c: "ⓒ",
    d: "ⓓ",
    e: "ⓔ",
    f: "ⓕ",
    g: "ⓖ",
    h: "ⓗ",
    i: "ⓘ",
    j: "ⓙ",
    k: "ⓚ",
    l: "ⓛ",
    m: "ⓜ",
    n: "ⓝ",
    o: "ⓞ",
    p: "ⓟ",
    q: "ⓠ",
    r: "ⓡ",
    s: "ⓢ",
    t: "ⓣ",
    u: "ⓤ",
    v: "ⓥ",
    w: "ⓦ",
    x: "ⓧ",
    y: "ⓨ",
    z: "ⓩ",
    A: "Ⓐ",
    B: "Ⓑ",
    C: "Ⓒ",
    D: "Ⓓ",
    E: "Ⓔ",
    F: "Ⓕ",
    G: "Ⓖ",
    H: "Ⓗ",
    I: "Ⓘ",
    J: "Ⓙ",
    K: "Ⓚ",
    L: "Ⓛ",
    M: "Ⓜ",
    N: "Ⓝ",
    O: "Ⓞ",
    P: "Ⓟ",
    Q: "Ⓠ",
    R: "Ⓡ",
    S: "Ⓢ",
    T: "Ⓣ",
    U: "Ⓤ",
    V: "Ⓥ",
    W: "Ⓦ",
    X: "Ⓧ",
    Y: "Ⓨ",
    Z: "Ⓩ",
    1: "①",
    2: "②",
    3: "③",
    4: "④",
    5: "⑤",
    6: "⑥",
    7: "⑦",
    8: "⑧",
    9: "⑨",
    0: "⓪"
  }
  , unicodeFont6 = {
    a: "🅐",
    b: "🅑",
    c: "🅒",
    d: "🅓",
    e: "🅔",
    f: "🅕",
    g: "🅖",
    h: "🅗",
    i: "🅘",
    j: "🅙",
    k: "🅚",
    l: "🅛",
    m: "🅜",
    n: "🅝",
    o: "🅞",
    p: "🅟",
    q: "🅠",
    r: "🅡",
    s: "🅢",
    t: "🅣",
    u: "🅤",
    v: "🅥",
    w: "🅦",
    x: "🅧",
    y: "🅨",
    z: "🅩",
    1: "➊",
    2: "➋",
    3: "➌",
    4: "➍",
    5: "➎",
    6: "➏",
    7: "➐",
    8: "➑",
    9: "➒",
    0: "⓿"
  }
  , unicodeFont7 = {
    a: "🄰",
    b: "🄱",
    c: "🄲",
    d: "🄳",
    e: "🄴",
    f: "🄵",
    g: "🄶",
    h: "🄷",
    i: "🄸",
    j: "🄹",
    k: "🄺",
    l: "🄻",
    m: "🄼",
    n: "🄽",
    o: "🄾",
    p: "🄿",
    q: "🅀",
    r: "🅁",
    s: "🅂",
    t: "🅃",
    u: "🅄",
    v: "🅅",
    w: "🅆",
    x: "🅇",
    y: "🅈",
    z: "🅉",
    A: "🄰",
    B: "🄱",
    C: "🄲",
    D: "🄳",
    E: "🄴",
    F: "🄵",
    G: "🄶",
    H: "🄷",
    I: "🄸",
    J: "🄹",
    K: "🄺",
    L: "🄻",
    M: "🄼",
    N: "🄽",
    O: "🄾",
    P: "🄿",
    Q: "🅀",
    R: "🅁",
    S: "🅂",
    T: "🅃",
    U: "🅄",
    V: "🅅",
    W: "🅆",
    X: "🅇",
    Y: "🅈",
    Z: "🅉",
    ".": "⊡"
  }
  , unicodeFont8 = {
    a: "🅰",
    b: "🅱",
    c: "🅲",
    d: "🅳",
    e: "🅴",
    f: "🅵",
    g: "🅶",
    h: "🅷",
    i: "🅸",
    j: "🅹",
    k: "🅺",
    l: "🅻",
    m: "🅼",
    n: "🅽",
    o: "🅾",
    p: "🅿",
    q: "🆀",
    r: "🆁",
    s: "🆂",
    t: "🆃",
    u: "🆄",
    v: "🆅",
    w: "🆆",
    x: "🆇",
    y: "🆈",
    z: "🆉",
    A: "🅰",
    B: "🅱",
    C: "🅲",
    D: "🅳",
    E: "🅴",
    F: "🅵",
    G: "🅶",
    H: "🅷",
    I: "🅸",
    J: "🅹",
    K: "🅺",
    L: "🅻",
    M: "🅼",
    N: "🅽",
    O: "🅾",
    P: "🅿",
    Q: "🆀",
    R: "🆁",
    S: "🆂",
    T: "🆃",
    U: "🆄",
    V: "🆅",
    W: "🆆",
    X: "🆇",
    Y: "🆈",
    Z: "🆉"
  }
  , unicodeFont9 = {
    a: "ᴀ",
    b: "ʙ",
    c: "ᴄ",
    d: "ᴅ",
    e: "ᴇ",
    f: "ꜰ",
    g: "ɢ",
    h: "ʜ",
    i: "ɪ",
    j: "ᴊ",
    k: "ᴋ",
    l: "ʟ",
    m: "ᴍ",
    n: "ɴ",
    o: "ᴏ",
    p: "ᴘ",
    r: "ʀ",
    s: "ꜱ",
    t: "ᴛ",
    u: "ᴜ",
    v: "ᴠ",
    w: "ᴡ",
    y: "ʏ",
    z: "ᴢ"
  }
  , unicodeFont10 = {
    " ": " ",
    0: "０",
    1: "１",
    2: "２",
    3: "３",
    4: "４",
    5: "５",
    6: "６",
    7: "７",
    8: "８",
    9: "９",
    a: "ａ",
    b: "ｂ",
    c: "ｃ",
    d: "ｄ",
    e: "ｅ",
    f: "ｆ",
    g: "ｇ",
    h: "ｈ",
    i: "ｉ",
    j: "ｊ",
    k: "ｋ",
    l: "ｌ",
    m: "ｍ",
    n: "ｎ",
    o: "ｏ",
    p: "ｐ",
    q: "ｑ",
    r: "ｒ",
    s: "ｓ",
    t: "ｔ",
    u: "ｕ",
    v: "ｖ",
    w: "ｗ",
    x: "ｘ",
    y: "ｙ",
    z: "ｚ",
    A: "Ａ",
    B: "Ｂ",
    C: "Ｃ",
    D: "Ｄ",
    E: "Ｅ",
    F: "Ｆ",
    G: "Ｇ",
    H: "Ｈ",
    I: "Ｉ",
    J: "Ｊ",
    K: "Ｋ",
    L: "Ｌ",
    M: "Ｍ",
    N: "Ｎ",
    O: "Ｏ",
    P: "Ｐ",
    Q: "Ｑ",
    R: "Ｒ",
    S: "Ｓ",
    T: "Ｔ",
    U: "Ｕ",
    V: "Ｖ",
    W: "Ｗ",
    X: "Ｘ",
    Y: "Ｙ",
    Z: "Ｚ",
    "!": "！",
    '"': "゛",
    "#": "＃",
    $: "＄",
    "%": "％",
    "&": "＆",
    "(": "（",
    ")": "）",
    "*": "＊",
    "+": "＋",
    ",": "、",
    "-": "ー",
    ".": "。",
    "/": "／",
    ":": "：",
    ";": "；",
    "<": "〈",
    "=": "＝",
    ">": "〉",
    "?": "？",
    "@": "＠",
    "[": "［",
    "'": "'",
    "]": "］",
    "^": "＾",
    _: "＿",
    "`": "‘",
    "{": "｛",
    "|": "｜",
    "}": "｝",
    "~": "～"
  }
  , unicodeFont11 = {
    a: "𝚊",
    b: "𝚋",
    c: "𝚌",
    d: "𝚍",
    e: "𝚎",
    f: "𝚏",
    g: "𝚐",
    h: "𝚑",
    i: "𝚒",
    j: "𝚓",
    k: "𝚔",
    l: "𝚕",
    m: "𝚖",
    n: "𝚗",
    o: "𝚘",
    p: "𝚙",
    q: "𝚚",
    r: "𝚛",
    s: "𝚜",
    t: "𝚝",
    u: "𝚞",
    v: "𝚟",
    w: "𝚠",
    x: "𝚡",
    y: "𝚢",
    z: "𝚣",
    A: "𝙰",
    B: "𝙱",
    C: "𝙲",
    D: "𝙳",
    E: "𝙴",
    F: "𝙵",
    G: "𝙶",
    H: "𝙷",
    I: "𝙸",
    J: "𝙹",
    K: "𝙺",
    L: "𝙻",
    M: "𝙼",
    N: "𝙽",
    O: "𝙾",
    P: "𝙿",
    Q: "𝚀",
    R: "𝚁",
    S: "𝚂",
    T: "𝚃",
    U: "𝚄",
    V: "𝚅",
    W: "𝚆",
    X: "𝚇",
    Y: "𝚈",
    Z: "𝚉",
    1: "𝟷",
    2: "𝟸",
    3: "𝟹",
    4: "𝟺",
    5: "𝟻",
    6: "𝟼",
    7: "𝟽",
    8: "𝟾",
    9: "𝟿",
    0: "𝟶",
    "!": "！",
    "?": "？",
    ".": "．"
  }
  , unicodeFont12 = {
    a: "𝖺",
    b: "𝖻",
    c: "𝖼",
    d: "𝖽",
    e: "𝖾",
    f: "𝖿",
    g: "𝗀",
    h: "𝗁",
    i: "𝗂",
    j: "𝗃",
    k: "𝗄",
    l: "𝗅",
    m: "𝗆",
    n: "𝗇",
    o: "𝗈",
    p: "𝗉",
    q: "𝗊",
    r: "𝗋",
    s: "𝗌",
    t: "𝗍",
    u: "𝗎",
    v: "𝗏",
    w: "𝗐",
    x: "𝗑",
    y: "𝗒",
    z: "𝗓",
    A: "𝖠",
    B: "𝖡",
    C: "𝖢",
    D: "𝖣",
    E: "𝖤",
    F: "𝖥",
    G: "𝖦",
    H: "𝖧",
    I: "𝖨",
    J: "𝖩",
    K: "𝖪",
    L: "𝖫",
    M: "𝖬",
    N: "𝖭",
    O: "𝖮",
    P: "𝖯",
    Q: "𝖰",
    R: "𝖱",
    S: "𝖲",
    T: "𝖳",
    U: "𝖴",
    V: "𝖵",
    W: "𝖶",
    X: "𝖷",
    Y: "𝖸",
    Z: "𝖹",
    0: "𝟢",
    1: "𝟣",
    2: "𝟤",
    3: "𝟥",
    4: "𝟦",
    5: "𝟧",
    6: "𝟨",
    7: "𝟩",
    8: "𝟪",
    9: "𝟫"
  }
  , unicodeFont13 = {
    a: "𝘢",
    b: "𝘣",
    c: "𝘤",
    d: "𝘥",
    e: "𝘦",
    f: "𝘧",
    g: "𝘨",
    h: "𝘩",
    i: "𝘪",
    j: "𝘫",
    k: "𝘬",
    l: "𝘭",
    m: "𝘮",
    n: "𝘯",
    o: "𝘰",
    p: "𝘱",
    q: "𝘲",
    r: "𝘳",
    s: "𝘴",
    t: "𝘵",
    u: "𝘶",
    v: "𝘷",
    w: "𝘸",
    x: "𝘹",
    y: "𝘺",
    z: "𝘻",
    A: "𝘈",
    B: "𝘉",
    C: "𝘊",
    D: "𝘋",
    E: "𝘌",
    F: "𝘍",
    G: "𝘎",
    H: "𝘏",
    I: "𝘐",
    J: "𝘑",
    K: "𝘒",
    L: "𝘓",
    M: "𝘔",
    N: "𝘕",
    O: "𝘖",
    P: "𝘗",
    Q: "𝘘",
    R: "𝘙",
    S: "𝘚",
    T: "𝘛",
    U: "𝘜",
    V: "𝘝",
    W: "𝘞",
    X: "𝘟",
    Y: "𝘠",
    Z: "𝘡"
  }
  , unicodeFont14 = {
    a: "𝗮",
    b: "𝗯",
    c: "𝗰",
    d: "𝗱",
    e: "𝗲",
    f: "𝗳",
    g: "𝗴",
    h: "𝗵",
    i: "𝗶",
    j: "𝗷",
    k: "𝗸",
    l: "𝗹",
    m: "𝗺",
    n: "𝗻",
    o: "𝗼",
    p: "𝗽",
    q: "𝗾",
    r: "𝗿",
    s: "𝘀",
    t: "𝘁",
    u: "𝘂",
    v: "𝘃",
    w: "𝘄",
    x: "𝘅",
    y: "𝘆",
    z: "𝘇",
    A: "𝗔",
    B: "𝗕",
    C: "𝗖",
    D: "𝗗",
    E: "𝗘",
    F: "𝗙",
    G: "𝗚",
    H: "𝗛",
    I: "𝗜",
    J: "𝗝",
    K: "𝗞",
    L: "𝗟",
    M: "𝗠",
    N: "𝗡",
    O: "𝗢",
    P: "𝗣",
    Q: "𝗤",
    R: "𝗥",
    S: "𝗦",
    T: "𝗧",
    U: "𝗨",
    V: "𝗩",
    W: "𝗪",
    X: "𝗫",
    Y: "𝗬",
    Z: "𝗭",
    0: "𝟬",
    1: "𝟭",
    2: "𝟮",
    3: "𝟯",
    4: "𝟰",
    5: "𝟱",
    6: "𝟲",
    7: "𝟳",
    8: "𝟴",
    9: "𝟵"
  }
  , unicodeFont15 = {
    a: "𝙖",
    b: "𝙗",
    c: "𝙘",
    d: "𝙙",
    e: "𝙚",
    f: "𝙛",
    g: "𝙜",
    h: "𝙝",
    i: "𝙞",
    j: "𝙟",
    k: "𝙠",
    l: "𝙡",
    m: "𝙢",
    n: "𝙣",
    o: "𝙤",
    p: "𝙥",
    q: "𝙦",
    r: "𝙧",
    s: "𝙨",
    t: "𝙩",
    u: "𝙪",
    v: "𝙫",
    w: "𝙬",
    x: "𝙭",
    y: "𝙮",
    z: "𝙯",
    A: "𝘼",
    B: "𝘽",
    C: "𝘾",
    D: "𝘿",
    E: "𝙀",
    F: "𝙁",
    G: "𝙂",
    H: "𝙃",
    I: "𝙄",
    J: "𝙅",
    K: "𝙆",
    L: "𝙇",
    M: "𝙈",
    N: "𝙉",
    O: "𝙊",
    P: "𝙋",
    Q: "𝙌",
    R: "𝙍",
    S: "𝙎",
    T: "𝙏",
    U: "𝙐",
    V: "𝙑",
    W: "𝙒",
    X: "𝙓",
    Y: "𝙔",
    Z: "𝙕"
  };