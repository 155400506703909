import React from 'react';
import { Helmet } from 'react-helmet';

const LengthConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Length Converter | Case Modify</title>
      <meta name="description" content="Length Unit Conversion - converts meter, foot, inch, yard, kilometer, mile, nautical mile, light-year etc" />
      <meta name="Keywords" content="length converter uk, length unit converter, length measurement converter, length convert, converter length, unit converter length, length converter table, convert length, length converter download, length convertion, focal length converter, free length converter, metric length converter, metric converter length, length units converter, unit length converter, length converters, diameter to length converter, length converter chart, human length converter" />

      <meta name="apple-mobile-web-app-title" content="Length Converter" />
      <meta name="application-name" content="Length Converter" />
      <meta name="msapplication-tooltip" content="Length Converter" />

      <link rel="canonical" href="https://casemodify.com/length-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/length-converter/" />
      <meta property="og:title" content="Length Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Length Unit Conversion - converts meter, foot, inch, yard, kilometer, mile, nautical mile, light-year etc" />
    </Helmet>
  );
};

export default LengthConverterMetaTags;
