import styled from 'styled-components';

export const Anchor = styled.a`

  .social-logo {
    vertical-align: middle;
    height: 20px;
    width: 20px;
  };
`;
