import React from 'react';
import { Helmet } from 'react-helmet';

const ReverseTextMetaTags = () => {
  return (
    <Helmet>
      <title>Reverse Text Generator | Flip Text Backwards | Case Modify</title>
      <meta name="description" content="Want to Flip Your Text Backwards? Then Use Our Simple to Use Online Free and Quick Reverse Text Generator Here." />

      <meta name="apple-mobile-web-app-title" content="Reverse Text Generator" />
      <meta name="application-name" content="Reverse Text Generator" />
      <meta name="msapplication-tooltip" content="Reverse Text Generator" />

      <link rel="canonical" href="https://casemodify.com/reverse-text-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/reverse-text-generator/" />
      <meta property="og:title" content="Reverse Text Generator - Flip Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Want to Flip Your Text Backwards? Then Use Our Simple to Use Online Free and Quick Reverse Text Generator Here." />
    </Helmet>
  );
};

export default ReverseTextMetaTags;
