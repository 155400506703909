import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto 15px;
  width: 100%;
  max-width: 1200px;
  background: #e0e0e0;
  border-top: 3px solid #c2c2c2;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  .title {
    margin-bottom: 60px;
  }

  h1,
  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  h1,
  h2,
  p,
  .row,
  .feature,
  .col,
  .message {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .input {
    color: #000000;
    width: 100%;
    height: 10px;
    min-height: 5.4vh;
    border: 0;
    display: block;
    overflow: auto;
    resize: vertical;
    padding: 10px;
  }

  .output {
    color: #000000;
    width: 100%;
    height: 10px;
    min-height: 5.4vh;
    border: 0;
    display: block;
    overflow: auto;
    resize: vertical;
    padding: 10px;
  }

  select{
    overflow:auto;
  }

  .select-unit {
    border-top: none;
    color: #000000;
    background: #fff;
    width: 100%;
    height: 320px;
    min-height: 22vh;
    border: 0;
    border-top: 3px solid #c2c2c2;
    display: block;
    padding: 10px;
  }

  .target {
    font-weight: bold;
  }

  .actions {
    margin-bottom: 5px;
    min-height: 64px;
  }

  .btns {
    margin-top: 10px;
    margin-bottom: -5px;
    display: inline-flex;
    font-size: 13px;
  }

  .media {
    margin-bottom: 5px;
    margin-top: 200px;
    text-align: center;
  }

  .btn,
  a,
  .tabs span {
    background: #c2c2c2;
    color: #111;
    border: 0;
    padding: 5px 8px;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 5px 8px;
    margin-right: 2px;
    &:hover {
      background-color: #777;
    }
  }

  .btn-coffee,
  .tabs a,
  .tabs span {
    background: #c2c2c2;
    color: #111;
    border: 0;
    padding: 5px 8px;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 5px 8px;
  }
  .row-counts {
    width: 100%;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }

  .cols {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .col {
      width: 50%;
      padding-right: 10px;
      flex-grow: 1;
    }

    .col:first-child {
      padding-left: 0;
    }

    .col:last-child {
      padding-right: 0;
    }
    .col.right {
      text-align: right;
    }
  }

  @media (max-width: 700px) {
    .cols .col {
      width: 100%;
      padding: 0;
    }

    .cols .col.right {
      text-align: center;
    }
  }

  .copy {
    width: 12px;
    fill: white;
    cursor: pointer;
  }

  .success-message {
    background: #eee;
    border-top: 3px solid #208000;
    padding: 5px 10px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CoffeeCup = styled.img`
  height: 12px;
  width: 12px;
`;
