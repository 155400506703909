import React from 'react';
import { Helmet } from 'react-helmet';

const HomeMetaTags = () => {
  return (
    <Helmet>
      <title>Case Modify | Convert text into different letter cases!</title>
      <meta name="description" content="Convert you text between different letter cases lower case, UPPER CASE, Sentence case, Capitalized Case, aLtErNaTiNg cAsE." />
      
      <meta name="apple-mobile-web-app-title" content="Case Modify" />
      <meta name="application-name" content="Case Modify" />
      <meta name="msapplication-TileImage" content="/apple-touch-icon.png" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-tooltip" content="Accidentally left the caps lock on and typed something, but I can't be bothered starting over and retyping everything." />
      
      <link rel="canonical" href="https://casemodify.com/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/" />
      <meta property="og:title" content="Case Modify - Convert text into different letter cases!" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Convert you text between different letter cases lower case, UPPER CASE, Sentence case, Capitalized Case, aLtErNaTiNg cAsE." />
    </Helmet>
  );
};

export default HomeMetaTags;
