import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './FacebookFontGenerator.style';
import { convertToFontText } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import FacebookFontMetaTags from '../../../components/MetaTag/FacebookFontMetaTags';
import {
  facebookFont1, facebookFont2, facebookFont3, facebookFont4,
  facebookFont5, facebookFont6, facebookFont7, facebookFont8,
  facebookFont9, facebookFont10, facebookFont11, facebookFont12,
  facebookFont13, facebookFont14, facebookFont15, facebookFont16,
  facebookFont17, facebookFont18, facebookFont19 } from '../../../common/constants';

const FacebookFontGenerator = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText1, setTransformedText1] = useState('');
  const [transformedText2, setTransformedText2] = useState('');
  const [transformedText3, setTransformedText3] = useState('');
  const [transformedText4, setTransformedText4] = useState('');
  const [transformedText5, setTransformedText5] = useState('');
  const [transformedText6, setTransformedText6] = useState('');
  const [transformedText7, setTransformedText7] = useState('');
  const [transformedText8, setTransformedText8] = useState('');
  const [transformedText9, setTransformedText9] = useState('');
  const [transformedText10, setTransformedText10] = useState('');
  const [transformedText11, setTransformedText11] = useState('');
  const [transformedText12, setTransformedText12] = useState('');
  const [transformedText13, setTransformedText13] = useState('');
  const [transformedText14, setTransformedText14] = useState('');
  const [transformedText15, setTransformedText15] = useState('');
  const [transformedText16, setTransformedText16] = useState('');
  const [transformedText17, setTransformedText17] = useState('');
  const [transformedText18, setTransformedText18] = useState('');
  const [transformedText19, setTransformedText19] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    setTransformedText1(convertToFontText(inputText, facebookFont1));
    setTransformedText2(convertToFontText(inputText, facebookFont2));
    setTransformedText3(convertToFontText(inputText, facebookFont3));
    setTransformedText4(convertToFontText(inputText, facebookFont4));
    setTransformedText5(convertToFontText(inputText, facebookFont5));
    setTransformedText6(convertToFontText(inputText, facebookFont6));
    setTransformedText7(convertToFontText(inputText, facebookFont7));
    setTransformedText8(convertToFontText(inputText, facebookFont8));
    setTransformedText9(convertToFontText(inputText, facebookFont9));
    setTransformedText10(convertToFontText(inputText, facebookFont10));
    setTransformedText11(convertToFontText(inputText, facebookFont11));
    setTransformedText12(convertToFontText(inputText, facebookFont12));
    setTransformedText13(convertToFontText(inputText, facebookFont13));
    setTransformedText14(convertToFontText(inputText, facebookFont14));
    setTransformedText15(convertToFontText(inputText, facebookFont15));
    setTransformedText16(convertToFontText(inputText, facebookFont16));
    setTransformedText17(convertToFontText(inputText, facebookFont17));
    setTransformedText18(convertToFontText(inputText, facebookFont18));
    setTransformedText19(convertToFontText(inputText, facebookFont19));
  };

  const handleDownload = () => {
    downloadText(combineDownloadableText());
  };

  const handleCopyTextToClipboard = (text) => {
    text && copyToClipboard(text);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(combineDownloadableText());

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const combineDownloadableText = () => {
    return transformedText1
      + "\n"
      + transformedText2
      + "\n"
      + transformedText3
      + "\n"
      + transformedText4
      + "\n"
      + transformedText5
      + "\n"
      + transformedText6
      + "\n"
      + transformedText7
      + "\n"
      + transformedText8
      + "\n"
      + transformedText9
      + "\n"
      + transformedText10
      + "\n"
      + transformedText11
      + "\n"
      + transformedText12
      + "\n"
      + transformedText13
      + "\n"
      + transformedText14
      + "\n"
      + transformedText15
      + "\n"
      + transformedText16
      + "\n"
      + transformedText17
      + "\n"
      + transformedText18
      + "\n"
      + transformedText19;
  }

  return (
    <Container>
      <FacebookFontMetaTags />
      <div className="title">
        <h1>Facebook Font Generator</h1>
        <p>
          <label>  
            Generate distinct fonts effortlessly for your Facebook profile or page using our user-friendly font generator.
          </label>
          </p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <p>
                {transformedText1} {' '}
                {transformedText1.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText1)}/>}
              </p>
              <p>
                {transformedText2} {' '}
                { transformedText2.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText2)}/>}
              </p>
              <p>
                {transformedText3} {' '}
                { transformedText3.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText3)}/>}
              </p>
              <p>
                {transformedText4} {' '}
                { transformedText4.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText4)}/>}
              </p>
              <p>
                {transformedText5} {' '}
                { transformedText5.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText5)}/>}
              </p>
              <p>
                {transformedText6} {' '}
                { transformedText6.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText6)}/>}
              </p>
              <p>
                {transformedText7} {' '}
                { transformedText7.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText7)}/>}
              </p>
              <p>
                {transformedText8} {' '}
                { transformedText8.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText8)}/>}
              </p>
              <p>
                {transformedText9} {' '}
                { transformedText9.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText9)}/>}
              </p>
              <p>
                {transformedText10} {' '}
                { transformedText10.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText10)}/>}
              </p>
              <p>
                {transformedText11} {' '}
                { transformedText11.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText11)}/>}
              </p>
              <p>
                {transformedText12} {' '}
                { transformedText12.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText12)}/>}
              </p>
              <p>
                {transformedText13} {' '}
                { transformedText13.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText13)}/>}
              </p>
              <p>
                {transformedText14} {' '}
                { transformedText14.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText14)}/>}
              </p>
              <p>
                {transformedText15} {' '}
                { transformedText15.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText15)}/>}
              </p>
              <p>
                {transformedText16} {' '}
                { transformedText16.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText16)}/>}
              </p>
              <p>
                {transformedText17} {' '}
                { transformedText17.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText17)}/>}
              </p>
              <p>
                {transformedText18} {' '}
                { transformedText18.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText18)}/>}
              </p>
              <p>
                {transformedText19} {' '}
                { transformedText19.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText19)}/>}
              </p>
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
      <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default FacebookFontGenerator;
