
export const downloadText = (text, filename) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Error copying text to clipboard:', err);
  }
};

export const convertToBold = (text) => {

  const boldTextFont = {
    " ": " ", A: "𝗔", B: "𝗕", C: "𝗖", D: "𝗗", E: "𝗘", F: "𝗙", G: "𝗚", H: "𝗛", I: "𝗜", J: "𝗝",
    K: "𝗞", L: "𝗟", M: "𝗠", N: "𝗡", O: "𝗢", P: "𝗣", Q: "𝗤", R: "𝗥", S: "𝗦", T: "𝗧", U: "𝗨",
    V: "𝗩", W: "𝗪", X: "𝗫", Y: "𝗬", Z: "𝗭",
    a: "𝗮", b: "𝗯", c: "𝗰", d: "𝗱", e: "𝗲", f: "𝗳", g: "𝗴", h: "𝗵", i: "𝗶", j: "𝗷",
    k: "𝗸", l: "𝗹", m: "𝗺", n: "𝗻", o: "𝗼", p: "𝗽", q: "𝗾", r: "𝗿", s: "𝘀", t: "𝘁",
    u: "𝘂", v: "𝘃", w: "𝘄", x: "𝘅", y: "𝘆", z: "𝘇",
    0: "𝟬", 1: "𝟭", 2: "𝟮", 3: "𝟯", 4: "𝟰", 5: "𝟱", 6: "𝟲", 7: "𝟳", 8: "𝟴", 9: "𝟵"
  };

  return text.split('').map(char => boldTextFont[char] || char).join('');
};

export const convertToTitleCase = (text) => {
  return text.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}
export const convertToSentenceCase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const convertToLowercase = (text) => {
  return text.toLowerCase()
};

export const convertToUppercase = (text) => {
  return text.toUpperCase();
};

export const convertToCapitalizedCase = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const convertToAlternatingCase = (text) => {
  return text.split('')
    .map((char, index) =>
      index % 2 === 0 ? char.toLowerCase() : char.toUpperCase()
    )
    .join('');
};

export const convertToInverseCase = (text) => {
  return text.split('')
    .map((char) =>
      char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
    )
    .join('')

};

export const convertToZalgo = (text) => {
  const zalgoChars = [
    '\u030d', '\u030e', '\u0304', '\u0305', '\u033f', '\u0311', '\u0306',
    '\u0310', '\u0352', '\u0357', '\u0351', '\u0307', '\u0308', '\u030a',
    '\u0342', '\u0343', '\u0344', '\u034a', '\u034b', '\u034c', '\u0303',
    '\u0302', '\u030c', '\u0350', '\u0301', '\u030b', '\u030f', '\u0312',
    '\u0313', '\u0314', '\u033d', '\u0309', '\u0363', '\u0364', '\u0365',
    '\u0366', '\u0367', '\u0368', '\u0369', '\u036a', '\u036b', '\u036c',
    '\u036d', '\u036e', '\u036f', '\u033e', '\u035b'
  ];

  let zalgoText = '';
  for (let i = 0; i < text.length; i++) {
    zalgoText += text[i];
    if (text[i] !== ' ') {
      const numZalgoChars = Math.floor(Math.random() * 3) + 1; // Randomly choose 1 to 3 zalgo characters
      for (let j = 0; j < numZalgoChars; j++) {
        zalgoText += zalgoChars[Math.floor(Math.random() * zalgoChars.length)];
      }
    }
  }
  return zalgoText;
};

export const convertToItalicCase = (text) => {
  const italicTextFont = {
    " ": " ", A: "𝘈", B: "𝘉", C: "𝘊", D: "𝘋", E: "𝘌", F: "𝘍", G: "𝘎", H: "𝘏", I: "𝘐", J: "𝘑",
    K: "𝘒", L: "𝘓", M: "𝘔", N: "𝘕", O: "𝘖", P: "𝘗", Q: "𝘘", R: "𝘙", S: "𝘚", T: "𝘛", U: "𝘜",
    V: "𝘝", W: "𝘞", X: "𝘟", Y: "𝘠", Z: "𝘡",
    a: "𝘢", b: "𝘣", c: "𝘤", d: "𝘥", e: "𝘦", f: "𝘧", g: "𝘨", h: "𝘩", i: "𝘪", j: "𝘫",
    k: "𝘬", l: "𝘭", m: "𝘮", n: "𝘯", o: "𝘰", p: "𝘱", q: "𝘲", r: "𝘳", s: "𝘴", t: "𝘵", u: "𝘶",
    v: "𝘷", w: "𝘸", x: "𝘹", y: "𝘺", z: "𝘻"
  };

  const italicArray = Array.from(text).map(char => italicTextFont[char] || char);
  return italicArray.join('');
}

export const whitespaceRemover = (text) => {
  const trimmedText = text.replace(/\s+/g, '');

  return trimmedText.trim();
}

export const superScriptGenerator = (text) => {
  const superscriptFont = {
    " ": " ", 0: "⁰", 1: "¹", 2: "²", 3: "³", 4: "⁴", 5: "⁵", 6: "⁶", 7: "⁷", 8: "⁸", 9: "⁹",
    "+": "⁺", "-": "⁻", "=": "⁼", "(": "⁽", ")": "⁾",
    a: "ᵃ", b: "ᵇ", c: "ᶜ", d: "ᵈ", e: "ᵉ", f: "ᶠ", g: "ᵍ", h: "ʰ", i: "ⁱ", j: "ʲ",
    k: "ᵏ", l: "ˡ", m: "ᵐ", n: "ⁿ", o: "ᵒ", p: "ᵖ", q: "ᵠ", r: "ʳ", s: "ˢ", t: "ᵗ",
    u: "ᵘ", v: "ᵛ", w: "ʷ", x: "ˣ", y: "ʸ", z: "ᶻ"
  };

  return text.toLowerCase().split('').map(char => superscriptFont[char] || char).join('');
}

export const smallCapsTextGenerator = (text) => {
  const smallCapsTextFont = {
    " ": " ", a: "ᴀ", b: "ʙ", c: "ᴄ", d: "ᴅ", e: "ᴇ", f: "ꜰ", g: "ɢ", h: "ʜ", i: "ɪ", j: "ᴊ",
    k: "ᴋ", l: "ʟ", m: "ᴍ", n: "ɴ", o: "ᴏ", p: "ᴘ", r: "ʀ", s: "ꜱ", t: "ᴛ", u: "ᴜ",
    v: "ᴠ", w: "ᴡ", y: "ʏ", z: "ᴢ"
  };

  return text.toLowerCase().split('').map(char => smallCapsTextFont[char] || char).join('');
}

export const FilledBubbleTextConverter = (text) => {
  const FilledBubbleTextFont = {
    " ": " ", a: "🅐", b: "🅑", c: "🅒", d: "🅓", e: "🅔", f: "🅕", g: "🅖", h: "🅗", i: "🅘", j: "🅙",
    k: "🅚", l: "🅛", m: "🅜", n: "🅝", o: "🅞", p: "🅟", q: "🅠", r: "🅡", s: "🅢", t: "🅣", u: "🅤",
    v: "🅥", w: "🅦", x: "🅧", y: "🅨", z: "🅩", 1: "➊", 2: "➋", 3: "➌", 4: "➍", 5: "➎",
    6: "➏", 7: "➐", 8: "➑", 9: "➒", 0: "⓿"
  };

  return text.toLowerCase().split('').map(char => FilledBubbleTextFont[char] || char).join('');
}

export const EmptyBubbleTextConverter = (text) => {
  const EmptyBubbleTextFont = {
    " ": " ", a: "ⓐ", b: "ⓑ", c: "ⓒ", d: "ⓓ", e: "ⓔ", f: "ⓕ", g: "ⓖ", h: "ⓗ", i: "ⓘ", j: "ⓙ",
    k: "ⓚ", l: "ⓛ", m: "ⓜ", n: "ⓝ", o: "ⓞ", p: "ⓟ", q: "ⓠ", r: "ⓡ", s: "ⓢ", t: "ⓣ", u: "ⓤ",
    v: "ⓥ", w: "ⓦ", x: "ⓧ", y: "ⓨ", z: "ⓩ", A: "Ⓐ", B: "Ⓑ", C: "Ⓒ", D: "Ⓓ", E: "Ⓔ", F: "Ⓕ",
    G: "Ⓖ", H: "Ⓗ", I: "Ⓘ", J: "Ⓙ", K: "Ⓚ", L: "Ⓛ", M: "Ⓜ", N: "Ⓝ", O: "Ⓞ", P: "Ⓟ", Q: "Ⓠ",
    R: "Ⓡ", S: "Ⓢ", T: "Ⓣ", U: "Ⓤ", V: "Ⓥ", W: "Ⓦ", X: "Ⓧ", Y: "Ⓨ", Z: "Ⓩ", 1: "①", 2: "②",
    3: "③", 4: "④", 5: "⑤", 6: "⑥", 7: "⑦", 8: "⑧", 9: "⑨", 0: "⓪"
  };

  return text.split('').map(char => EmptyBubbleTextFont[char] || char).join('');
}

export const wideTextGenerator = (text) => {
  const wideTextFont = {
    " ": " ", 0: "０", 1: "１", 2: "２", 3: "３", 4: "４", 5: "５", 6: "６", 7: "７", 8: "８", 9: "９",
    a: "ａ", b: "ｂ", c: "ｃ", d: "ｄ", e: "ｅ", f: "ｆ", g: "ｇ", h: "ｈ", i: "ｉ", j: "ｊ",
    k: "ｋ", l: "ｌ", m: "ｍ", n: "ｎ", o: "ｏ", p: "ｐ", q: "ｑ", r: "ｒ", s: "ｓ", t: "ｔ",
    u: "ｕ", v: "ｖ", w: "ｗ", x: "ｘ", y: "ｙ", z: "ｚ",
    A: "Ａ", B: "Ｂ", C: "Ｃ", D: "Ｄ", E: "Ｅ", F: "Ｆ", G: "Ｇ", H: "Ｈ", I: "Ｉ", J: "Ｊ",
    K: "Ｋ", L: "Ｌ", M: "Ｍ", N: "Ｎ", O: "Ｏ", P: "Ｐ", Q: "Ｑ", R: "Ｒ", S: "Ｓ", T: "Ｔ",
    U: "Ｕ", V: "Ｖ", W: "Ｗ", X: "Ｘ", Y: "Ｙ", Z: "Ｚ",
    "!": "！", '"': "゛", "#": "＃", $: "＄", "%": "％", "&": "＆", "(": "（", ")": "）",
    "*": "＊", "+": "＋", ",": "、", "-": "ー", ".": "。", "/": "／", ":": "：", ";": "；",
    "<": "〈", "=": "＝", ">": "〉", "?": "？", "@": "＠", "[": "［", "'": "'", "]": "］",
    "^": "＾", _: "＿", "`": "‘", "{": "｛", "|": "｜", "}": "｝", "~": "～"
  };

  return text.split('').map(char => wideTextFont[char] || char).join('');
}

export const wingdingsConverter = (text) => {
  const wingdingsTextFont = {
    a: "♋︎", b: "♌︎", c: "♍︎", d: "♎︎", e: "♏︎", f: "♐︎", g: "♑︎", h: "♒︎", i: "♓︎",
    j: "🙰", k: "🙵", l: "●︎", m: "❍︎", n: "■︎", o: "□︎", p: "◻︎", q: "❑︎", r: "❒︎", s: "⬧︎",
    t: "⧫︎", u: "◆︎", v: "❖︎", w: "⬥︎", x: "⌧︎", y: "⍓︎", z: "⌘︎",
    A: "✌︎", B: "👌︎", C: "👍︎", D: "👎︎", E: "☜︎", F: "☞︎", G: "☝︎", H: "☟︎", I: "✋︎",
    J: "☺︎", K: "😐︎", L: "☹︎", M: "💣︎", N: "☠︎", O: "⚐︎", P: "🏱︎", Q: "✈︎", R: "☼︎",
    S: "💧︎", T: "❄︎", U: "🕆︎", V: "✞︎", W: "🕈︎", X: "✠︎", Y: "✡︎", Z: "☪︎",
    0: "📁︎", 1: "📂︎", 2: "📄︎", 3: "🗏︎", 4: "🗐︎", 5: "🗄︎", 6: "⌛︎", 7: "🖮︎", 8: "🖰︎",
    9: "🖲︎", ".": "📬︎", ",": "📪︎", "!": "✏︎", "?": "✍︎", "-": "📫︎", "@": "@", "#": "✁︎",
    $: "👓︎", "%": "🕭︎", "^": "♈︎", "&": "🕮︎", "*": "🖂︎", "(": "🕿︎", ")": "✆︎", _: "♉︎",
    "+": "🖃︎", "=": "🖬︎", "{": "❀︎", "}": "❝︎", "[": "☯︎", "]": "☸︎", "|": "✿︎", ";": "🖴︎",
    "'": "ॐ︎", '"': "✂︎", "<": "🖫︎", ">": "✇︎", "/": "📭︎", "`": "♊︎", "~": "❞︎", " ": " "
  };

  return text.split('').map(char => wingdingsTextFont[char] || char).join('');
}

export const upsideDownTextConverter = (text) => {
  const upsideDownTextFont = {
    " ": " ", a: "ɐ", b: "q", c: "ɔ", d: "p", e: "ǝ", f: "ɟ", g: "ƃ", h: "ɥ", i: "ᴉ", j: "ɾ",
    k: "ʞ", l: "l", m: "ɯ", n: "u", o: "o", p: "d", q: "b", r: "ɹ", s: "s", t: "ʇ", u: "n",
    v: "ʌ", w: "ʍ", x: "x", y: "ʎ", z: "z",
    A: "∀", B: "B", C: "Ɔ", D: "ᗡ", E: "Ǝ", F: "Ⅎ", G: "פ", H: "H", I: "I", J: "ſ",
    K: "K", L: "˥", M: "W", N: "N", O: "O", P: "Ԁ", Q: "Q", R: "R", S: "S", T: "┴",
    U: "∩", V: "Λ", W: "M", X: "X", Y: "⅄", Z: "Z",
    0: "0", 1: "Ɩ", 2: "ᄅ", 3: "Ɛ", 4: "ㄣ", 5: "ϛ", 6: "9", 7: "ㄥ", 8: "8", 9: "6",
    ",": "'", ".": "˙", "?": "¿", "!": "¡", '"': ",,", "'": ",", "`": ",",
    "(": ")", ")": "(", "[": "]", "]": "[", "{": "}", "}": "{", "<": ">", ">": "<",
    "&": "⅋", "_": "‾"
  };

  return text.split('').map(char => upsideDownTextFont[char] || char).join('');
}

export const strikethroughTextGenerator = (text) => {
  const strikethroughTextFont = {
    " ": " ", a: "a̶", b: "b̶", c: "c̶", d: "d̶", e: "e̶", f: "f̶", g: "g̶", h: "h̶", i: "i̶", j: "j̶",
    k: "k̶", l: "l̶", m: "m̶", n: "n̶", o: "o̶", p: "p̶", q: "q̶", r: "r̶", s: "s̶", t: "t̶",
    u: "u̶", v: "v̶", w: "w̶", x: "x̶", y: "y̶", z: "z̶",
    A: "A̶", B: "B̶", C: "C̶", D: "D̶", E: "E̶", F: "F̶", G: "G̶", H: "H̶", I: "I̶", J: "J̶",
    K: "K̶", L: "L̶", M: "M̶", N: "N̶", O: "O̶", P: "P̶", Q: "Q̶", R: "R̶", S: "S̶", T: "T̶",
    U: "U̶", V: "V̶", W: "W̶", X: "X̶", Y: "Y̶", Z: "Z̶",
  };

  return text.split('').map(char => strikethroughTextFont[char] || char).join('');
}

export const convertToMirroredText = (text) => {
  const mirrorTextFont = {
    " ": " ", a: "ɒ", b: "d", c: "ɔ", d: "b", e: "ɘ", f: "ʇ", g: "ǫ", h: "ʜ", i: "i", j: "Ⴑ", k: "ʞ",
    l: "l", m: "m", n: "n", o: "o", p: "q", q: "p", r: "ɿ", s: "ƨ", t: "ƚ", u: "u", v: "v", w: "w",
    x: "x", y: "y", z: "z", A: "A", B: "ᙠ", C: "Ɔ", D: "ᗡ", E: "Ǝ", F: "ᖷ", G: "Ꭾ", H: "H", I: "I",
    J: "Ⴑ", K: "ᐴ", L: "⅃", M: "M", N: "И", O: "O", P: "ꟼ", Q: "Ọ", R: "Я", S: "Ƨ", T: "T", U: "U",
    V: "V", W: "W", X: "X", Y: "Y", Z: "Ƹ", 0: "0", 1: "1", 2: "2", 3: "Ƹ", 4: "4", 5: "5", 6: "6",
    7: "7", "?": "␚", ";": "⁏"
  };

  return text.split('').map(char => mirrorTextFont[char] || char).reverse().join('');
}

export const reverseText = (text) => {
  return text.split('').reverse().join('');
}

export const removeDuplicateLines = (text) => {
  const lines = text.split('\n');
  return Array.from(new Set(lines)).join('<br>');
}

export const underlineText = (text) => {
  return text.split('').map(char => char + '̲').join('');
}

export const convertToFontText = (text, fontMap) => {
  return text.split('').map((char) => (fontMap[char] ? fontMap[char] : char)).join('');
}

export const escapeJSONString = (str) => {
  return str
    .replace(/\\/g, '\\\\')
    .replace(/"/g, '\\"')
    .replace(/\b/g, '\\b')
    .replace(/\f/g, '\\f')
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t');
}

export const unescapeJSONString = (str) => {
  return str
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f')
    .replace(/\\n/g, '\n')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\"/g, '"')
  .replace(/\\\\/g, '\\');
}
