import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './TimeConverter.style';
import TimeConverterMetaTags from '../../../components/MetaTag/TimeConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const TimeConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    centuries: { toSecond: value => value * 3155760000, fromSecond: value => value / 3155760000 },
    'days [d]': { toSecond: value => value * 86400, fromSecond: value => value / 86400 },
    decades: { toSecond: value => value * 315576000, fromSecond: value => value / 315576000 },
    'femtoseconds [fs]': { toSecond: value => value * 1e-15, fromSecond: value => value / 1e-15 },
    fortnights: { toSecond: value => value * 1209600, fromSecond: value => value / 1209600 },
    'hours [h]': { toSecond: value => value * 3600, fromSecond: value => value / 3600 },
    'microseconds [μs]': { toSecond: value => value * 1e-6, fromSecond: value => value / 1e-6 },
    millenia: { toSecond: value => value * 31557600000, fromSecond: value => value / 31557600000 },
    'milliseconds [ms]': { toSecond: value => value * 0.001, fromSecond: value => value / 0.001 },
    'minutes [min]': { toSecond: value => value * 60, fromSecond: value => value / 60 },
    'months (Common)': { toSecond: value => value * 2629800, fromSecond: value => value / 2629800 },
    'months (Synodic)': { toSecond: value => value * 2551442.88, fromSecond: value => value / 2551442.88 },
    'nanoseconds [ns]': { toSecond: value => value * 1e-9, fromSecond: value => value / 1e-9 },
    'picoseconds [ps]': { toSecond: value => value * 1e-12, fromSecond: value => value / 1e-12 },
    'quarters (Common)': { toSecond: value => value * 7889400, fromSecond: value => value / 7889400 },
    'seconds [s]': { toSecond: value => value, fromSecond: value => value },
    shakes: { toSecond: value => value * 1e-8, fromSecond: value => value / 1e-8 },
    weeks: { toSecond: value => value * 604800, fromSecond: value => value / 604800 },
    'years (Common) [y]': { toSecond: value => value * 31557600, fromSecond: value => value / 31557600 },
    'years (Average Gregorian)': { toSecond: value => value * 31556952, fromSecond: value => value / 31556952 },
    'years (Julian)': { toSecond: value => value * 31557600, fromSecond: value => value / 31557600 },
    'years (Leap)': { toSecond: value => value * 31622400, fromSecond: value => value / 31622400 },
    'years (Tropical)': { toSecond: value => value * 31556925.216, fromSecond: value => value / 31556925.216 },
  };

  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertTime(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertTime(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertTime(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const convertTime = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const secondValue = units[fromUnit].toSecond(value);
    return units[toUnit].fromSecond(secondValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'TimeConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <TimeConverterMetaTags />
      <div className='title'>
        <h1>Time Converter</h1>
        <p>
          <label htmlFor='content'>
            Time Unit Conversion - converts second, minute, hour, day, millisecond, tropical year...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default TimeConverter;
