import React from 'react';
import { Helmet } from 'react-helmet';

const FrequencyConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Time Converter | Case Modify</title>
      <meta name="description" content="Time Unit Conversion - converts second, minute, hour, day, millisecond, tropical year..." />
      <meta name="Keywords" content="converts second, minute, hour, day, millisecond, tropical year" />

      <meta name="apple-mobile-web-app-title" content="Time Converter" />
      <meta name="application-name" content="Time Converter" />
      <meta name="msapplication-tooltip" content="Time Converter" />

      <link rel="canonical" href="https://casemodify.com/time-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/time-converter/" />
      <meta property="og:title" content="Time Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Time Unit Conversion - converts second, minute, hour, day, millisecond, tropical year..." />
    </Helmet>
  );
};

export default FrequencyConverterMetaTags;
