import React from 'react';
import { Helmet } from 'react-helmet';

const JsonEscapeTextMetaTags = () => {
  return (
    <Helmet>
      <title>JSON Escape - Unescape | Case Modify</title>
      <meta name="description" content="Removes characters from a JSON string that can cause parsing issues by either escaping or unescapes it" />

      <meta name="apple-mobile-web-app-title" content="JSON Escape - Unescape" />
      <meta name="application-name" content="JSON Escape - Unescape" />
      <meta name="msapplication-tooltip" content="JSON Escape - Unescape" />

      <link rel="canonical" href="https://casemodify.com/json-escape/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/json-escape/" />
      <meta property="og:title" content="JSON Escape - Unescape | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Removes characters from a JSON string that can cause parsing issues by either escaping or unescapes it" />
    </Helmet>
  );
};

export default JsonEscapeTextMetaTags;
