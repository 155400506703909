import React from 'react';
import {
  FooterContainer, CompanyInfo, Widget,
  InfoWidget, CategoriesWidget
} from './Footer.style';

const Footer = () => {
  return (
    <FooterContainer>
      <CompanyInfo>
        <img className='logo' src='/icons/logo.svg' alt='logo' />
        <p>
          Free online tool for effortless text case conversion - uppercase,
          lowercase, title, sentence, camel case, providing versatile solutions.
        </p>
      </CompanyInfo>

      <Widget>
        <InfoWidget>
          <h3>INFO</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
          </ul>
        </InfoWidget>
        <CategoriesWidget>
          <h3>POPULAR CASES</h3>
          <div className='grid-container'>
            <ul className='grid-item'>
              <li>
                <a href="/bold-text-converter">Bold Text Generator</a>
              </li>
              <li>
                <a href="/italic-text-converter">Italic Text Converter</a>
              </li>
              <li>
                <a href="/sentence-case">Sentence Case Converter</a>
              </li>
              <li>
                <a href="/title-case-converter">Title Case Converter</a>
              </li>
              <li>
                <a href="/facebook-font-generator">Facebook Font Generator</a>
              </li>
            </ul>
            <ul  className='grid-item'>
              <li>
                <a href="/length-converter">Length Converter</a>
              </li>
              <li>
                <a href="/weight-converter">Weight Converter</a>
              </li>
              <li>
                <a href="/speed-converter">Speed Converter</a>
              </li>
              <li>
                <a href="/mass-converter">Mass Converter</a>
              </li>
              <li>
                <a href="/time-converter">Time Converter</a>
              </li>
            </ul>
          </div>
        </CategoriesWidget>
      </Widget>
    </FooterContainer>
  );
};

export default Footer;