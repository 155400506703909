import React from 'react';
import { Helmet } from 'react-helmet';

const UpsideDownTextMetaTags = () => {
  return (
    <Helmet>
      <title>Upside Down Text Generator | Type Flip Text Upside Down | Convert Case | Case Modify</title>
      <meta name="description" content="Do You Want to Flip Your Text Upside Down Quickly? Then Use Our Free Fast Online Upside Down Text Generator Here." />

      <meta name="apple-mobile-web-app-title" content="Upside Down Text Generator" />
      <meta name="application-name" content="Upside Down Text Generator" />
      <meta name="msapplication-tooltip" content="Upside Down Text Generator" />

      <link rel="canonical" href="https://casemodify.com/upside-down-text-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/upside-down-text-generator/" />
      <meta property="og:title" content="Upside Down Text Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Do You Want to Flip Your Text Upside Down Quickly? Then Use Our Free Fast Online Upside Down Text Generator Here." />
    </Helmet>
  );
};

export default UpsideDownTextMetaTags;
