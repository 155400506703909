import React from 'react';
import { Anchor } from './Media.style';

const PayPalDonate = () => {
  const paypalDonateUrl = 'https://paypal.me/casemodify/10usd';
  return (
    <Anchor href={paypalDonateUrl} className="btn" target="_blank" rel="noopener noreferrer">
      Donate via PayPal <img className='social-logo' src="/icons/paypal.png" alt='paypal'/>
    </Anchor>
  );
};

export default PayPalDonate;