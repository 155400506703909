import React from 'react';
import { Helmet } from 'react-helmet';

const FuelConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Fuel Converter | Case Modify</title>
      <meta name="description" content="Fuel Unit Conversion - converts gallons ,miles, liters, kilometer..." />
      <meta name="Keywords" content="converts gallons ,miles, liters, kilometer..." />

      <meta name="apple-mobile-web-app-title" content="Fuel Converter" />
      <meta name="application-name" content="Fuel Converter" />
      <meta name="msapplication-tooltip" content="Fuel Converter" />

      <link rel="canonical" href="https://casemodify.com/fuel-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/fuel-converter/" />
      <meta property="og:title" content="Fuel Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Fuel Unit Conversion - Fuel Unit Conversion - converts gallons ,miles, liters, kilometer..." />
    </Helmet>
  );
};

export default FuelConverterMetaTags;
