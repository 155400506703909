import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './FuelConverter.style';
import FuelConverterMetaTags from '../../../components/MetaTag/FuelConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const FuelConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const [fromValue, setFromValue] = useState('');
  const [fromUnit, setFromUnit] = useState('gallons (UK)/100 miles');
  const [toUnit, setToUnit] = useState('gallons (US)/100 miles');
  const [toValue, setToValue] = useState('');

  const units = {
    'gallons (UK)/100 miles': { toStandard: value => value, fromStandard: value => value },
    'gallons (US)/100 miles': { toStandard: value => value, fromStandard: value => value },
    'kilometer/liter (km/l)': { toStandard: value => 100 / (value * 2.82481), fromStandard: value => 100 / (value * 2.82481) },
    'liters/100 kilometer': { toStandard: value => 282.481 / value, fromStandard: value => 282.481 / value },
    'liters/meter': { toStandard: value => 282.481 / (value * 0.000621371), fromStandard: value => 282.481 / (value * 0.000621371) },
    'miles/gallon (UK) (mpg)': { toStandard: value => value * 1.20095, fromStandard: value => value / 1.20095 },
    'miles/gallon (US) (mpg)': { toStandard: value => value, fromStandard: value => value },
  };

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = units[fromUnit].toStandard(parseFloat(value));
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = units[unit].fromStandard(parseFloat(fromValue));
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = units[fromUnit].fromStandard(parseFloat(fromValue));
    const finalValue = units[unit].toStandard(convertedValue);
    setToValue(isNaN(finalValue) ? '' : finalValue.toFixed(8));
  };
  
  const handleDownload = () => {
    downloadText(toValue, 'FuelConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <FuelConverterMetaTags />
      <div className='title'>
        <h1>Fuel Converter</h1>
        <p>
          <label htmlFor='content'>
            Fuel Unit Conversion - converts gallons ,miles, liters, kilometer etc...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default FuelConverter;
