import React from 'react';
import { FooterContainer } from './CopyRightFooter.style';

const CopyRightFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <p>Transforming Cases, Elevating Style. Explore endless possibilities with CaseModify.</p>
      <p>&copy; 2020 - {currentYear} CaseModify. All rights reserved.</p>
  </FooterContainer>
  );
};

export default CopyRightFooter;
