import React from 'react';
import { Helmet } from 'react-helmet';

const BubbleTextMetaTags = () => {
  return (
    <Helmet>
      <title>Bubble Text Generator | Bubble Font | Case Modify</title>
      <meta name="description" content="Quickly looking to develop your normal text into bubble text. Simply enter, see it get transformed and copy and paste to wherever you want. Find out more here." />

      <meta name="apple-mobile-web-app-title" content="Bubble Text Generator" />
      <meta name="application-name" content="Bubble Text Generator" />
      <meta name="msapplication-tooltip" content="Bubble Text Generator" />

      <link rel="canonical" href="https://casemodify.com/bubble-text-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/bubble-text-generator/" />
      <meta property="og:title" content="Bubble Text Generator - Bubble Generator | Bubble Font" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Quickly looking to develop your normal text into bubble text. Simply enter, see it get transformed and copy and paste to wherever you want. Find out more here." />
    </Helmet>
  );
};

export default BubbleTextMetaTags;
