import React from 'react';
import { Helmet } from 'react-helmet';

const TitleCaseMetaTags = () => {
  return (
    <Helmet>
      <title>Title Case Converter Tool | Capitalize My Title | Convert Case | Case Modify</title>
      <meta name="description" content="Looking to Capitalize Your Title? Then Use This Quick, Free Online Handy Title Case Converter Tool. Simple Copy and Paste." />

      <meta name="apple-mobile-web-app-title" content="Title Case Converter Tool" />
      <meta name="application-name" content="Title Case Converter Tool" />
      <meta name="msapplication-tooltip" content="Title Case Converter Tool" />

      <link rel="canonical" href="https://casemodify.com/title-case-converter" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/title-case-converter" />
      <meta property="og:title" content="Title Case Converter Tool | Capitalize My Title | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking to Capitalize Your Title? Then Use This Quick, Free Online Handy Title Case Converter Tool. Simple Copy and Paste." />
    </Helmet>
  );
};

export default TitleCaseMetaTags;
