import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './PowerConverter.style';
import PowerConverterMetaTags from '../../../components/MetaTag/PowerConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const PowerConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'Btu/hour': { toWatt: value => value * 0.293071, fromWatt: value => value / 0.293071 },
    'Btu/minute': { toWatt: value => value * 17.5843, fromWatt: value => value / 17.5843 },
    'Btu/second': { toWatt: value => value * 1055.058, fromWatt: value => value / 1055.058 },
    'calories(th)/hour': { toWatt: value => value * 0.001163, fromWatt: value => value / 0.001163 },
    'calories(th)/minute': { toWatt: value => value * 0.06978, fromWatt: value => value / 0.06978 },
    'calories(th)/second': { toWatt: value => value * 4.1868, fromWatt: value => value / 4.1868 },
    'foot pounds-force/minute': { toWatt: value => value * 0.022597, fromWatt: value => value / 0.022597 },
    'foot pounds-force/second': { toWatt: value => value * 1.35582, fromWatt: value => value / 1.35582 },
    'gigawatts [GW]': { toWatt: value => value * 1e9, fromWatt: value => value / 1e9 },
    'horsepowers (electric)': { toWatt: value => value * 746, fromWatt: value => value / 746 },
    'horsepowers (international)': { toWatt: value => value * 745.7, fromWatt: value => value / 745.7 },
    'horsepowers (water)': { toWatt: value => value * 746.043, fromWatt: value => value / 746.043 },
    'horsepowers (metric)': { toWatt: value => value * 735.49875, fromWatt: value => value / 735.49875 },
    'watts [W]': { toWatt: value => value, fromWatt: value => value },
    'joules/hour': { toWatt: value => value * 0.000277778, fromWatt: value => value / 0.000277778 },
    'joules/minute': { toWatt: value => value * 0.0166667, fromWatt: value => value / 0.0166667 },
    'joules/second': { toWatt: value => value, fromWatt: value => value },
    'kilocalories(th)/hour': { toWatt: value => value * 1.163, fromWatt: value => value / 1.163 },
    'kilocalories(th)/minute': { toWatt: value => value * 69.78, fromWatt: value => value / 69.78 },
    'kilogram-force meters/hour': { toWatt: value => value * 0.00980665, fromWatt: value => value / 0.00980665 },
    'kilogram-force meters/minute': { toWatt: value => value * 0.588399, fromWatt: value => value / 0.588399 },
    'kilowatts [kW]': { toWatt: value => value * 1000, fromWatt: value => value / 1000 },
    'megawatts [MW]': { toWatt: value => value * 1e6, fromWatt: value => value / 1e6 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertPower(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(10));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertPower(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(10));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertPower(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(10));
  };

  const convertPower = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const wattValue = units[fromUnit].toWatt(value);
    return units[toUnit].fromWatt(wattValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'PowerConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <PowerConverterMetaTags />
      <div className='title'>
        <h1>Power Converter</h1>
        <p>
          <label htmlFor='content'>
            Power Unit Conversion - converts watt, kilowatt, BTU per hour, calories per second, horsepower...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default PowerConverter;
