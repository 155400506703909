import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './DensityConverter.style';
import DensityConverterMetaTags from '../../../components/MetaTag/DensityConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const DensityConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'grains/gallon (UK)': { toGrams: value => value * 6.236023e-5, fromGrams: value => value / 6.236023e-5 },
    'grains/gallon (US)': { toGrams: value => value * 7.489152e-5, fromGrams: value => value / 7.489152e-5 },
    'grains/cubic foot': { toGrams: value => value * 0.016387064, fromGrams: value => value / 0.016387064 },
    'grains/cubic inch': { toGrams: value => value * 0.0005780367, fromGrams: value => value / 0.0005780367 },
    'grains/cubic yard': { toGrams: value => value * 1.247105e-9, fromGrams: value => value / 1.247105e-9 },
    'grams/cubic centimeters': { toGrams: value => value, fromGrams: value => value },
    'grams/liter': { toGrams: value => value * 0.001, fromGrams: value => value / 0.001 },
    'grams/milliliters': { toGrams: value => value, fromGrams: value => value },
    'kilograms/cubic meters': { toGrams: value => value * 0.001, fromGrams: value => value / 0.001 },
    'kilograms/liter': { toGrams: value => value * 1000, fromGrams: value => value / 1000 },
    'megagrams/cubic meter': { toGrams: value => value * 1000, fromGrams: value => value / 1000 },
    'milligrams/milliliter': { toGrams: value => value * 1, fromGrams: value => value / 1 },
    'milligrams/liter': { toGrams: value => value * 0.001, fromGrams: value => value / 0.001 },
    'ounces/cubic inch': { toGrams: value => value * 1.729994, fromGrams: value => value / 1.729994 },
    'ounces/gallon (UK)': { toGrams: value => value * 0.006236023, fromGrams: value => value / 0.006236023 },
    'ounces/gallon (US)': { toGrams: value => value * 0.007489152, fromGrams: value => value / 0.007489152 },
    'pounds/cubic inch': { toGrams: value => value * 27.679904, fromGrams: value => value / 27.679904 },
    'pounds/cubic foot': { toGrams: value => value * 16.018463, fromGrams: value => value / 16.018463 },
    'pounds/gallon (UK)': { toGrams: value => value * 0.099776373, fromGrams: value => value / 0.099776373 },
    'pounds/gallon (US)': { toGrams: value => value * 0.119826427, fromGrams: value => value / 0.119826427 },
    'slugs/cubic foot': { toGrams: value => value * 515.31788206, fromGrams: value => value / 515.31788206 },
    'tonnes/cubic centimeter': { toGrams: value => value * 1e-6, fromGrams: value => value / 1e-6 },
    'tonnes/cubic decimeter': { toGrams: value => value * 0.001, fromGrams: value => value / 0.001 },
    'tonnes/cubic meter': { toGrams: value => value * 1000, fromGrams: value => value / 1000 },
    'tonnes/cubic liter': { toGrams: value => value * 1, fromGrams: value => value / 1 },
    'tonnes/cubic milliliter': { toGrams: value => value * 1e-6, fromGrams: value => value / 1e-6 },
    'tons (UK)/cubic yard': { toGrams: value => value * 1.328939e-6, fromGrams: value => value / 1.328939e-6 },
    'tons (US)/cubic yard': { toGrams: value => value * 1.186552e-6, fromGrams: value => value / 1.186552e-6 },
  };

  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertDensity(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertDensity(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertDensity(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const convertDensity = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const gramsPerCubicCentimeter = units[fromUnit].toGrams(value);
    return units[toUnit].fromGrams(gramsPerCubicCentimeter);
  };


  const handleDownload = () => {
    downloadText(toValue, 'DensityConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <DensityConverterMetaTags />
      <div className='title'>
        <h1>Density Converter</h1>
        <p>
          <label htmlFor='content'>
            Density Unit Conversion - converts gram per cubic meter, kilogram/liter ...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default DensityConverter;
