import React from 'react';

const TextAnalyzer = ({ text }) => {
  const analyzeText = () => {
    const characterCount = text.length;
    const wordCount = text.split(/\s+/).filter(Boolean).length;
    const sentenceCount = text.split(/[.!?]+/).filter(Boolean).length;
    const lineCount = characterCount && text.split(/\r?\n/).length;

    return {
      characterCount,
      wordCount,
      sentenceCount,
      lineCount,
    };
  };

  const { characterCount, wordCount, sentenceCount, lineCount } = analyzeText();

  return (
    <>
      <span>Character Count: {characterCount}</span>
      <span> | Word Count: {wordCount}</span>
      <span> | Sentence Count: {sentenceCount}</span>
      <span> | Line Count: {lineCount}</span>
    </>
  );
};

export default TextAnalyzer;
