import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './WeightConverter.style';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import WeightConverterMetaTags from '../../../components/MetaTag/WeightConverterMetaTags';

const WeightConverter = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [fromUnit, setFromUnit] = useState('atomic mass unit (amu)');
  const [toUnit, setToUnit] = useState('atomic mass unit (amu)');
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  const units = {
    'atomic mass unit (amu)': { toGram: value => value, fromGram: value => value },
    'carat (metric)': { toGram: value => value * 0.2, fromGram: value => value / 0.2 },
    cental: { toGram: value => value * 45359.237, fromGram: value => value / 45359.237 },
    centigram: { toGram: value => value * 0.01, fromGram: value => value / 0.01 },
    dekagram: { toGram: value => value * 10, fromGram: value => value / 10 },
    'dram (dr)': { toGram: value => value * 1.7718451953125, fromGram: value => value / 1.7718451953125 },
    'grain (gr)': { toGram: value => value * 0.06479891, fromGram: value => value / 0.06479891 },
    'gram (g)': { toGram: value => value, fromGram: value => value },
    'hundredweight (UK)': { toGram: value => value * 50802.345, fromGram: value => value / 50802.345 }, // Updated conversion factor
    'kilogram (kg)': { toGram: value => value * 1000, fromGram: value => value / 1000 },
    'microgram (μg)': { toGram: value => value * 1e-6, fromGram: value => value / 1e-6 },
    'milligram (mg)': { toGram: value => value * 0.001, fromGram: value => value / 0.001 },
    'newton (Earth)': { toGram: value => value * 101.9716213, fromGram: value => value / 101.9716213 },
    'ounce (oz)': { toGram: value => value * 28.349523125, fromGram: value => value / 28.349523125 },
    'pennyweight (dwt)': { toGram: value => value * 1.55517384, fromGram: value => value / 1.55517384 },
    'pound (lb)': { toGram: value => value * 453.59237, fromGram: value => value / 453.59237 },
    quarter: { toGram: value => value * 12700.586, fromGram: value => value / 12700.586 },
    stone: { toGram: value => value * 6350.293, fromGram: value => value / 6350.293 },
    'ton (UK, long)': { toGram: value => value * 1016046.91, fromGram: value => value / 1016046.91 },
    'ton (US, short)': { toGram: value => value * 907184.74, fromGram: value => value / 907184.74 },
    'tonne (t)': { toGram: value => value * 1e6, fromGram: value => value / 1e6 },
    'troy ounce': { toGram: value => value * 31.1034768, fromGram: value => value / 31.1034768 },
  };

  const convertWeight = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const gramValue = units[fromUnit].toGram(value);
    return units[toUnit].fromGram(gramValue);
  };

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertWeight(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertWeight(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertWeight(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleDownload = () => {
    downloadText(toValue, 'WeightConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <WeightConverterMetaTags />
      <div className='title'>
        <h1>Weight Converter</h1>
        <p>
          <label htmlFor='content'>Convert between many different weight standards</label>
        </p>
      </div>
      {showMessage && <div className='success-message'>Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
        <div className='cols'>
          <div className='col'>
            <p>
              <span className='target'>From:</span> {fromUnit}
            </p>
            <input
              className='input'
              type='number'
              value={fromValue}
              onChange={handleFromValueChange}
              placeholder='Enter value'
            />
            <select
              className='select-unit'
              onChange={handleFromUnitChange}
              value={fromUnit}
              size={15}
              style={{ height: '555px' }}>
              {Object.keys(units).map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
          <div className='col'>
            <p>
              <span className='target'>To: </span> {toUnit}
            </p>
            <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
            <select
              className='select-unit'
              onChange={handleToUnitChange}
              value={toUnit}
              size={15}
              style={{ height: '555px' }}>
              {Object.keys(units).map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='btns'>
        <button className='btn' onClick={handleDownload}>
          Download Text
        </button>
        <button className='btn' onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </button>
        <a href='https://www.ko-fi.com/casemodify' className='btn-coffee' target='_blank' rel='noopener noreferrer'>
          <CoffeeCup src={'/icons/coffee-cup.png'} alt='Coffee Cup' /> Buy me a Coffee
        </a>
      </div>

      <div className='media'>
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default WeightConverter;
