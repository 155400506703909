import React from 'react';
import { Helmet } from 'react-helmet';

const FacebookFontMetaTags = () => {
  return (
    <Helmet>
      <title>Facebook Font Generator | Facebook Unique Fonts | Case Modify</title>
      <meta name="description" content="Create unique fonts for your Facebook profile or page with our easy-to-use font generator." />

      <meta name="apple-mobile-web-app-title" content="Facebook Font Generator" />
      <meta name="application-name" content="Facebook Font Generator" />
      <meta name="msapplication-tooltip" content="Facebook Font Generator" />

      <link rel="canonical" href="https://casemodify.com/facebook-font-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/facebook-font-generator/" />
      <meta property="og:title" content="Facebook Font Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Create unique fonts for your Facebook profile or page with our easy-to-use font generator." />
    </Helmet>
  );
};

export default FacebookFontMetaTags;
