import React from 'react';
import { Helmet } from 'react-helmet';

const EnergyConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Energy Converter | Case Modify</title>
      <meta name="description" content="Energy Unit Conversion - converts calories, Btu, gigajoules, joules, therms ..." />
      <meta name="Keywords" content="converts calories, Btu, gigajoules, joules, therms" />

      <meta name="apple-mobile-web-app-title" content="Energy Converter" />
      <meta name="application-name" content="Energy Converter" />
      <meta name="msapplication-tooltip" content="Energy Converter" />

      <link rel="canonical" href="https://casemodify.com/energy-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/energy-converter/" />
      <meta property="og:title" content="Energy Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Energy Unit Conversion - Energy Unit Conversion - converts calories ,Btu ,gigajoules , joules ,therms ..." />
    </Helmet>
  );
};

export default EnergyConverterMetaTags;
