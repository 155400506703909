import React from 'react';
import { Helmet } from 'react-helmet';

const StrikethroughTextMetaTags = () => {
  return (
    <Helmet>
      <title>Strikethrough Text Generator | Cross Out Text Generator | Convert Case | Case Modify</title>
      <meta name="description" content="Need a Strike Through Text Generator? Then Put a Line Through Your Text Here - Copy and Paste It Where You’d Like." />

      <meta name="apple-mobile-web-app-title" content="Strikethrough Text Generator" />
      <meta name="application-name" content="Strikethrough Text Generator" />
      <meta name="msapplication-tooltip" content="Strikethrough Text Generator" />

      <link rel="canonical" href="https://casemodify.com/strikethrough-text-generator/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/strikethrough-text-generator/" />
      <meta property="og:title" content="Strikethrough Text Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Need a Strike Through Text Generator? Then Put a Line Through Your Text Here - Copy and Paste It Where You’d Like." />
    </Helmet>
  );
};

export default StrikethroughTextMetaTags;
