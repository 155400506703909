import React from 'react';
import { Helmet } from 'react-helmet';

const ItalicTextMetaTags = () => {
  return (
    <Helmet>
      <title>Italic Text Generator | Case Modify</title>
      <meta name="description" content="Want to convert your text into italics? Use our fast, free, and easy-to-use online Italic Text Generator to transform your text into an italic font." />

      <meta name="apple-mobile-web-app-title" content="Italic Text Generator" />
      <meta name="application-name" content="Italic Text Generator" />
      <meta name="msapplication-tooltip" content="Italic Text Generator" />

      <link rel="canonical" href="https://casemodify.com/italic-text-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/italic-text-converter/" />
      <meta property="og:title" content="Italic Text Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Want to convert your text into italics? Use our fast, free, and easy-to-use online Italic Text Generator to transform your text into an italic font." />
    </Helmet>
  );
};

export default ItalicTextMetaTags;
