import React, { useState, useEffect } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './FrequencyConverter.style';
import BoldTextMetaTags from '../../../components/MetaTag/BoldTextMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const FrequencyConverter = () => {
  const cb_frequency = [
    ['1/second', 1],
    ['cycle/second', 1],
    ['degree/hour', 1 / 1296e3],
    ['degree/minute', 1 / 21600],
    ['degree/second', 1 / 360],
    ['gigahertz', 1e9],
    ['hertz', 1],
    ['kilohertz', 1e3],
    ['megahertz', 1e6],
    ['millihertz', 0.001],
    ['radian/hour', 1 / 22619.467],
    ['radian/minute', 1 / 376.99112],
    ['radian/second', 1 / 6.2831853],
    ['revolution/hour', 1 / 3600],
    ['revolution/minute', 1 / 60],
    ['revolution/second', 1],
    ['RPM', 1 / 60],
    ['terrahertz', 1e12],
  ];

  const [showMessage, setShowMessage] = useState(false);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [fromUnit, setFromUnit] = useState('1/second');
  const [toUnit, setToUnit] = useState('1/second');

  useEffect(() => {
    fromValue && convert();
  }, [fromValue, fromUnit, toUnit]);

  const stripBad = (value) => {
    // Function to strip bad characters if required
    return value.replace(/[^0-9.eE-]/g, '');
  };

  const convert = () => {
    let value = parseFloat(stripBad(fromValue));
    value = isNaN(value) ? 0 : value;

    const lUnitFrom = cb_frequency.find((unit) => unit[0] === fromUnit);
    const lUnitTo = cb_frequency.find((unit) => unit[0] === toUnit);

    setFromValue(value.toString());
    setToValue('');

    if (lUnitFrom && lUnitTo) {
      let convertedValue = (value * lUnitFrom[1]) / lUnitTo[1];
      convertedValue = Math.round(convertedValue * Math.pow(10, 10)) / Math.pow(10, 10);
      setToValue(convertedValue.toString());
    }
  };

  const handleDownload = () => {
    downloadText(toValue, 'FrequencyConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <BoldTextMetaTags />
      <div className='title'>
        <h1>Frequency Converter</h1>
        <p>
          <label htmlFor='content'>
            Length Unit Conversion - converts meter, foot, inch, yard, kilometer, mile, nautical mile, light-year etc
          </label>
        </p>
      </div>
      {showMessage && <div className='success-message'>Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
        <div className='cols'>
          <div className='col'>
            <p>
              <span className='target'>From:</span> {fromUnit}
            </p>
            <input
              className='input'
              type='number'
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
              placeholder='Enter value'
            />
            <select className='select-unit' size={17} onChange={(e) => setFromUnit(e.target.value)} value={fromUnit}>
              <option value='1/second'>1/second</option>
              <option value='cycle/second'>cycle/second</option>
              <option value='degree/hour'>degree/hour</option>
              <option value='degree/minute'>degree/minute</option>
              <option value='degree/second'>degree/second</option>
              <option value='gigahertz'>gigahertz</option>
              <option value='hertz'>hertz</option>
              <option value='kilohertz'>kilohertz</option>
              <option value='megahertz'>megahertz</option>
              <option value='millihertz'>millihertz</option>
              <option value='radian/hour'>radian/hour</option>
              <option value='radian/minute'>radian/minute</option>
              <option value='radian/second'>radian/second</option>
              <option value='revolution/hour'>revolution/hour</option>
              <option value='revolution/minute'>revolution/minute</option>
              <option value='revolution/second'>revolution/second</option>
              <option value='RPM'>RPM</option>
              <option value='terrahertz'>terrahertz</option>
            </select>
          </div>
          <div className='col'>
            <p>
              <span className='target'>To: </span> {toUnit}
            </p>
            <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
            <select className='select-unit' size={17} onChange={(e) => setToUnit(e.target.value)} value={toUnit}>
              <option value='1/second'>1/second</option>
              <option value='cycle/second'>cycle/second</option>
              <option value='degree/hour'>degree/hour</option>
              <option value='degree/minute'>degree/minute</option>
              <option value='degree/second'>degree/second</option>
              <option value='gigahertz'>gigahertz</option>
              <option value='hertz'>hertz</option>
              <option value='kilohertz'>kilohertz</option>
              <option value='megahertz'>megahertz</option>
              <option value='millihertz'>millihertz</option>
              <option value='radian/hour'>radian/hour</option>
              <option value='radian/minute'>radian/minute</option>
              <option value='radian/second'>radian/second</option>
              <option value='revolution/hour'>revolution/hour</option>
              <option value='revolution/minute'>revolution/minute</option>
              <option value='revolution/second'>revolution/second</option>
              <option value='RPM'>RPM</option>
              <option value='terrahertz'>terrahertz</option>
            </select>
          </div>
        </div>
      </div>

      <div className='btns'>
        <button className='btn' onClick={handleDownload}>
          Download Text
        </button>
        <button className='btn' onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </button>
        <a href='https://www.ko-fi.com/casemodify' className='btn-coffee' target='_blank' rel='noopener noreferrer'>
          <CoffeeCup src={'/icons/coffee-cup.png'} alt='Coffee Cup' /> Buy me a Coffee
        </a>
      </div>

      <div className='media'>
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default FrequencyConverter;
