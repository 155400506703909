import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './TitleCaseConverter.style';
import { convertToTitleCase } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import TitleCaseMetaTags from '../../../components/MetaTag/TitleCaseMetaTags';

const TitleCaseConverter = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    const newTransformedText = convertToTitleCase(inputText);
    setTransformedText(newTransformedText);
  };

  const handleDownload = () => {
    downloadText(transformedText, 'titleCaseText.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(transformedText);

     // Set showMessage to true when the button is clicked
     setShowMessage(true);

     // Use setTimeout to set showMessage back to false after 5 seconds
     setTimeout(() => {
       setShowMessage(false);
     }, 5000);
  };

  return (
    <Container>
      <TitleCaseMetaTags />
      
      <div className="title">
        <h1>Title Case Converter Tool</h1>
        <p>
          <label htmlFor="content">
            Explore the convenience of our Bold Text Generator. Paste or type your text into the left field, and witness it instantly transform into bold font on the right. Copy the bold text from the converter and effortlessly place it wherever you desire.
          </label>
        </p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              id="content"
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <p dangerouslySetInnerHTML={{ __html: transformedText }} />
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
 <a
          href="https://www.ko-fi.com/casemodify"
          className="btn-coffee"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
          Buy me a Coffee
        </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default TitleCaseConverter;
