import React from 'react';
import { Helmet } from 'react-helmet';

const SentenceCaseMetaTags = () => {
  return (
    <Helmet>
      <title>Sentence Case Converter | Sentence Case Generator | Case Modify</title>
      <meta name="description" content="Looking for a Quick and Easy Sentence Case Converter? Use This Online Sentence Case Converter to Get Sentence Casing." />

      <meta name="apple-mobile-web-app-title" content="Sentence Case Converter " />
      <meta name="application-name" content="Sentence Case Converter " />
      <meta name="msapplication-tooltip" content="Sentence Case Converter " />

      <link rel="canonical" href="https://casemodify.com/sentence-case/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/sentence-case/" />
      <meta property="og:title" content="Sentence Case Converter  - Sentence Case Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking for a Quick and Easy Sentence Case Converter? Use This Online Sentence Case Converter to Get Sentence Casing." />
    </Helmet>
  );
};

export default SentenceCaseMetaTags;
