import React, { useState, useEffect } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './LengthConverter.style';
import LengthConverterMetaTags from '../../../components/MetaTag/LengthConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const LengthConverter = () => {
  const cb_length = [
    'angstrom',
    'astronomical unit (au)',
    'centimeter (cm)',
    'chain',
    'decimeter (dm)',
    'fathom',
    'foot (ft)',
    'furlong',
    'inch (in)',
    'kilometer (km)',
    'league',
    'light year',
    'meter (m)',
    'mile (mi)',
    'millimeter (mm)',
    'micron (μ)',
    'nanometer (nm)',
    'nautical mile',
    'parsec',
    'rod',
    'yard (yd)',
  ];
  const factors_length = [
    1e10, 66845871226706e-25, 100, 0.049709695378987, 10, 0.54680664916885, 3.2808398950131, 0.0049709695378987,
    39.370078740157, 0.001, 0.00020712373074577, 10570008340246e-29, 1, 0.00062137119223733, 1e3, 1e6, 1e9,
    0.00053995680345572, 32407792896393e-30, 0.19883878151595, 1.0936132983377,
  ];

  const fixLength = (e) => {
    if (!isFinite(e)) return '';
    if (e === 0) return '0';

    let st = '' + e;
    let epos = st.indexOf('E');
    if (epos === -1) epos = st.indexOf('e');

    let sdigi = Math.log(Math.abs(e)) / Math.LN10;
    let sdigif = Math.floor(sdigi);

    if (epos === -1) {
      let adjust = Math.pow(10, sdigif - 12);
      let faqs = Math.round(e / adjust);

      if (sdigif < 12) {
        let adjust2 = Math.pow(10, 12 - sdigif);
        return faqs / adjust2;
      } else {
        return faqs * adjust;
      }
    } else {
      let zo = e * Math.pow(10, 12 - sdigif);
      let szo = String(Math.round(zo));

      let inse = '-'.includes(szo.charAt(0)) ? 2 : 1;
      let rest = szo.substring(inse);

      let i = rest.length - 1;
      while (i >= 0 && rest.charAt(i) === '0') {
        i--;
      }

      rest = rest.substring(0, i + 1);
      let rou = szo.substring(0, inse);

      if (rest.length > 0) rou += '.' + rest;

      let sa = sdigif < 0 ? rou + 'E' : rou + 'E+';
      let snow = sa + sdigif;

      let vanow = Math.abs(parseFloat(snow));
      let faqsvab = Math.abs(e);

      if (sdigif >= 0) {
        if (vanow > 5 * faqsvab) {
          snow = sa + String(sdigif - 1);
        } else if (vanow < faqsvab / 5) {
          snow = sa + String(sdigif + 1);
        }
      }

      vanow = parseFloat(snow);

      if (vanow > 1.1 * e || vanow < 0.9 * e) {
        return e;
      } else {
        return snow;
      }
    }
  };

  const convertLength = (fromIndex, toIndex, fromInput) => {
    const faqsorg = factors_length[toIndex] / factors_length[fromIndex];
    const resfaqs = fromInput * faqsorg;

    if (isNaN(parseFloat(resfaqs))) {
      return '';
    } else {
      return fixLength(parseFloat(resfaqs)) + ' ';
    }
  };

  const [showMessage, setShowMessage] = useState(false);
  const [fromSelectIndex, setFromSelectIndex] = useState(0);
  const [toSelectIndex, setToSelectIndex] = useState(1);
  const [fromInput, setFromInput] = useState('');
  const [toValue, setToValue] = useState('');

  const handleConversion = async () => {
    setToValue('');
    const result = convertLength(fromSelectIndex, toSelectIndex, parseFloat(fromInput));

    setToValue(result.toString());
  };

  useEffect(() => {
    fromInput && handleConversion();
  }, [fromInput, fromSelectIndex, toSelectIndex]);

  const handleDownload = () => {
    downloadText(toValue, 'LengthConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <LengthConverterMetaTags />
      <div className='title'>
        <h1>Length Converter</h1>
        <p>
          <label htmlFor='content'>Convert between many different frequency standards</label>
        </p>
      </div>
      {showMessage && <div className='success-message'>Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
        <div className='cols'>
          <div className='col'>
            <p>
              <span className='target'>From:</span> {cb_length[fromSelectIndex]}
            </p>
            <input
              className='input'
              type='number'
              value={fromInput}
              onChange={(e) => setFromInput(e.target.value)}
              placeholder='Enter value'
            />
            <select
              className='select-unit'
              size={17}
              onChange={(e) => setFromSelectIndex(e.target.value)}
              value={fromSelectIndex}>
              {cb_length.map((unit, index) => (
                <option key={index} value={index}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
          <div className='col'>
            <p>
              <span className='target'>To: </span> {cb_length[toSelectIndex]}
            </p>
            <input className='output' type='string' placeholder='Converted value' value={toValue} readOnly />
            <select
              className='select-unit'
              size={17}
              onChange={(e) => setToSelectIndex(e.target.value)}
              value={toSelectIndex}>
              {cb_length.map((unit, index) => (
                <option key={index} value={index}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='btns'>
        <button className='btn' onClick={handleDownload}>
          Download Text
        </button>
        <button className='btn' onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </button>
        <a href='https://www.ko-fi.com/casemodify' className='btn-coffee' target='_blank' rel='noopener noreferrer'>
          <CoffeeCup src={'/icons/coffee-cup.png'} alt='Coffee Cup' /> Buy me a Coffee
        </a>
      </div>

      <div className='media'>
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default LengthConverter;
