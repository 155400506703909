import styled from 'styled-components';

export const FooterContainer = styled.footer`
  margin: 0 auto 2px;
  width: 100%;
  max-width: 1200px;
  padding-top: 15px;
  line-height: 1.5;
  background-color: #e0e0e0;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CompanyInfo = styled.div`
  width: 30%;
  padding-left: 30px;
  h2 {
    text-transform: uppercase;
    span {
      color: #5cb85c;
    }
    color: #B4B1AB;
  }
  .logo {
    width: 200px;
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 15px;

    p {
      padding: 0 10px;
    }
  }
`;

export const Widget = styled.div`
  width: 70%;
  display: flex;
  
  h3 {
    color: #666663;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      a {
        color: black;

        font-size: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InfoWidget = styled(Widget)`
  width: 25%;
  display: block;

  @media (max-width: 768px) {
    width: 70%;
    padding-left: 20px;
  }
`;

export const CategoriesWidget = styled(Widget)`
  width: 70%;
  display: block;

  .grid-container {
    display: flex;
  }

  .grid-item {
    margin-right: 20px;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    width: 100%;

    .grid-container {
      flex-wrap: wrap;
    }

    .grid-item {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;