import React from 'react';
import { DescriptionContainer } from './Description.style';

const Description = () => {
  return (
    <DescriptionContainer>
      <div>
        <h2>Welcome to the Case Modify Text Generator Tool</h2>
        <p>
          Discover a convenient online text tool that enables you to seamlessly switch between lowercase and uppercase
          letters. This versatile tool allows you to capitalize, uncapitalize, convert to mixed case, and transform your
          text. Explore the various options provided below:
        </p>
      </div>
      <div>
        <h3>
          <a href='/sentence-case'>Sentence Case</a>
        </h3>
        <p>
          Utilize the sentence case converter to effortlessly paste any text of your choice, and watch as it
          automatically transforms it into a well-structured, fully formed sentence.
        </p>
        <p>
          The process involves capitalizing the initial letter of each sentence, subsequently converting the remaining
          text to lowercase, and transforming "i" into "I". Additionally, every letter following a full stop will be
          converted into uppercase.
        </p>
        <p>This is an example of sentence case.</p>
      </div>
      <div>
        <h3>Lower Case</h3>
        <p>
          If you're seeking a way to convert text to lowercase, the lowercase text converter is the solution. It changes
          all the letters in your text to lowercase. Just copy the text you want to convert, paste it into the box
          above, and choose the 'lower case' tab.
        </p>
        <p>this is an example of lower case.</p>
      </div>
      <div>
        <h3>Upper Case</h3>
        <p>
          The uppercase transformer is designed to convert any given text into uppercase letters. It effectively turns
          all lowercase letters into CAPITALS while maintaining the uppercase letters in their original form.
        </p>
        <p>
          To accomplish this, choose the text you want to modify, paste it into the box above, and then select the UPPER
          CASE tab.
        </p>
        <p>THIS IS AN EXAMPLE OF UPPER CASE.</p>
      </div>
      <div>
        <h3>Capitalized Case</h3>
        <p>
          The title case converter automatically transforms the initial letter of each word into uppercase, while
          leaving the rest of the letters in lowercase.
        </p>
        <p>
          Just copy the content you want to format into this style, then paste it into the form box above, and choose
          the Capitalized Case tab.
        </p>
        <p>This Is An Example Of Capitalized Case.</p>
      </div>
      <div>
        <h3>Alternating Case</h3>
        <p>
          With the alternating case converter, you can change the format of your text, irrespective of its current
          state, to alternate between lowercase and uppercase. This tool will produce a capital letter followed by a
          lowercase letter within the same word.
        </p>
        <p>tHiS Is aN ExAmPlE Of aLtErNaTiNg cAsE.</p>
      </div>
      <div>
        <h3>
          <a href='/title-case-converter'>Title Case</a>
        </h3>
        <p>
          The title case converter is ideal for individuals who may be uncertain about how to format the title of an
          upcoming essay. It guarantees the appropriate capitalization of letters within the context of a title. Common
          words like "an" will remain in lowercase, while significant words such as "Title" will be converted
          accordingly.
        </p>
        <p>This Is an Example of Title Case.</p>
      </div>
      <div>
        <h3>
          <a href='/small-text-generator'>Small Text Generator</a>
        </h3>
        <p>
          The small text generator effortlessly transforms regular-sized text into small text, including "small caps"
          and "Superscript" styles. Input your usual text and witness its conversion into the small text font.
        </p>
        <p>
          Tʜɪꜱ ɪꜱ ᴀɴ ᴇxᴀᴍᴘʟᴇ ᴏꜰ ᴛɪɴʏ ᴛᴇxᴛ ᴄᴀꜱᴇ ꜱᴍᴀʟʟ ᴄᴀᴘꜱ <br />
          ᵗʰⁱˢ ⁱˢ ᵃⁿ ᵉˣᵃᵐᵖˡᵉ ᵒᶠ ᵗⁱⁿʸ ᵗᵉˣᵗ ᶜᵃˢᵉ ˢᵘᵖᵉʳˢᶜʳⁱᵖᵗ{' '}
        </p>
      </div>
      <div>
        <h3>
          <a href='/wide-text-generator'>Wide Text Generator</a>
        </h3>
        <p>
          If you aim to enhance the appearance of your text, the widening text generator, also referred to as the
          Aesthetic Font and text generator, is an excellent tool for achieving this effect. Just input your regular
          text, and witness it gradually broaden.
        </p>
        <p>Ｔｈｉｓ ｉｓ ａｎ ｅｘａｍｐｌｅ ｏｆ ｗｉｄｅ ａｅｓｔｈｅｔｉｃ ｔｅｘｔ。</p>
      </div>
      <div>
        <h3>
          <a href='/reverse-text-generator'>Reverse Text Generator</a>
        </h3>
        <p>
          If you're looking for a swift and easy method to reverse your text, the reverse text generator is an excellent
          choice. Simply write your text as usual and witness it being flipped.
        </p>
        <p>.txet sdrawkcab fo ecnetnes elpmaxe na si sihT</p>
      </div>
      <div>
        <h3>
          <a href='/upside-down-text-generator'>Upside down Text Generator</a>
        </h3>
        <p>
          Like the mirror text generator and the reverse text generator, the upside-down text generator allows you to
          flip your text both upside down and back to front.
        </p>
        <p>˙ʇxǝʇ uʍop ǝpᴉsdn ɟo ǝldɯɐxǝ uɐ sᴉ sᴉɥ┴</p>
      </div>
      <div>
        <h3>Binary Code Translator</h3>
        <p>
          Translate binary code into English and English into binary code with the following generator. Type out
          regularly and get a series of 0’s and 1’s in return.
        </p>
        <p>
          01000010 01101001 01101110 01100001 01110010 01111001 00100000 01000011 01101111 01100100 01100101 00100000
          01010100 01110010 01100001 01101110 01110011 01101100 01100001 01110100 01101111 01110010
        </p>
      </div>
      <div>
        <h3>
          <a href='/bold-text-converter'>Bold Text Generator</a>
        </h3>
        <p>
          A fast method to make your text bold online. Just input the regular text into the tool, and witness its
          automatic conversion into the bold version, ready for you to copy and paste.
        </p>
        <p>𝗧𝗵𝗶𝘀 𝗶𝘀 𝗮𝗻 𝗲𝘅𝗮𝗺𝗽𝗹𝗲 𝗼𝗳 𝗯𝗼𝗹𝗱 𝘁𝗲𝘅𝘁.</p>
      </div>
      <div>
        <h3>
          <a href='/strikethrough-text-generator'>Strikethrough Text Generator</a>
        </h3>
        <p>
          If you need a swift method to strikethrough your text, this tool is perfect. Enter the regular text you want
          to be crossed out, and witness it being automatically generated for easy copying and pasting.
        </p>
        <p>T̶h̶i̶s̶ ̶i̶s̶ ̶a̶n̶ ̶e̶x̶a̶m̶p̶l̶e̶ ̶o̶f̶ ̶s̶t̶r̶i̶k̶e̶t̶h̶r̶o̶u̶g̶h̶ ̶t̶e̶x̶t̶.̶</p>
      </div>
      <div>
        <h3>
          <a href='/italic-text-converter'>Italic Text Converter</a>
        </h3>
        <p>
          If you wish to italicize your font, this tool is perfect for the job. Input your regular text, and observe it
          automatically displayed at a slant, ready for you to copy and paste wherever needed.
        </p>
        <p>𝘛𝘩𝘪𝘴 𝘪𝘴 𝘢𝘯 𝘦𝘹𝘢𝘮𝘱𝘭𝘦 𝘰𝘧 𝘪𝘵𝘢𝘭𝘪𝘤 𝘵𝘦𝘹𝘵.</p>
      </div>
      <div>
        <h3>
          <a href='/underline-text-generator'>Underline Text Generator</a>
        </h3>
        <p>
          Use this online underliner tool to emphasize your text. Simply write or paste the content you wish to
          underline, and you'll see it automatically generated with underlining. Copy and paste it wherever you need.
        </p>
        <p>T̲h̲i̲s̲ ̲i̲s̲ ̲a̲n̲ ̲e̲x̲a̲m̲p̲l̲e̲ ̲o̲f̲ ̲u̲n̲d̲e̲r̲l̲i̲n̲e̲ ̲t̲e̲x̲t̲.̲</p>
      </div>
      <div>
        <h3>
          <a href='/mirror-text-generator'>Mirror Text Generator</a>
        </h3>
        <p>
          Mirror text is essentially the identical text you'd observe when your text is reflected in a mirror. Print the
          text, hold it up to a mirror, and it should appear in the correct orientation.
        </p>
        <p>.ƚxɘƚ ɿoɿɿim ʇo ɘlqmɒxɘ nɒ ƨi ƨiʜT</p>
      </div>
      <div>
        <h3>
          <a href='/unicode-text-converter'>Unicode Text Converter</a>
        </h3>
        <p>
          Discover a variety of fonts with our Unicode text generator. Input your regular text and select the font style
          of your choice.
        </p>
      </div>
      <div>
        <h3>
          <a href='/zalgo-glitch-text-converter'>Zalgo Glitch Text Generator</a>
        </h3>
        <p>
          Interested in creating text with a funky and glitchy vibe? This Zalgo text will achieve just that. Type out
          your desired text, and witness it transform into the distinctive Zalgo font.
        </p>
        <p class='zalgoText'>T̵̡̧̨̖̪̗̠͔̰̓̈́̕͜ẖ̷̙̪̮̭̖̮̯̘͋͐̃̅͌̈́̆̕ͅȉ̶̛̫̟͎͋͗̓s̵̡͔̻͆̄͘ i̴̟͔͚͔͗͠ś̴̳̗͙ a̵̧̳͇̜̪̣̅̓n̸̛̰̼͇̱͐̎̀̽̌̐̿̈́̓ͅ ḛ̴̟̉͗x̷̬̩̀̕à̵̡̭̫̤̯͇̫͓̘͊̑͐̎̍͠m̶͎̦̾̾͠p̷̗̬̯̰͙̣̮̋̈́͆͊̇̿̋l̸̨̘̞̗̩̞̱͖̏̍̽̆͜͜e̸̱̹̬̞̹̍̇̓ͅ o̷̊ͅḟ̴͈̆̔̾̒́ g̴̤̪̻̰̗̀̓̎͋͝l̷̨͇͔͈̤̝̊̀̂͛͜į̴̡̦̼̄̏̏͛̒ẗ̶̙̻̜̺͉́̽̒̅̀̐͝c̸̛̛͇̱͇̮͔͔͙̼̀̈́̀͜h̵̙̘̣̻͈̫͇̜͂́̍͊͗̀͜ t̷̬̹̜̼̫̘͒̊ę̷̰̬̠̭̠͉̳̐́̒͑͋̓̎̽̈́͠x̷̢̢̛͓̜͚̖̜͕̼̙̌̍ţ̵̖͖̜̜̪́̎͒̚͜͝.̷̛̬̖̳̻̪̩̾̾̆̄̓͋͒̚͠</p>
      </div>
    </DescriptionContainer>
  );
};

export default Description;
