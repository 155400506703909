import React from 'react';
import { Helmet } from 'react-helmet';

const UnicodeTextMetaTags = () => {
  return (
    <Helmet>
      <title>Unicode Text Converter | Convert Unicode Text | Convert Case | Case Modify</title>
      <meta name="description" content="Looking for a Simple to Use Unicode Text Converter Online for Free?" />

      <meta name="apple-mobile-web-app-title" content="Unicode Text Converter" />
      <meta name="application-name" content="Unicode Text Converter" />
      <meta name="msapplication-tooltip" content="Unicode Text Converter" />

      <link rel="canonical" href="https://casemodify.com/unicode-text-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/unicode-text-converter/" />
      <meta property="og:title" content="Unicode Text Converter | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking for a Simple to Use Unicode Text Converter Online for Free? Use This Simple and Fast Unicode Text Converter Right Here to Translate Fonts." />
    </Helmet>
  );
};

export default UnicodeTextMetaTags;
