import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './MassConverter.style';
import MassConverterMetaTags from '../../../components/MetaTag/MassConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const MassConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'carats (metric)': { toGram: value => value * 0.2, fromGram: value => value / 0.2 },
    'cental': { toGram: value => value * 45359.237, fromGram: value => value / 45359.237 },
    'Earth masses': { toGram: value => value * 5.9736e24, fromGram: value => value / 5.9736e24 },
    'grains': { toGram: value => value * 0.06479891, fromGram: value => value / 0.06479891 },
    'grams': { toGram: value => value, fromGram: value => value },
    'hundredweights': { toGram: value => value * 45359.237, fromGram: value => value / 45359.237 },
    'kilograms [kg]': { toGram: value => value * 1000, fromGram: value => value / 1000 },
    'ounces (US & UK)': { toGram: value => value * 28.349523125, fromGram: value => value / 28.349523125 },
    'ounces (troy, precious metals)': { toGram: value => value * 31.1034768, fromGram: value => value / 31.1034768 },
    'pounds [lbs] (US & UK)': { toGram: value => value * 453.59237, fromGram: value => value / 453.59237 },
    'pounds (troy, precious metals)': { toGram: value => value * 373.241722, fromGram: value => value / 373.241722 },
    'Solar masses': { toGram: value => value * 1.989e30, fromGram: value => value / 1.989e30 },
    'slugs (g-pounds)': { toGram: value => value * 14593.903, fromGram: value => value / 14593.903 },
    'stones': { toGram: value => value * 6350.293, fromGram: value => value / 6350.293 },
    'tons (UK or long)': { toGram: value => value * 1016046.91, fromGram: value => value / 1016046.91 },
    'tons (US or short)': { toGram: value => value * 907184.74, fromGram: value => value / 907184.74 },
    'tonnes': { toGram: value => value * 1e6, fromGram: value => value / 1e6 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const convertMass = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const gramValue = units[fromUnit].toGram(value);
    return units[toUnit].fromGram(gramValue);
  };
  
  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertMass(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertMass(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertMass(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleDownload = () => {
    downloadText(toValue, 'MassConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <MassConverterMetaTags />
      <div className='title'>
        <h1>Mass Converter</h1>
        <p>
          <label htmlFor='content'>
            Mass Unit Conversion - converts carats ,cental, Earth masses, kilograms
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default MassConverter;
