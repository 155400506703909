import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BoldTextGenerator from '../pages/Transformations/BoldTextGenerator/BoldTextGenerator';
import BubbleTextGenerator from '../pages/Transformations/BubbleTextGenerator/BubbleTextGenerator';
import TitleCaseConverter from '../pages/Transformations/TitleCaseConverter/TitleCaseConverter';
import ItalicTextConverter from '../pages/Transformations/ItalicTextConverter/ItalicTextConverter';
import SentenceCaseConverter from '../pages/Transformations/SentenceCaseConverter/SentenceCaseConverter';
import WhitespaceRemover from '../pages/Transformations/WhitespaceRemover/WhitespaceRemover';
import SuperscriptGenerator from '../pages/Transformations/SuperscriptGenerator/SuperscriptGenerator';
import WideTextGenerator from '../pages/Transformations/WideTextGenerator/WideTextGenerator';
import WingdingsConverter from '../pages/Transformations/WingdingsConverter/WingdingsConverter';
import UpsideDownTextGenerator from '../pages/Transformations/UpsideDownTextGenerator/UpsideDownTextGenerator';
import StrikethroughTextGenerator from '../pages/Transformations/StrikethroughTextGenerator/StrikethroughTextGenerator';
import ReverseTextGenerator from '../pages/Transformations/ReverseTextGenerator/ReverseTextGenerator';
import MirrorTextGenerator from '../pages/Transformations/MirrorTextGenerator/MirrorTextGenerator';
import DuplicateLineRemover from '../pages/Transformations/DuplicateLineRemover/DuplicateLineRemover';
import UnderlineTextGenerator from '../pages/Transformations/UnderlineTextGenerator/UnderlineTextGenerator';
import SmallTextGenerator from '../pages/Transformations/SmallTextGenerator/SmallTextGenerator';
import FacebookFontGenerator from '../pages/Transformations/FacebookFontGenerator/FacebookFontGenerator';
import CursedTextTool from '../pages/Transformations/CursedTextTool/CursedTextTool';
import UnicodeTextConverter from '../pages/Transformations/UnicodeTextConverter/UnicodeTextConverter';
import ZalgoGlitchTextGenerator from '../pages/Transformations/ZalgoGlitchTextGenerator/ZalgoGlitchTextGenerator';
import JsonEscapeTextGenerator from '../pages/Transformations/JsonEscapeTextGenerator/JsonEscapeTextGenerator';

const TransformationsRouter = () => {
  return (
    <Routes>
      <Route path="/bold-text-converter/" exact element={<BoldTextGenerator />} />
      <Route path="/bubble-text-generator/" exact element={<BubbleTextGenerator />} />
      <Route path="/title-case-converter/" exact element={<TitleCaseConverter />} />
      <Route path="/italic-text-converter/" exact element={<ItalicTextConverter />} />
      <Route path="/sentence-case/" exact element={<SentenceCaseConverter />} />
      <Route path="/whitespace-remover/" exact element={<WhitespaceRemover />} />
      <Route path="/superscript-generator/" exact element={<SuperscriptGenerator />} />
      <Route path="/wide-text-generator/" exact element={<WideTextGenerator />} />
      <Route path="/wingdings-converter/" exact element={<WingdingsConverter />} />
      <Route path="/upside-down-text-generator/" exact element={<UpsideDownTextGenerator />} />
      <Route path="/strikethrough-text-generator/" exact element={<StrikethroughTextGenerator />} />
      <Route path="/reverse-text-generator/" exact element={<ReverseTextGenerator />} />
      <Route path="/mirror-text-generator/" exact element={<MirrorTextGenerator />} />
      <Route path="/duplicate-line-remover/" exact element={<DuplicateLineRemover />} />
      <Route path="/underline-text-generator/" exact element={<UnderlineTextGenerator />} />
      <Route path="/small-text-generator/" exact element={<SmallTextGenerator />} />
      <Route path="/facebook-font-generator/" exact element={<FacebookFontGenerator />} />
      <Route path="/cursed-text-converter/" exact element={<CursedTextTool />} />
      <Route path="/unicode-text-converter/" exact element={<UnicodeTextConverter />} />
      <Route path="/zalgo-glitch-text-converter/" exact element={<ZalgoGlitchTextGenerator />} />
      <Route path="/json-escape/" exact element={<JsonEscapeTextGenerator />} />
    </Routes>
  );
};

export default TransformationsRouter;
