import React from 'react';
import { Container } from './About.style';
import HomeMetaTags from '../../components/MetaTag/HomeMetaTags'

const About = () => {

  return (
    <Container>
      <HomeMetaTags />
      
      <h1>About Case Modify</h1>
      <div className="description">
        <p>Case Modify is crafted as a freely accessible online text manipulator, offering a resource for enhanced efficiency, productivity gains, and a touch of amusement. Whether you've unintentionally composed your text entirely in uppercase or seek a swift adjustment of capitalization for titles, Case Modify empowers you to promptly rectify errors and generate various outcomes through its versatile tools.</p>
        <p>Additionally, there's a selection of converters to delve into, including binary code and Morse code translators. These tools enable you to input regular text and seamlessly convert it into the respective codes you need.</p>
        <p>Employing text manipulators can enhance the visibility of your text. Whether it's for social media endeavors or to draw attention to an advertisement, these tools serve as an excellent solution for addressing such matters.</p>
        <p>If you have any recommendations for new tools that could be beneficial and should be incorporated, please don't hesitate to <a href="mailto:contact@casemodify.com">contact us</a>. We're more than willing to assist you.</p>
      </div>
    </Container>
  )
};

export default About;