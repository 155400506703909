import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './DuplicateLineRemover.style';
import { removeDuplicateLines } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import DuplicateLineRemoverMetaTags from '../../../components/MetaTag/DuplicateLineRemoverMetaTags';

const DuplicateLineRemover = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    const newTransformedText = removeDuplicateLines(inputText);
    setTransformedText(newTransformedText);
  };

  const handleDownload = () => {
    const normalizedText = transformedText.replace(/<br\s*\/?>/g, '\n');
    downloadText(normalizedText, 'duplicateLineRemover.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(transformedText);

     // Set showMessage to true when the button is clicked
     setShowMessage(true);

     // Use setTimeout to set showMessage back to false after 5 seconds
     setTimeout(() => {
       setShowMessage(false);
     }, 5000);
  };

  return (
    <Container>
      <DuplicateLineRemoverMetaTags />
      
      <div className="title">
        <h1>Duplicate Line Remover</h1>
        <p>
          <label>
            Whether you're dealing with large data sets, cleaning up lists, or just need to eliminate redundant information, our tool is here to simplify your task. Simply paste your text and let our tool do the rest!
          </label>
        </p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <p dangerouslySetInnerHTML={{ __html: transformedText }} />
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default DuplicateLineRemover;
