import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './ForceConverter.style';
import ForceConverterMetaTags from '../../../components/MetaTag/ForceConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const ForceConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'attonewton': { toNewton: value => value * 1e-18, fromNewton: value => value / 1e-18 },
    'centinewton': { toNewton: value => value * 0.01, fromNewton: value => value / 0.01 },
    'decigram-force': { toNewton: value => value * 0.00980665, fromNewton: value => value / 0.00980665 },
    'decinewton': { toNewton: value => value * 0.1, fromNewton: value => value / 0.1 },
    'dekagram-force': { toNewton: value => value * 0.980665, fromNewton: value => value / 0.980665 },
    'dekanewton': { toNewton: value => value * 10, fromNewton: value => value / 10 },
    'dyne (dyn)': { toNewton: value => value * 1e-5, fromNewton: value => value / 1e-5 },
    'exanewton': { toNewton: value => value * 1e18, fromNewton: value => value / 1e18 },
    'femtonewton': { toNewton: value => value * 1e-15, fromNewton: value => value / 1e-15 },
    'giganewton (GN)': { toNewton: value => value * 1e9, fromNewton: value => value / 1e9 },
    'gram-force': { toNewton: value => value * 0.00980665, fromNewton: value => value / 0.00980665 },
    'hectonewton': { toNewton: value => value * 100, fromNewton: value => value / 100 },
    'joule / meter (J/m)': { toNewton: value => value, fromNewton: value => value },
    'kilogram-force (kgf)': { toNewton: value => value * 9.80665, fromNewton: value => value / 9.80665 },
    'kilonewton (kN)': { toNewton: value => value * 1000, fromNewton: value => value / 1000 },
    'kilopond (kp)': { toNewton: value => value * 9.80665, fromNewton: value => value / 9.80665 },
    'kip (kip)': { toNewton: value => value * 4448.22, fromNewton: value => value / 4448.22 },
    'meganewton': { toNewton: value => value * 1e6, fromNewton: value => value / 1e6 },
    'megapond': { toNewton: value => value * 9806.65, fromNewton: value => value / 9806.65 },
    'micronewton (μN)': { toNewton: value => value * 1e-6, fromNewton: value => value / 1e-6 },
    'millinewton (mN)': { toNewton: value => value * 1e-3, fromNewton: value => value / 1e-3 },
    'nanonewton (nN)': { toNewton: value => value * 1e-9, fromNewton: value => value / 1e-9 },
    'newton (N)': { toNewton: value => value, fromNewton: value => value },
    'ounce-force (ozf)': { toNewton: value => value * 0.2780139, fromNewton: value => value / 0.2780139 },
    'petanewton': { toNewton: value => value * 1e15, fromNewton: value => value / 1e15 },
    'piconewton': { toNewton: value => value * 1e-12, fromNewton: value => value / 1e-12 },
    'pond': { toNewton: value => value * 0.00980665, fromNewton: value => value / 0.00980665 },
    'pound-force (lbf)': { toNewton: value => value * 4.448222, fromNewton: value => value / 4.448222 },
    'poundal (pdl)': { toNewton: value => value * 0.138255, fromNewton: value => value / 0.138255 },
    'sthene (sn)': { toNewton: value => value * 1000, fromNewton: value => value / 1000 },
    'teranewton': { toNewton: value => value * 1e12, fromNewton: value => value / 1e12 },
    'ton-force (long)(tnf)': { toNewton: value => value * 9964.016, fromNewton: value => value / 9964.016 },
    'ton-force (metric)(tnf)': { toNewton: value => value * 9806.65, fromNewton: value => value / 9806.65 },
    'ton-force (short)(tnf)': { toNewton: value => value * 8896.443, fromNewton: value => value / 8896.443 },
    'yoctonewton': { toNewton: value => value * 1e-24, fromNewton: value => value / 1e-24 },
    'yottanewton': { toNewton: value => value * 1e24, fromNewton: value => value / 1e24 },
    'zeptonewton': { toNewton: value => value * 1e-21, fromNewton: value => value / 1e-21 },
    'zettanewton': { toNewton: value => value * 1e21, fromNewton: value => value / 1e21 },
  };

  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const newValue = convertForce(parseFloat(value), fromUnit, toUnit);
    setToValue(newValue.toExponential(0));
  };

  const convertForce = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const newValue = units[fromUnit].toNewton(value);
    return units[toUnit].fromNewton(newValue);
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const newValue = convertForce(parseFloat(fromValue), unit, toUnit);
    setToValue(newValue.toExponential(0));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const newValue = convertForce(parseFloat(fromValue), fromUnit, unit);
    setToValue(newValue.toExponential(0));
  };
  const handleDownload = () => {
    downloadText(toValue, 'ForceConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <ForceConverterMetaTags />
      <div className='title'>
        <h1>Force Converter</h1>
        <p>
          <label htmlFor='content'>
              Force Unit Conversion - converts dyne, gram-force, newton, pond...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default ForceConverter;
