import React from 'react';
import { Helmet } from 'react-helmet';

const CursedTextMetaTags = () => {
  return (
    <Helmet>
      <title>Cursed Text Tool | Cursed Generator | Cursed Font | Case Modify</title>
      <meta name="description" content="Try our cursed text generator and create slanting text that suits your profile and content! If you're looking for action-packed posts, then this is the perfect tool for you." />

      <meta name="apple-mobile-web-app-title" content="Cursed Text Tool" />
      <meta name="application-name" content="Cursed Text Tool" />
      <meta name="msapplication-tooltip" content="Cursed Text Tool" />

      <link rel="canonical" href="https://casemodify.com/cursed-text-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/cursed-text-converter/" />
      <meta property="og:title" content="Cursed Text Generator (Copy and Paste) | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Try our cursed text generator and create slanting text that suits your profile and content! If you're looking for action-packed posts, then this is the perfect tool for you." />
    </Helmet>
  );
};

export default CursedTextMetaTags;
