import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './PressureConverter.style';
import PressureConverterMetaTags from '../../../components/MetaTag/PressureConverterMetaTags.jsx';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const PressureConverter = () => {
  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'atmospheres': {
      toPascals: (value) => value * 101325,
      fromPascals: (value) => value / 101325
    },
    'bars': {
      toPascals: (value) => value * 100000,
      fromPascals: (value) => value / 100000
    },
    'centimeters mercury': {
      toPascals: (value) => value * 1333.223874,
      fromPascals: (value) => value / 1333.223874
    },
    'centimeters water': {
      toPascals: (value) => value * 98.0665,
      fromPascals: (value) => value / 98.0665
    },
    'feet of water': {
      toPascals: (value) => value * 2989.0669,
      fromPascals: (value) => value / 2989.0669
    },
    'hectopascals [hPa]': {
      toPascals: (value) => value * 100,
      fromPascals: (value) => value / 100
    },
    'inches of water': {
      toPascals: (value) => value * 249.08891,
      fromPascals: (value) => value / 249.08891
    },
    'inches of mercury': {
      toPascals: (value) => value * 3386.389,
      fromPascals: (value) => value / 3386.389
    },
    'kilogram-forces/sq.centimeter': {
      toPascals: (value) => value * 98066.5,
      fromPascals: (value) => value / 98066.5
    },
    'kilogram-forces/sq.meter': {
      toPascals: (value) => value * 9.80665,
      fromPascals: (value) => value / 9.80665
    },
    'kilonewtons/sq.meter': {
      toPascals: (value) => value * 1000,
      fromPascals: (value) => value / 1000
    },
    'kilonewtons/sq.millimeter': {
      toPascals: (value) => value * 1e6,
      fromPascals: (value) => value / 1e6
    },
    'kilopascals [kPa]': {
      toPascals: (value) => value * 1000,
      fromPascals: (value) => value / 1000
    },
    'kips/sq.inch': {
      toPascals: (value) => value * 6894757,
      fromPascals: (value) => value / 6894757
    },
    'meganewtons/sq.meter': {
      toPascals: (value) => value * 1e6,
      fromPascals: (value) => value / 1e6
    },
    'meganewtons/sq.millimeter': {
      toPascals: (value) => value * 1e9,
      fromPascals: (value) => value / 1e9
    },
    'meters of water': {
      toPascals: (value) => value * 9806.65,
      fromPascals: (value) => value / 9806.65
    },
    'millibars': {
      toPascals: (value) => value * 100,
      fromPascals: (value) => value / 100
    },
    'millimeters of mercury': {
      toPascals: (value) => value * 133.3223874,
      fromPascals: (value) => value / 133.3223874
    },
    'millimeters of water': {
      toPascals: (value) => value * 9.80665,
      fromPascals: (value) => value / 9.80665
    },
    'newtons/sq.centimeter': {
      toPascals: (value) => value * 10000,
      fromPascals: (value) => value / 10000
    },
    'newtons/sq.meter': {
      toPascals: (value) => value,
      fromPascals: (value) => value
    },
    'newtons/sq.millimeter': {
      toPascals: (value) => value * 1e6,
      fromPascals: (value) => value / 1e6
    },
    'pascals [Pa]': {
      toPascals: (value) => value,
      fromPascals: (value) => value
    },
    'pounds-force/sq.foot': {
      toPascals: (value) => value * 47.88025898,
      fromPascals: (value) => value / 47.88025898
    },
    'pounds-force/sq.inch [psi]': {
      toPascals: (value) => value * 6894.757293,
      fromPascals: (value) => value / 6894.757293
    },
    'poundals/sq.foot': {
      toPascals: (value) => value * 1.488163943,
      fromPascals: (value) => value / 1.488163943
    },
    'tons (UK)-force/sq.foot': {
      toPascals: (value) => value * 47900.51758,
      fromPascals: (value) => value / 47900.51758
    },
    'tons (UK)-force/sq.inch': {
      toPascals: (value) => value * 6894757,
      fromPascals: (value) => value / 6894757
    },
    'tons (US)-force/sq.foot': {
      toPascals: (value) => value * 47880.25898,
      fromPascals: (value) => value / 47880.25898
    },
    'tons (US)-force/sq.inch': {
      toPascals: (value) => value * 6894757,
      fromPascals: (value) => value / 6894757
    },
    'tonnes-force/sq.cm': {
      toPascals: (value) => value * 9806650,
      fromPascals: (value) => value / 9806650
    },
    'tonnes-force/sq.meter': {
      toPascals: (value) => value * 9806.65,
      fromPascals: (value) => value / 9806.65
    },
    'torr (mm Hg 0°C)': {
      toPascals: (value) => value * 133.3223684,
      fromPascals: (value) => value / 133.3223684
    }
  };;

  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  const convertPressure = (value, fromUnit, toUnit) => {
    const fromUnitToPascals = units[fromUnit].toPascals(value);
    return units[toUnit].fromPascals(fromUnitToPascals);
  };

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertPressure(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertPressure(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertPressure(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleDownload = () => {
    downloadText(toValue, 'PressureConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <PressureConverterMetaTags />
      <div className='title'>
        <h1>Pressure Converter</h1>
        <p>
          <label htmlFor='content'>
            Pressure Unit Conversion - converts pascal, bar, millibar, torr, pounds per square inch...
          </label>
        </p>
      </div>
      {showMessage && <div className='success-message'>Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
        <div className='cols'>
          <div className='col'>
            <p>
              <span className='target'>From:</span> {fromUnit}
            </p>
            <input
              className='input'
              type='number'
              value={fromValue}
              onChange={handleFromValueChange}
              placeholder='Enter value'
            />
            <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15}>
              {Object.keys(units).map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
          <div className='col'>
            <p>
              <span className='target'>To: </span> {toUnit}
            </p>
            <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
            <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15}>
              {Object.keys(units).map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='btns'>
        <button className='btn' onClick={handleDownload}>
          Download Text
        </button>
        <button className='btn' onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </button>
        <a href='https://www.ko-fi.com/casemodify' className='btn-coffee' target='_blank' rel='noopener noreferrer'>
          <CoffeeCup src={'/icons/coffee-cup.png'} alt='Coffee Cup' /> Buy me a Coffee
        </a>
      </div>

      <div className='media'>
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default PressureConverter;
