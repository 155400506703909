import React from 'react';
import { Helmet } from 'react-helmet';

const WideTextMetaTags = () => {
  return (
    <Helmet>
      <title>Vaporwave Text Generator | Wide Text Generator | Convert Case | Case Modify</title>
      <meta
        name='description'
        content='Want to Widen Your Text? Use Our Free Fast Online Vaporwave Text Generator to Convert Your Standard Text Into an Aesthetic Font.'
      />

      <meta name='apple-mobile-web-app-title' content='Wide Text Generator ' />
      <meta name='application-name' content='Wide Text Generator ' />
      <meta name='msapplication-tooltip' content='Wide Text Generator ' />

      <link rel='canonical' href='https://casemodify.com/wide-text-generator/' />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@casemodify' />
      <meta name='twitter:creator' content='@casemodify' />

      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://casemodify.com/bold-text-converter/' />
      <meta property='og:title' content='Wide Text Generator | Case Modify' />
      <meta property='og:image' content='https://casemodify.com/apple-touch-icon.png' />
      <meta
        property='og:description'
        content='Looking to Make Your Text Bold? Convert Your Text Into Bold With the Handy Online Free and Quick to Use Wide Text Generator .'
      />
    </Helmet>
  );
};

export default WideTextMetaTags;
