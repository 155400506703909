import React from 'react';
import { Helmet } from 'react-helmet';

const WingdingsMetaTags = () => {
  return (
    <Helmet>
      <title>Wingdings Converter | Convert Your Text to Wingdings | Convert Case | Case Modify</title>
      <meta name="description" content="Use our free Wingdings Converter to transform your text into Wingdings symbols, or decode Wingdings into understandable text." />

      <meta name="apple-mobile-web-app-title" content="Wingdings Converter " />
      <meta name="application-name" content="Wingdings Converter " />
      <meta name="msapplication-tooltip" content="Wingdings Converter " />

      <link rel="canonical" href="https://casemodify.com/wingdings-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/wingdings-converter/" />
      <meta property="og:title" content="Wingdings Converter | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Use our free Wingdings Converter to transform your text into Wingdings symbols, or decode Wingdings into understandable text." />
    </Helmet>
  );
};

export default WingdingsMetaTags;
