import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto 15px;
  width: 100%;
  max-width: 1200px;
  background: #e0e0e0;
  border-top: 3px solid #c2c2c2;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  h1, h2 {
    font-size: 24px;
    font-weight: 700
  }
  .description {
    margin-bottom: 200px;
  }
   
`;