import React from 'react';
import { Helmet } from 'react-helmet';

const BytesConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Bytes Converter | Case Modify</title>
      <meta name="description" content="Convert bits/bytes to and from kb,mb,gb,tb,pd etc." />
      <meta name="Keywords" content="bits,bytes,kilobytes,megabytes,gigabytes,petabytes" />

      <meta name="apple-mobile-web-app-title" content="Bytes Converter" />
      <meta name="application-name" content="Bytes Converter" />
      <meta name="msapplication-tooltip" content="Bytes Converter" />

      <link rel="canonical" href="https://casemodify.com/bytes-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/bytes-converter/" />
      <meta property="og:title" content="Bytes Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Bytes Unit Conversion - converts bits,bytes,kilobytes,megabytes,gigabytes,petabytes" />
    </Helmet>
  );
};

export default BytesConverterMetaTags;
