import React from 'react';
import { Helmet } from 'react-helmet';

const DuplicateLineRemoverMetaTags = () => {
  return (
    <Helmet>
      <title>Online Duplicate Line Remover | Case Modify</title>
      <meta name="description" content="Effortlessly remove duplicate lines from your text with our free online tool." />

      <meta name="apple-mobile-web-app-title" content="Online Duplicate Line Remover " />
      <meta name="application-name" content="Online Duplicate Line Remover" />
      <meta name="msapplication-tooltip" content="Online Duplicate Line Remover" />

      <link rel="canonical" href="https://casemodify.com/duplicate-line-remover/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/duplicate-line-remover/" />
      <meta property="og:title" content="Online Duplicate Line Remover | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Effortlessly remove duplicate lines from your text with our free online tool." />
    </Helmet>
  );
};

export default DuplicateLineRemoverMetaTags;
