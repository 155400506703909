import React from 'react';
import { Container } from './PrivacyPolicy.style';
import HomeMetaTags from '../../components/MetaTag/HomeMetaTags'

const About = () => {

  return (
    <Container>
      <HomeMetaTags />

      <h1>Privacy Policy</h1>
      <div className="description">
        <p>
          <strong>Your Privacy</strong><br /><br />
          We value your privacy. In order to safeguard it, we offer this notice to elucidate our online information practices and to empower you with choices regarding the collection and utilization of your information. To ensure easy access, we make this notice readily available on our homepage and at every instance where personally identifiable information may be requested.
        </p>
        <p>
          <strong>Google Ads and the DoubleClick DART Cookie</strong><br /><br />
          As a third-party advertising vendor, Google employs cookies to display ads on this site. The utilization of DART cookies allows Google to deliver advertisements to visitors based on their interactions with this website and other sites on the internet.
        </p>
        <p>
        If you wish to opt out of DART cookies, please visit Google's ad and content network privacy policy at the following URL: <a href="https://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>. The tracking of users through DART cookie mechanisms is governed by Google's own privacy policies.
        </p>
        <p>
          Third-party ad servers or ad networks may utilize cookies to monitor users' activities on this website, assessing advertisement effectiveness, and for purposes outlined in their respective privacy policies. CaseModify.com does not possess access to or control over these cookies employed by third-party advertisers.
        </p>
        <p>
          <strong>Collection of Personal Information</strong><br /><br />
          When visiting <a href="https://www.casemodify.com">CaseModify.com</a>, we don't record any user data'.
        </p>
        <p>
          <strong>Links to third party Websites</strong><br /><br />
          No, third party link is added in <a href="https://www.casemodify.com">CaseModify.com</a>.
        </p>
        <p>
          <strong>Changes to this Privacy Statement</strong><br /><br />
          The contents of this statement may be altered at any time, at our discretion.
        </p><br /><br /><br /><br />
        <p>
          If you have any questions regarding the privacy policy of CaseModify.com then you may <a href="mailto:contact@casemodify.com">contact us</a>.
        </p>
      </div>
    </Container>
  )
};

export default About;