import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './VolumeConverter.style';
import VolumeConverterMetaTags from '../../../components/MetaTag/VolumeConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const VolumeConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'barrel (petroleum) (bbl, bo)': { toLiter: value => value * 158.987, fromLiter: value => value / 158.987 },
    'bushel (UK) (bu)': { toLiter: value => value * 36.3687, fromLiter: value => value / 36.3687 },
    'bushel (US dry) (bu)': { toLiter: value => value * 35.2391, fromLiter: value => value / 35.2391 },
    'centiliter (cl)': { toLiter: value => value * 0.01, fromLiter: value => value / 0.01 },
    'cubic centimeter (cc, cm^3)': { toLiter: value => value * 0.001, fromLiter: value => value / 0.001 },
    'cubic decimeter (dm^3)': { toLiter: value => value * 1, fromLiter: value => value / 1 },
    'cubic foot (ft^3, cu ft)': { toLiter: value => value * 28.3168, fromLiter: value => value / 28.3168 },
    'cubic inch (in^3, cu in)': { toLiter: value => value * 0.0163871, fromLiter: value => value / 0.0163871 },
    'cubic meter (m^3)': { toLiter: value => value * 1000, fromLiter: value => value / 1000 },
    'cubic millimeter (mm^3)': { toLiter: value => value * 1e-6, fromLiter: value => value / 1e-6 },
    'cubic yard (yd^3)': { toLiter: value => value * 764.555, fromLiter: value => value / 764.555 },
    'dekaliter (dal)': { toLiter: value => value * 10, fromLiter: value => value / 10 },
    'fluid dram (fl dr)': { toLiter: value => value * 0.00369669, fromLiter: value => value / 0.00369669 },
    'fluid ounce (fl oz)': { toLiter: value => value * 0.0295735, fromLiter: value => value / 0.0295735 },
    'fluid ounce (UK) (fl oz)': { toLiter: value => value * 0.0284131, fromLiter: value => value / 0.0284131 },
    'gallon (fluid) (gal)': { toLiter: value => value * 3.78541, fromLiter: value => value / 3.78541 },
    'gallon (UK) (gal)': { toLiter: value => value * 4.54609, fromLiter: value => value / 4.54609 },
    'gill (gi)': { toLiter: value => value * 0.118294, fromLiter: value => value / 0.118294 },
    'hectoliter (hl)': { toLiter: value => value * 100, fromLiter: value => value / 100 },
    'liter (l)': { toLiter: value => value, fromLiter: value => value },
    'microliter (μl)': { toLiter: value => value * 1e-6, fromLiter: value => value / 1e-6 },
    'milliliter (ml)': { toLiter: value => value * 0.001, fromLiter: value => value / 0.001 },
    'minim (min)': { toLiter: value => value * 0.0000616115, fromLiter: value => value / 0.0000616115 },
    'peck (US dry) (pk)': { toLiter: value => value * 8.80977, fromLiter: value => value / 8.80977 },
    'pint (fluid) (pt)': { toLiter: value => value * 0.473176, fromLiter: value => value / 0.473176 },
    'pint (UK) (pt)': { toLiter: value => value * 0.568261, fromLiter: value => value / 0.568261 },
    'pint (US dry) (pt)': { toLiter: value => value * 0.55061, fromLiter: value => value / 0.55061 },
    'quart (fluid) (qt)': { toLiter: value => value * 0.946353, fromLiter: value => value / 0.946353 },
    'quart (UK) (qt)': { toLiter: value => value * 1.13652, fromLiter: value => value / 1.13652 },
    'quart (US dry) (qt)': { toLiter: value => value * 1.10122, fromLiter: value => value / 1.10122 },
  };

  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");


  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertVolume(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertVolume(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertVolume(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const convertVolume = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const literValue = units[fromUnit].toLiter(value);
    return units[toUnit].fromLiter(literValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'VolumeConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <VolumeConverterMetaTags />
      <div className='title'>
        <h1>Volume Converter</h1>
        <p>
          <label htmlFor='content'>
            Volume Unit Conversion - converts liter, cubic meter, gallon, quart, pint, bushel, barrel etc
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default VolumeConverter;
