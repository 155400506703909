import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default PrivacyPolicyRouter;
