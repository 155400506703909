import React from 'react';
import { Helmet } from 'react-helmet';

const SpeedConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Speed Converter | Case Modify</title>
      <meta name="description" content="Convert speed to and from kilometer, centimeter,feet per hours/minutes etc." />
      <meta name="Keywords" content="Convert speed to and from kilometer, centimeter,feet per hours/minutes etc" />

      <meta name="apple-mobile-web-app-title" content="Speed Converter" />
      <meta name="application-name" content="Speed Converter" />
      <meta name="msapplication-tooltip" content="Speed Converter" />

      <link rel="canonical" href="https://casemodify.com/speed-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/speed-converter/" />
      <meta property="og:title" content="Speed Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Convert speed to and from kilometer, centimeter,feet per hours/minutes etc." />
    </Helmet>
  );
};

export default SpeedConverterMetaTags;
