import React from 'react';
import { Helmet } from 'react-helmet';

const WeightConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Weight Converter | Case Modify</title>
      <meta name="description" content="Best Weight Conversion Online tool to Convert Weight and Supports all Units. i.e kilogram to pound, pound to ounce" />
      <meta name="Keywords" content="convert kilogram to pound, pound to ounce" />

      <meta name="apple-mobile-web-app-title" content="Weight Converter" />
      <meta name="application-name" content="Weight Converter" />
      <meta name="msapplication-tooltip" content="Weight Converter" />

      <link rel="canonical" href="https://casemodify.com/weight-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/weight-converter/" />
      <meta property="og:title" content="Weight Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Best Weight Conversion Online tool to Convert Weight and Supports all Units. i.e kilogram to pound, pound to ounce" />
    </Helmet>
  );
};

export default WeightConverterMetaTags;
