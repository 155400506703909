import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './SpeedConverter.style';
import SpeedConverterMetaTags from '../../../components/MetaTag/SpeedConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const SpeedConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'centimeters/minute': { toMetersPerSecond: value => value * 0.000166667, fromMetersPerSecond: value => value / 0.000166667 },
    'centimeters/second': { toMetersPerSecond: value => value * 0.01, fromMetersPerSecond: value => value / 0.01 },
    'feet/hour': { toMetersPerSecond: value => value * 0.0000846668, fromMetersPerSecond: value => value / 0.0000846668 },
    'feet/minute': { toMetersPerSecond: value => value * 0.00508, fromMetersPerSecond: value => value / 0.00508 },
    'feet/second': { toMetersPerSecond: value => value * 0.3048, fromMetersPerSecond: value => value / 0.3048 },
    'inches/minute': { toMetersPerSecond: value => value * 0.000423333, fromMetersPerSecond: value => value / 0.000423333 },
    'inches/second': { toMetersPerSecond: value => value * 0.0254, fromMetersPerSecond: value => value / 0.0254 },
    'kilometers/hour': { toMetersPerSecond: value => value * 0.277778, fromMetersPerSecond: value => value / 0.277778 },
    'kilometers/second': { toMetersPerSecond: value => value * 1000, fromMetersPerSecond: value => value / 1000 },
    knots: { toMetersPerSecond: value => value * 0.514444, fromMetersPerSecond: value => value / 0.514444 },
    'Mach number (ISA/Sea level)': { toMetersPerSecond: value => value * 343, fromMetersPerSecond: value => value / 343 },
    'meters/hour': { toMetersPerSecond: value => value * 0.000277778, fromMetersPerSecond: value => value / 0.000277778 },
    'meters/minute': { toMetersPerSecond: value => value * 0.0166667, fromMetersPerSecond: value => value / 0.0166667 },
    'meters/second [m/s]': { toMetersPerSecond: value => value, fromMetersPerSecond: value => value },
    'miles/hour': { toMetersPerSecond: value => value * 0.44704, fromMetersPerSecond: value => value / 0.44704 },
    'miles/minute': { toMetersPerSecond: value => value * 26.8224, fromMetersPerSecond: value => value / 26.8224 },
    'miles/second': { toMetersPerSecond: value => value * 1609.34, fromMetersPerSecond: value => value / 1609.34 },
    'nautical miles/hour': { toMetersPerSecond: value => value * 0.514444, fromMetersPerSecond: value => value / 0.514444 },
    'Nm/24hr (Volvo Ocean Race)': { toMetersPerSecond: value => value * 0.0059492, fromMetersPerSecond: value => value / 0.0059492 },
    'speed of light': { toMetersPerSecond: value => value * 299792458, fromMetersPerSecond: value => value / 299792458 },
    'yards/hour': { toMetersPerSecond: value => value * 0.000254, fromMetersPerSecond: value => value / 0.000254 },
    'yards/minute': { toMetersPerSecond: value => value * 0.01524, fromMetersPerSecond: value => value / 0.01524 },
    'yards/second': { toMetersPerSecond: value => value * 0.9144, fromMetersPerSecond: value => value / 0.9144 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertSpeed(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertSpeed(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertSpeed(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(8));
  };

  const convertSpeed = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const metersPerSecondValue = units[fromUnit].toMetersPerSecond(value);
    return units[toUnit].fromMetersPerSecond(metersPerSecondValue);
  };


  const handleDownload = () => {
    downloadText(toValue, 'SpeedConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <SpeedConverterMetaTags />
      <div className='title'>
        <h1>Speed Converter</h1>
        <p>
          <label htmlFor='content'>
            Convert speed to and from kilometer, centimeter,feet per hours/minutes etc...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default SpeedConverter;
