import React from 'react';
import { Helmet } from 'react-helmet';

const AngleConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Angle Converter | Case Modify</title>
      <meta name="description" content="Convert angle to and from degreen, mil,circle etc." />
      <meta name="Keywords" content="Convert angle to and from degreen, mil,circle etc." />

      <meta name="apple-mobile-web-app-title" content="Angle Converter" />
      <meta name="application-name" content="Angle Converter" />
      <meta name="msapplication-tooltip" content="Angle Converter" />

      <link rel="canonical" href="https://casemodify.com/angle-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/angle-converter/" />
      <meta property="og:title" content="Angle Converter - Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Angle Unit Conversion - Convert angle to and from degreen, mil,circle etc." />
    </Helmet>
  );
};

export default AngleConverterMetaTags;
