import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './AstronomicalConverter.style';
import EnergyConverterMetaTags from '../../../components/MetaTag/EnergyConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const AstronomicalConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const units = {
    'astronomical unit [1996]': { toMeter: value => value * 149597870700, fromMeter: value => value / 149597870700 },
    'kilometer': { toMeter: value => value * 1000, fromMeter: value => value / 1000 },
    'light second': { toMeter: value => value * 299792458, fromMeter: value => value / 299792458 },
    'light minute': { toMeter: value => value * 299792458 * 60, fromMeter: value => value / (299792458 * 60) },
    'light hour': { toMeter: value => value * 299792458 * 3600, fromMeter: value => value / (299792458 * 3600) },
    'light day': { toMeter: value => value * 299792458 * 86400, fromMeter: value => value / (299792458 * 86400) },
    'light year [Julian]': { toMeter: value => value * 365.25 * 24 * 3600 * 299792458, fromMeter: value => value / (365.25 * 24 * 3600 * 299792458) },
    'light year [tropical]': { toMeter: value => value * 365.24219 * 24 * 3600 * 299792458, fromMeter: value => value / (365.24219 * 24 * 3600 * 299792458) },
    'light year [traditional]': { toMeter: value => value * 365.25 * 24 * 3600 * 299792458, fromMeter: value => value / (365.25 * 24 * 3600 * 299792458) },
    'parsec': { toMeter: value => value * 3.0856776e16, fromMeter: value => value / 3.0856776e16 },
    'meter': { toMeter: value => value, fromMeter: value => value },
    'mile': { toMeter: value => value * 1609.344, fromMeter: value => value / 1609.344 },
  };
  
  const [fromUnit, setFromUnit] = useState(Object.keys(units)[0]);
  const [toUnit, setToUnit] = useState(Object.keys(units)[0]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertDistance(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertDistance(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertDistance(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const meterValue = units[fromUnit].toMeter(value);
    return units[toUnit].fromMeter(meterValue);
  };

  const handleDownload = () => {
    downloadText(toValue, 'AstronomicalConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <EnergyConverterMetaTags />
      <div className='title'>
        <h1>AstronomicalConverter</h1>
        <p>
          <label htmlFor='content'>
            Astronomical Unit Conversion - Convert between several astronomical units.
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default AstronomicalConverter;
