import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto 15px;
    width: 100%;
    max-width: 1200px;
    background: #e0e0e0;
    border-top: 3px solid #c2c2c2;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    .title {
      margin-bottom: 60px;
    }

    h1, h2 {
      font-size: 24px;
      font-weight: 700
    }

    h1,h2,p,.row,.feature,.col,.message {
        margin-top: 0;
        margin-bottom: 10px
    }

    .row {
        width:100%;
        overflow: hidden;
      }

    .row .right {
      float: right;
      padding-top: 5px
    }

  textarea {
    border-top:none;
    color: #000000;
      width: 100%;
      height: 100px;
      min-height: 22vh;
      border: 0;
      border-top: 3px solid #c2c2c2;
      display: block;
      overflow: auto;
      resize: vertical;
      padding: 10px;
    }

    .actions {
      margin-bottom: 5px;
      min-height: 64px;
    }

    .actions .btn {
      border-top: 0;
      margin-bottom: 5px
    }

    .btn,.tabs a,.tabs span {
      background: #c2c2c2;
      color: #111;
      border: 0;
      padding: 5px 8px;
      font-weight: 700;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      padding: 5px 8px;
      margin-right: 2px;
      &:hover {
        background-color: #777;
      }
    }
    .btn-coffee,.tabs a,.tabs span {
      background: #c2c2c2;
      color: #111;
      border: 0;
      padding: 5px 8px;
      font-weight: 700;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      padding: 5px 8px;
      margin-top: 2px;
    }

    .row-counts {
      width: 100%;
      overflow: hidden;
    }
    .media {
      margin-bottom: 5px;
      margin-top: 200px;
      text-align: center;
    }
    .success-message {
      background: #eee;
      border-top: 3px solid #208000;
      padding: 5px 10px;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto
    }
`;

export const CoffeeCup = styled.img`
  height: 12px;
  width: 12px;
`;
