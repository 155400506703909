import React from 'react';
import { Helmet } from 'react-helmet';

const PressureConverterMetaTags = () => {
  return (
    <Helmet>
      <title>Pressure Converter | Case Modify</title>
      <meta
        name='description'
        content='Pressure Unit Conversion - converts pascal, bar, millibar, torr, pounds per square inch...'
      />
      <meta name='Keywords' content='converts pascal, bar, millibar, torr, pounds per square inch' />

      <meta name='apple-mobile-web-app-title' content='Pressure Converter' />
      <meta name='application-name' content='Pressure Converter' />
      <meta name='msapplication-tooltip' content='Pressure Converter' />

      <link rel='canonical' href='https://casemodify.com/pressure-converter/' />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@casemodify' />
      <meta name='twitter:creator' content='@casemodify' />

      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://casemodify.com/pressure-converter/' />
      <meta property='og:title' content='Pressure Converter - Case Modify' />
      <meta property='og:image' content='https://casemodify.com/apple-touch-icon.png' />
      <meta
        property='og:description'
        content='Pressure Unit Conversion - converts pascal, bar, millibar, torr, pounds per square inch...'
      />
    </Helmet>
  );
};

export default PressureConverterMetaTags;
