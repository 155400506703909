import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './TemperatureConverter.style';
import TemperatureConverterMetaTags from '../../../components/MetaTag/TemperatureConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const TemperatureConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const [fromUnit, setFromUnit] = useState('Celsius');
  const [toUnit, setToUnit] = useState('Fahrenheit');
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  const units = {
    Celsius: {
      toCelsius: (value) => value,
      fromCelsius: (value) => value
    },
    Fahrenheit: {
      toCelsius: (value) => (value - 32) * 5 / 9,
      fromCelsius: (value) => (value * 9 / 5) + 32
    },
    Kelvin: {
      toCelsius: (value) => value - 273.15,
      fromCelsius: (value) => value + 273.15
    },
    Rankine: {
      toCelsius: (value) => (value - 491.67) * 5 / 9,
      fromCelsius: (value) => (value * 9 / 5) + 491.67
    },
    Reaumur: {
      toCelsius: (value) => value * 5 / 4,
      fromCelsius: (value) => value * 4 / 5
    }
  };

  const convertTemperature = (value, sourceUnit, targetUnit) => {
    const celsiusValue = units[sourceUnit].toCelsius(value);
    return units[targetUnit].fromCelsius(celsiusValue);
  };

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convertTemperature(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convertTemperature(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convertTemperature(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(2));
  };

  const handleDownload = () => {
    downloadText(toValue, 'TemperatureConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <TemperatureConverterMetaTags />

      <div className='title'>
        <h1>Temperature Converter</h1>
        <p>
          <label htmlFor='content'>
            Convert between many different temperature scales
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={Object.keys(units).length}>
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={Object.keys(units).length}>
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default TemperatureConverter;
