import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './SuperscriptGenerator.style';
import { superScriptGenerator } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import SuperscriptGeneratorMetaTags from '../../../components/MetaTag/SuperscriptGeneratorMetaTags';

const SuperscriptGenerator = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    const newTransformedText = superScriptGenerator(inputText);
    setTransformedText(newTransformedText);
  };

  const handleDownload = () => {
    downloadText(transformedText, 'superScriptText.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(transformedText);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <SuperscriptGeneratorMetaTags />
      
      <h1>Superscript Generator</h1>
      <p>
        <label>
        Enhance your text effortlessly with the subtle elegance of superscript formatting! Your search for the ideal platform to seamlessly convert your text into superscript form ends here.
        </label>
      </p>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <p dangerouslySetInnerHTML={{ __html: transformedText }} />
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default SuperscriptGenerator;
