import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LengthConverter from '../pages/Transformations/LengthConverter/LengthConverter';
import VolumeConverter from '../pages/Transformations/VolumeConverter/VolumeConverter';
import FrequencyConverter from '../pages/Transformations/FrequencyConverter/FrequencyConverter';
import TemperatureConverter from '../pages/Transformations/TemperatureConverter/TemperatureConverter';
import TimeConverter from '../pages/Transformations/TimeConverter/TimeConverter';
import WeightConverter from '../pages/Transformations/WeightConverter/WeightConverter';
import BytesConverter from '../pages/Transformations/BytesConverter/BytesConverter';
import AngleConverter from '../pages/Transformations/AngleConverter/AngleConverter';
import FuelConverter from '../pages/Transformations/FuelConverter/FuelConverter';
import MassConverter from '../pages/Transformations/MassConverter/MassConverter';
import PowerConverter from '../pages/Transformations/PowerConverter/PowerConverter';
import AreaConverter from '../pages/Transformations/AreaConverter/AreaConverter';
import SpeedConverter from '../pages/Transformations/SpeedConverter/SpeedConverter';
import DensityConverter from '../pages/Transformations/DensityConverter/DensityConverter';
import EnergyConverter from '../pages/Transformations/EnergyConverter/EnergyConverter';
import ForceConverter from '../pages/Transformations/ForceConverter/ForceConverter';
import PressureConverter from '../pages/Transformations/PressureConverter/PressureConverter';
import AstronomicalConverter from '../pages/Transformations/AstronomicalConverter/AstronomicalConverter';

const UniversalConverterSuiteRouter = () => {
  return (
    <Routes>
      <Route path='/length-converter/' exact element={<LengthConverter />} />
      <Route path='/volume-converter/' exact element={<VolumeConverter />} />
      <Route path='/frequency-converter/' exact element={<FrequencyConverter />} />
      <Route path='/temperature-converter/' exact element={<TemperatureConverter />} />
      <Route path='/time-converter/' exact element={<TimeConverter />} />
      <Route path='/weight-converter/' exact element={<WeightConverter />} />
      <Route path='/bytes-converter/' exact element={<BytesConverter />} />
      <Route path='/angle-converter/' exact element={<AngleConverter />} />
      <Route path='/fuel-converter/' exact element={<FuelConverter />} />
      <Route path='/mass-converter/' exact element={<MassConverter />} />
      <Route path='/power-converter/' exact element={<PowerConverter />} />
      <Route path='/pressure-converter/' exact element={<PressureConverter />} />
      <Route path='/area-converter/' exact element={<AreaConverter />} />
      <Route path='/speed-converter/' exact element={<SpeedConverter />} />
      <Route path='/density-converter/' exact element={<DensityConverter />} />
      <Route path='/energy-converter' exact element={<EnergyConverter />} />
      <Route path='/force-converter/' exact element={<ForceConverter />} />
      <Route path='/astronomical-converter/' exact element={<AstronomicalConverter />} />
    </Routes>
  );
};

export default UniversalConverterSuiteRouter;
