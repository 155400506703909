import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './BubbleTextGenerator.style';
import { FilledBubbleTextConverter, EmptyBubbleTextConverter } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import BubbleTextMetaTags from '../../../components/MetaTag/BubbleTextMetaTags';

const BubbleTextGenerator = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText1, setTransformedText1] = useState('');
  const [transformedText2, setTransformedText2] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    const newTransformedText1 = FilledBubbleTextConverter(inputText);
    setTransformedText1(newTransformedText1);

    const newTransformedText2 = EmptyBubbleTextConverter(inputText);
    setTransformedText2(newTransformedText2);
  };

  const handleDownload = () => {
    downloadText(transformedText1 + "\n"+ transformedText2, 'bubbleText.txt');
  };

  const handleCopyTextToClipboard = (text) => {
    text && copyToClipboard(text);

     // Set showMessage to true when the button is clicked
     setShowMessage(true);

     // Use setTimeout to set showMessage back to false after 5 seconds
     setTimeout(() => {
       setShowMessage(false);
     }, 5000);
  };

  const handleCopyToClipboard = () => {
    (transformedText1 && transformedText2) && copyToClipboard(transformedText1 + "\n" + transformedText2);
    
     // Set showMessage to true when the button is clicked
     setShowMessage(true);

     // Use setTimeout to set showMessage back to false after 5 seconds
     setTimeout(() => {
       setShowMessage(false);
     }, 5000);
  };

  return (
    <Container>
      <BubbleTextMetaTags />
      <div className="title">
        <h1>Bubble Text Generator</h1>
        <p>
          <label>
            Would you like a fast online tool that converts your regular text into a bubble format? Just enter the text in the left panel, and watch it transform into bubble font.
          </label>
          </p>
      </div>
      {showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <p>
                {transformedText1} {' '}
                {transformedText1.length > 0 && <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText1)}/>}
              </p>
              <p>
                {transformedText2} {' '}
                { transformedText2.length > 0 && <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText2)}/>}
              </p>
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default BubbleTextGenerator;
