import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils'
import { Container, CoffeeCup } from './UnicodeTextConverter.style';
import { convertToFontText } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import UnicodeTextMetaTags from '../../../components/MetaTag/UnicodeTextMetaTags';
import {
  unicodeFont1, unicodeFont2, unicodeFont3, unicodeFont4,
  unicodeFont5, unicodeFont6, unicodeFont7, unicodeFont8,
  unicodeFont9, unicodeFont10, unicodeFont11, unicodeFont12,
  unicodeFont13 } from '../../../common/constants';

const UnicodeTextConverter = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText1, setTransformedText1] = useState('');
  const [transformedText2, setTransformedText2] = useState('');
  const [transformedText3, setTransformedText3] = useState('');
  const [transformedText4, setTransformedText4] = useState('');
  const [transformedText5, setTransformedText5] = useState('');
  const [transformedText6, setTransformedText6] = useState('');
  const [transformedText7, setTransformedText7] = useState('');
  const [transformedText8, setTransformedText8] = useState('');
  const [transformedText9, setTransformedText9] = useState('');
  const [transformedText10, setTransformedText10] = useState('');
  const [transformedText11, setTransformedText11] = useState('');
  const [transformedText12, setTransformedText12] = useState('');
  const [transformedText13, setTransformedText13] = useState('');

  const handleInputChange = (e) => {

    const inputText = e.target.value;
    setTextInput(inputText);

    setTransformedText1(convertToFontText(inputText, unicodeFont1));
    setTransformedText2(convertToFontText(inputText, unicodeFont2));
    setTransformedText3(convertToFontText(inputText, unicodeFont3));
    setTransformedText4(convertToFontText(inputText, unicodeFont4));
    setTransformedText5(convertToFontText(inputText, unicodeFont5));
    setTransformedText6(convertToFontText(inputText, unicodeFont6));
    setTransformedText7(convertToFontText(inputText, unicodeFont7));
    setTransformedText8(convertToFontText(inputText, unicodeFont8));
    setTransformedText9(convertToFontText(inputText, unicodeFont9));
    setTransformedText10(convertToFontText(inputText, unicodeFont10));
    setTransformedText11(convertToFontText(inputText, unicodeFont11));
    setTransformedText12(convertToFontText(inputText, unicodeFont12));
    setTransformedText13(convertToFontText(inputText, unicodeFont13));
  };

  const handleDownload = () => {
    downloadText(
      copyToClipboard(combineDownloadableText()),
      'unicodeText.txt');
  };

  const handleCopyTextToClipboard = (text) => {
    text && copyToClipboard(text);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(combineDownloadableText());

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const combineDownloadableText = () => {
    return transformedText1
      + "\n"
      + transformedText2
      + "\n"
      + transformedText3
      + "\n"
      + transformedText4
      + "\n"
      + transformedText5
      + "\n"
      + transformedText6
      + "\n"
      + transformedText7
      + "\n"
      + transformedText8
      + "\n"
      + transformedText9
      + "\n"
      + transformedText10
      + "\n"
      + transformedText11
      + "\n"
      + transformedText12
      + "\n"
      + transformedText13;
  }

  return (
    <Container>
      <UnicodeTextMetaTags />
      
      <div className="title">
        <h1>Unicode Text Converter</h1>
        <p>
          <label>  
            Seeking a convenient method to explore an extensive array of fonts for identical text? Utilize this efficient Unicode converter to simplify the process. Enter your text in the left panel, and effortlessly browse through a variety of fonts. Select your preferred font from the unitext converter, copy it, and seamlessly paste it wherever needed. Simplify your font exploration with ease.
          </label>
        </p>
      </div>
      { showMessage && <div className="success-message">Text copied to clipboard!</div> }
      <div className="tool">
        <div className="cols">
          <div className="col">
            <textarea
              placeholder="Type or paste your content here"
              autoFocus={true}
              value={textInput}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <div className="output">
              <h3 className='heading'>Script</h3>
              <p>
                {transformedText1} {' '}
                {transformedText1.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText1)}/>}
              </p>
               <h3 className='heading'>Script (Bold)</h3>
              <p>
                {transformedText2} {' '}
                { transformedText2.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText2)}/>}
              </p>
               <h3 className='heading'>Fraktur</h3>
              <p>
                {transformedText3} {' '}
                { transformedText3.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText3)}/>}
              </p>
               <h3 className='heading'>Fraktur (Bold)</h3>
              <p>
                {transformedText4} {' '}
                { transformedText4.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText4)}/>}
              </p>
               <h3 className='heading'>Circled</h3>
              <p>
                {transformedText5} {' '}
                { transformedText5.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText5)}/>}
              </p>
               <h3 className='heading'>Circled (Inverted)</h3>
              <p>
                {transformedText6} {' '}
                { transformedText6.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText6)}/>}
              </p>
               <h3 className='heading'>Squared</h3>
              <p>
                {transformedText7} {' '}
                { transformedText7.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText7)}/>}
              </p>
               <h3 className='heading'>Squared (Inverted)</h3>
              <p>
                {transformedText8} {' '}
                { transformedText8.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText8)}/>}
              </p>
               <h3 className='heading'>Capitalized</h3>
              <p>
                {transformedText9} {' '}
                { transformedText9.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText9)}/>}
              </p>
               <h3 className='heading'>Full Width</h3>
              <p>
                {transformedText10} {' '}
                { transformedText10.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText10)}/>}
              </p>
               <h3 className='heading'>Monospace</h3>
              <p>
                {transformedText11} {' '}
                { transformedText11.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText11)}/>}
              </p>
               <h3 className='heading'>Sans-Serif</h3>
              <p>
                {transformedText12} {' '}
                { transformedText12.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText12)}/>}
              </p>
               <h3 className='heading'>Sans-Serif (Italic)</h3>
              <p>
                {transformedText13} {' '}
                { transformedText13.length > 0 &&  <img className='copy' src={'/icons/copy.svg'} alt="Copy to Clipboard" onClick={() => handleCopyTextToClipboard(transformedText13)}/>}
              </p>
            </div>
            <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
            <TextAnalyzer text={textInput} />
      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default UnicodeTextConverter;
