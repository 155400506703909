import styled from 'styled-components';

export const FooterContainer = styled.footer`
  margin: 0 auto 15px;
  width: 100%;
  max-width: 1200px;
  background-color: #333;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  line-height: 1.5;
  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;