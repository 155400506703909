import React, { useState } from 'react';
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './BytesConverter.style';
import BytesConverterMetaTags from '../../../components/MetaTag/BytesConverterMetaTags';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';

const BytesConverter = () => {

  const [showMessage, setShowMessage] = useState(false);

  const [fromUnit, setFromUnit] = useState("bits");
  const [toUnit, setToUnit] = useState("bits");
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const units = {
    'bits': { toBits: value => value, fromBits: value => value },
    'bytes': { toBits: value => value * 8, fromBits: value => value / 8 },
    'kilobits (Kb)': { toBits: value => value * 1024, fromBits: value => value / 1024 },
    'kilobytes (KB)': { toBits: value => value * 8192, fromBits: value => value / 8192 },
    'megabits (Mb)': { toBits: value => value * 1048576, fromBits: value => value / 1048576 },
    'megabytes (MB)': { toBits: value => value * 8388608, fromBits: value => value / 8388608 },
    'gigabits (Gb)': { toBits: value => value * 1073741824, fromBits: value => value / 1073741824 },
    'gigabytes (GB)': { toBits: value => value * 8589934592, fromBits: value => value / 8589934592 },
    'terabits (Tb)': { toBits: value => value * 1099511627776, fromBits: value => value / 1099511627776 },
    'terabytes (TB)': { toBits: value => value * 8796093022208, fromBits: value => value / 8796093022208 },
    'petabits (Pb)': { toBits: value => value * 1125899906842624, fromBits: value => value / 1125899906842624 },
    'petabytes (PB)': { toBits: value => value * 9007199254740992, fromBits: value => value / 9007199254740992 },
    'exabits (Eb)': { toBits: value => value * 1152921504606846976, fromBits: value => value / 1152921504606846976 },
    'exabytes (EB)': { toBits: value => value * 9223372036854775808, fromBits: value => value / 9223372036854775808 },
  };
  

  const convert = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value; // No conversion needed for the same units
    const bitsValue = units[fromUnit].toBits(value);
    return units[toUnit].fromBits(bitsValue);
  };

  const handleFromValueChange = (e) => {
    const value = e.target.value;
    setFromValue(value);
    const convertedValue = convert(parseFloat(value), fromUnit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleFromUnitChange = (e) => {
    const unit = e.target.value;
    setFromUnit(unit);
    const convertedValue = convert(parseFloat(fromValue), unit, toUnit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleToUnitChange = (e) => {
    const unit = e.target.value;
    setToUnit(unit);
    const convertedValue = convert(parseFloat(fromValue), fromUnit, unit);
    setToValue(isNaN(convertedValue) ? '' : convertedValue.toFixed(6));
  };

  const handleDownload = () => {
    downloadText(toValue, 'BytesConverter.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(toValue);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };


  return (
    <Container>
      <BytesConverterMetaTags />
      <div className='title'>
        <h1>Bytes/Bits Converter</h1>
        <p>
          <label htmlFor='content'>
            Convert bits/bytes to and from kb, mb, gb, tb, pd etc...
          </label>
        </p>
      </div>
      {showMessage && <div className="success-message">Output text copied to clipboard!</div>}
      <br />
      <div className='tool'>
      <div className='cols'>
        <div className="col">
          <p><span className='target'>From:</span> {fromUnit}</p>
          <input
            className='input'
            type='number'
            value={fromValue}
            onChange={handleFromValueChange}
            placeholder='Enter value'
          />
          <select className='select-unit' onChange={handleFromUnitChange} value={fromUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <p><span className='target'>To: </span> {toUnit}</p>
          <input className='output' type='number' value={toValue} readOnly placeholder='Converted value' />
          <select className='select-unit' onChange={handleToUnitChange} value={toUnit} size={15} >
            {Object.keys(units).map(unit => (
              <option key={unit} value={unit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
    </div>

      <div className="btns">
              <button className="btn" onClick={handleDownload}>
                Download Text
              </button>
              <button className="btn" onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href="https://www.ko-fi.com/casemodify"
                className="btn-coffee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CoffeeCup src={'/icons/coffee-cup.png'} alt="Coffee Cup" />{' '}
                Buy me a Coffee
              </a>
            </div>

      <div className="media">
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default BytesConverter;
