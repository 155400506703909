import styled from 'styled-components';

export const NavBarWrapper = styled.nav`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  list-style: none;
  display: flex;
  white-space: nowrap;
  z-index: 100;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media screen and (max-width: 768px) {
    display: block;
    box-sizing: border-box;
  }
`;

export const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const NavItem = styled.li`
  position: relative;
  margin: 0;
  background: #434343;
  color: #fff;
  margin-right: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 1px;
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 8px;

  &:hover {
    border-bottom: 2px solid white;
  }
`;

export const SubMenu = styled.ul`
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #555;
  padding: 0;
  margin: 0;
  display: none;
  z-index: 1;
  ${NavItem}:hover & {
    display: block;
  }
  columns: 2; /* Adjust the number of columns as needed */
  column-gap: 10px;
  max-width: 200%; /* Set a maximum width for the dropdown */

  @media screen and (max-width: 768px) {
    columns: 1
  }
`;

export const SubMenuItem = styled.li`
  margin: 0;
`;

export const SubNavLink = styled.a`
  display: block;
  padding: 8px;
  text-decoration: none;
  color: white;
  &:hover {
    background-color: #777;
  }
`;