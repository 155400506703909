import React from 'react';
import { Helmet } from 'react-helmet';

const UnderlineTextMetaTags = () => {
  return (
    <Helmet>
      <title>Underline Text Generator | Underline Your Text | Convert Case | Case Modify</title>
      <meta name="description" content="Looking to Get Your Text Underlined? Use This Online Fast and Free Underline Text Converter Now." />

      <meta name="apple-mobile-web-app-title" content="Underline Text Generator" />
      <meta name="application-name" content="Underline Text Generator" />
      <meta name="msapplication-tooltip" content="Underline Text Generator" />

      <link rel="canonical" href="https://casemodify.com/underline-text-generator" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/underline-text-generator" />
      <meta property="og:title" content="Underline Text Generator - Bold Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking to Get Your Text Underlined? Use This Online Fast and Free Underline Text Converter Now." />
    </Helmet>
  );
};

export default UnderlineTextMetaTags;
