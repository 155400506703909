import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 15px auto;
  text-align: center;
  position: relative;
  z-index: 1;

  a {
    color: #000000;
  }

  @media (min-width: 801px) {
    -moz-columns: 2;
    columns: 2;

    * {
      -moz-column-break-inside: avoid;
      break-inside: avoid;
    }

    *:only-child {
      -moz-column-span: all;
      column-span: all;
    }
  }

  h2, h3 {
    font-size: 15px;
    margin-top: 0;
  }

  p {
    font-size: 14px;
  }

  div {
    border-top-color: #c2c2c2;
    background: #434343;
    text-align: left;
    background: #e0e0e0;
    border-top: 3px solid #c2c2c2;
    padding: 15px;
    margin-bottom: 10px
    
  }

  div:not(.cols) > *:last-child,
  div:not(.cols) div[itemprop='text'] > *:last-child {
    margin-bottom: 0;
  }

  div:not(.cols):last-child {
    margin-bottom: 0;
  }

  table {
    margin-bottom: 15px;
  }

  .zalgoText {
    margin-top: 36px;
    margin-bottom: 24px !important;
  }
`;
