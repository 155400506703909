import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TransformationsRouter from './routes/TransformationsRouter';
import UniversalConverterSuiteRouter from './routes/UniversalConverterSuiteRouter';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CopyRightFooter from './components/Footer/CopyRightFooter';
import AboutRouter from './routes/AboutRouter';
import PrivacyPolicyRouter from './routes/PrivacyPolicyRouter';
import HomeRouter from './routes/HomeRouter';
import './common/firebase'

const App = () => {
  return (
    <>
      <Header />
      <Router>
        <Routes>
          <Route path="*" exact element={<HomeRouter />} />
          <Route path="/about/" exact element={<AboutRouter />} />
          <Route path="/privacy-policy/" exact element={<PrivacyPolicyRouter />} />
        </Routes>
        <TransformationsRouter />
        <UniversalConverterSuiteRouter />
      </Router>
      <Footer />
      <CopyRightFooter />
    </>
  );
};

export default App;