import React from 'react';
import { Helmet } from 'react-helmet';

const ZalgoGlitchTextMetaTags = () => {
  return (
    <Helmet>
      <title>Glitch Text Generator | Convert Case | Case Modify</title>
      <meta name="description" content="Looking for a Quick Online Glitch Text Generator? Convert Your Text to Zalgo Text Here With Our Zalgo Glitch Converter." />

      <meta name="apple-mobile-web-app-title" content="Glitch Zalgo Text Generator" />
      <meta name="application-name" content="Glitch Zalgo Text Generator" />
      <meta name="msapplication-tooltip" content="Glitch Zalgo Text Generator" />

      <link rel="canonical" href="https://casemodify.com/glitch-text-converter/" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@casemodify" />
      <meta name="twitter:creator" content="@casemodify" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://casemodify.com/glitch-text-converter/" />
      <meta property="og:title" content="Glitch Zalgo Text Generator | Case Modify" />
      <meta property="og:image" content="https://casemodify.com/apple-touch-icon.png" />
      <meta property="og:description" content="Looking to Make Your Text Bold? Convert Your Text Into Bold With the Handy Online Free and Quick to Use Glitch Zalgo Text Generator." />
    </Helmet>
  );
};

export default ZalgoGlitchTextMetaTags;
